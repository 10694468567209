import { CdkStep, CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IconComponent } from '@app/shared/components/icons/icon.component';
import {
    TimelineLabelComponent,
} from '@app/shared/components/stepper/timeline-label/timeline-label.component';
import {
    TimelineStepComponent,
} from '@app/shared/components/stepper/timeline-step/timeline-step.component';

type StepState = 'upcoming' | 'selected' | 'done';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    standalone: true,
    providers: [
        { provide: CdkStepper, useExisting: StepperComponent },
    ],
    imports: [
        CommonModule,
        CdkStepperModule,
        IconComponent,
        TimelineStepComponent,
        TimelineLabelComponent,
    ],
})
export class StepperComponent extends CdkStepper {

    isStepDone(step: CdkStep): boolean {
        return this.isStepDoneByIndex(this.getStepIndex(step));
    }

    isStepSelected(step: CdkStep): boolean {
        return this.isStepSelectedByIndex(this.getStepIndex(step));
    }

    isStepUpcoming(step: CdkStep): boolean {
        return this.isStepUpcomingByIndex(this.getStepIndex(step));
    }

    isLastStep(step: CdkStep): boolean {
        return this.getStepIndex(step) === (this.steps.length -1);
    }

    protected isStepSelectedByIndex(index: number): boolean {
        return 'selected' === this.getStepState(index);
    }

    protected isStepDoneByIndex(index: number): boolean {
        return 'done' === this.getStepState(index);
    }

    protected isStepUpcomingByIndex(index: number): boolean {
        return 'upcoming' === this.getStepState(index);
    }

    protected getStepState(index: number): StepState {
        if (index < this.selectedIndex) {
            return 'done';
        }

        if (index === this.selectedIndex) {
            return 'selected';
        }

        return 'upcoming';
    }

    private getStepIndex(step: CdkStep): number {
        return this.steps.toArray().indexOf(step);
    }
}
