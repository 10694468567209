import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { RequestAppVersionChecker } from "@app/core/http/request-app-version-checker.service";

@Injectable({
    providedIn: 'root',
})
export class CheckAppVersionInterceptor implements HttpInterceptor {

    constructor(
        private versionChecker: RequestAppVersionChecker,
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                this.checkHttpResponse(),
                this.checkHttpErrorResponse(),
            );
    }

    private checkHttpResponse<T, Event extends HttpEvent<T>>(): MonoTypeOperatorFunction<Event> {
        return switchMap((event) => {
            if (event instanceof HttpResponse) {
                this.versionChecker.checkIfAppIsUpToDate(event);
            }

            return of(event);
        });
    }

    private checkHttpErrorResponse<T>(): MonoTypeOperatorFunction<T> {
        return catchError((error) => {
            if (error instanceof HttpErrorResponse) {
                this.versionChecker.checkIfAppIsUpToDate(error);
            }

            return throwError(() => error);
        });
    }
}
