import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatDateToYYYYMMDD } from '@app/shared/date.utils';
import { Assessment } from '@app/shared/models/assessment/assessment.model';
import { Request } from '@app/shared/models/request/request.model';
import { getExtension } from 'mime';
import { fromEvent, Observable, of, OperatorFunction, throwError } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export function downloadBlob(fileName: string, blob: Blob): void {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = fileName;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        }),
    );

    // Remove link from body
    document.body.removeChild(link);
}

function mapToBlob(
    fileName: string,
    extension: string | null = null,
): OperatorFunction<Blob | undefined, void> {
    return map((blob) => {
        if (!blob) {
            throw new Error('Expected response to contain a blob');
        }

        if (null === extension) {
            extension = getExtension(blob.type);

            fileName = `${fileName}.${extension}`;
        }

        return downloadBlob(fileName, blob);
    });
}

function createImageFromBlob(): OperatorFunction<Blob | undefined, string> {
    return switchMap((blob) => {
        if (!blob) {
            return throwError(new Error('Expected response to contain a blob'));
        }

        const reader = new FileReader();

        const image = fromEvent(reader, 'load')
            .pipe(
                map(() => reader.result as string),
                first(),
            );

        reader.readAsDataURL(blob);

        return image;
    });
}

function createFormattedCurrentDate(): string {
    return formatDateToYYYYMMDD(new Date());
}

@Injectable({
    providedIn: 'root',
})
export class AssetService {

    constructor(private http: HttpClient) {
    }

    downloadCompanyContract(auditCompanyId: string, auditCompanyName: string): Observable<void> {
        return this.getAsset(
            `/audit-companies/${auditCompanyId}/contract`,
            `${auditCompanyName}_Zertifizierungsvertrag`,
        );
    }

    downloadGrowingSeasonData(growingSeasonId: string): Observable<void> {
        console.log(`TODO: download growing season data for ${growingSeasonId}.`);

        return of(void 0);
    }

    downloadCompanyGermanCertificate(
        companyId: string,
        registrationNumber: string,
        growingSeason: string,
    ): Observable<void> {
        return this.getAsset(
            `/companies/${companyId}/german-certificate`,
            `Nachhaltig_Austria_Zertifikat_${registrationNumber}_${growingSeason}_DE`,
        );
    }

    downloadCompanyGermanPrintCertificate(
        companyId: string,
        registrationNumber: string,
        growingSeason: string,
    ): Observable<void> {
        return this.getAsset(
            `/companies/${companyId}/german-print-certificate`,
            `Nachhaltig_Austria_Zertifikat_${registrationNumber}_${growingSeason}_Druck_DE`,
        );
    }

    downloadCompanyEnglishCertificate(
        companyId: string,
        registrationNumber: string,
        growingSeason: string,
    ): Observable<void> {
        return this.getAsset(
            `/companies/${companyId}/english-certificate`,
            `Nachhaltig_Austria_Zertifikat_${registrationNumber}_${growingSeason}_EN`,
        );
    }

    downloadCompanyEnglishPrintCertificate(
        companyId: string,
        registrationNumber: string,
        growingSeason: string,
    ): Observable<void> {
        return this.getAsset(
            `/companies/${companyId}/english-print-certificate`,
            `Nachhaltig_Austria_Zertifikat_${registrationNumber}_${growingSeason}_Druck_EN`,
        );
    }

    downloadCompanyLogos(
        companyId: string,
        registrationNumber: string,
        growingSeason: string,
    ): Observable<void> {
        return this.http
            .get(
                `/companies/${companyId}/logos`,
                { responseType: 'blob' },
            )
            .pipe(
                mapToBlob(
                    `Nachhaltig_Austria_Logos_${registrationNumber}_${growingSeason}`,
                    'zip',
                ),
            );
    }

    downloadCompanyEnglishColorLogoImage(companyId: string): Observable<string> {
        return this.http
            .get(
                `/companies/${companyId}/english-color-logo`,
                { responseType: 'blob' },
            )
            .pipe(
                createImageFromBlob(),
            );
    }

    downloadCompanyGermanColorLogoImage(companyId: string): Observable<string> {
        return this.http
            .get(
                `/companies/${companyId}/german-color-logo`,
                { responseType: 'blob' },
            )
            .pipe(
                createImageFromBlob(),
            );
    }

    downloadInspectionReport(request: Request): Observable<void> {
        const requestId = request.id;
        const registerNumber = request.mainCompany.registerNumber;
        const growingSeason = request.growingSeasonId;

        return this.getAsset(
            `/requests/${requestId}/download-inspection-report`,
            `Nachhaltig_Austria_Kontrollbericht_${registerNumber}_${growingSeason}_${createFormattedCurrentDate()}`,
        );
    }

    downloadEvaluation(assessment: Assessment): Observable<void> {
        const assessmentId = assessment.id;
        const growingSeason = assessment.growingSeasonId;

        return this.getAsset(
            `/assessments/${assessmentId}/evaluation`,
            $localize`:@@evaluation.evaluationPage.print.fileName: ${growingSeason} ${createFormattedCurrentDate()}`,
        );
    }

    getAsset(url: string, fileName: string): Observable<void> {
        return this.http
            .get(url, { responseType: 'blob' })
            .pipe(mapToBlob(fileName));
    }
}
