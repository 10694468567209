import { Component, HostBinding, Input } from '@angular/core';

export type BadgeColor = 'brown'
    | 'dark-green'
    | 'light-green'
    | 'dark-red'
    | 'gray'
    | 'purple'
    | 'blue';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {

    @HostBinding('class')
    @Input() color?: BadgeColor;

    @HostBinding('class.outline')
    @Input() outline = false;

    constructor() {
    }
}
