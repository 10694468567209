import { getSuffixByUnit, Unit } from '@app/shared/models/unit';

export enum MeasureBlockConstraint {
    GREATER_THAN_0 = 'greaterThan0',
    EXACTLY_100_PERCENT = 'exactly100Percent',
    MAX_100_PERCENT = 'max100Percent',
}

// Keep in sync with the API
const MEASURE_BLOCK_CONSTRAINTS_WITH_UPPER_BOUND = [
    MeasureBlockConstraint.MAX_100_PERCENT,
    MeasureBlockConstraint.EXACTLY_100_PERCENT,
];

export function isMeasureBlockConstraintWithUpperBound(constraint: MeasureBlockConstraint): boolean {
    return MEASURE_BLOCK_CONSTRAINTS_WITH_UPPER_BOUND.includes(constraint);
}

function createGreaterThan0ConstraintMessage(unit: Unit): string {
    switch (unit) {
        case Unit.BOOLEAN:
            return 'Es muss mindestens eine Maßnahme mit "Ja" beantwortet werden.';

        default:
            return `Die Summe der Eingaben muss größer als ${renderValue(0, unit)} sein.`;
    }
}

function createMax100PercentConstraintMessage(unit: Unit, referenceAmount: number) {
    switch (unit) {
        case Unit.BOOLEAN:
            return 'Es darf höchstens eine Maßnahme mit "Ja" beantwortet werden.';

        default:
            return `Die Summe der Eingaben darf maximal ${renderValue(referenceAmount, unit)} ergeben.`;
    }
}

function createExactly100PercentConstraintMessage(unit: Unit, referenceAmount: number): string {
    switch (unit) {
        case Unit.BOOLEAN:
            return 'Es muss genau eine zutreffende Maßnahme mit "Ja" beantwortet werden.';

        default:
            return `Die Summe der Eingaben muss ${renderValue(referenceAmount, unit)} ergeben.`;
    }
}

function renderValue(value: number, unit: Unit): string {
    return withSuffix(
        convertNumber(value, unit),
        unit,
    );
}

function convertNumber(value: number, unit: Unit): number {
    if (Unit.PERCENT === unit) {
        return value * 100;
    }

    return value;
}

function withSuffix(value: number, unit: Unit): string {
    return `${value.toLocaleString('de-DE')}${getSuffixByUnit(unit)}`;
}

export function getMessageByMeasureBlockConstraintAndUnit(
    constraint: MeasureBlockConstraint | null,
    unit: Unit,
    referenceAmount: number,
): string | null {
    if (null === constraint) {
        return null;
    }

    switch (constraint) {
        case MeasureBlockConstraint.GREATER_THAN_0:
            return createGreaterThan0ConstraintMessage(unit);

        case MeasureBlockConstraint.EXACTLY_100_PERCENT:
            return createExactly100PercentConstraintMessage(unit, referenceAmount);

        case MeasureBlockConstraint.MAX_100_PERCENT:
            return createMax100PercentConstraintMessage(unit, referenceAmount);
    }
}
