import { NgForOf, NgIf } from '@angular/common';
import {
    Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { IconComponent } from '@app/shared/components/icons/icon.component';
import { TabBarOption } from '@app/shared/components/tab-bar/tab-bar-option.model';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-tab-bar',
    templateUrl: './tab-bar.component.html',
    styleUrls: ['./tab-bar.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgIf,
        NgForOf,
        IconComponent,
        MatMenuModule,
    ],
})
export class TabBarComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true }) options!: TabBarOption[];

    @Input() limit = 5;

    @Output() optionChanged = new EventEmitter<TabBarOption>();

    control = new UntypedFormControl(null);

    primaryOptions: TabBarOption[] = [];

    additionalOptions: TabBarOption[] = [];

    additionalOptionSelected = false;

    private readonly ngUnsubscribe = new Subject<void>();

    constructor() {
    }

    ngOnInit(): void {
        this.control.valueChanges
            .pipe(
                distinctUntilChanged(),
                map((value) => this.options.find((option) => option.value === value)!),
                tap((option) => {
                    this.optionChanged.emit(option);
                    this.additionalOptionSelected = this.additionalOptions.includes(option);
                }),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe();

        this.selectFirstOption();
        this.updatePrimaryAndAdditionalOptions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('options' in changes) {
            this.selectFirstOption();
        }

        if ('options' in changes || 'limit' in changes) {
            this.updatePrimaryAndAdditionalOptions();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private selectFirstOption(): void {
        const firstOption = this.options[0];
        if (undefined === firstOption) {
            throw new Error('Expected at least one option.');
        }

        this.control.setValue(firstOption.value);
    }

    private updatePrimaryAndAdditionalOptions(): void {
        if (1 === this.options.length) {
            this.primaryOptions = [];
        } else if (this.options.length > this.limit) {
            // Take one less to make room for the button "More"
            this.primaryOptions = this.options.slice(0, this.limit - 1);
            this.additionalOptions = this.options.slice(this.limit - 1);
        } else {
            this.primaryOptions = this.options;
            this.additionalOptions = [];
        }
    }
}
