import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrls: ['./dropdown-button.component.scss'],
})
export class DropdownButtonComponent {

    @Input({ required: true }) text!: string;

    constructor() {
    }
}
