/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { browserTracingIntegration } from '@sentry/angular';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
    enabled: null !== environment.sentryDsn,
    dsn: environment.sentryDsn || undefined,
    release: environment.sentryRelease,
    environment: environment.environment,
    integrations: [
        browserTracingIntegration(),
    ],
    tracePropagationTargets: [
        'localhost',
        'https://tool.nachhaltigaustria.at/api',
        'https://tool.nachhaltigaustria.at.webmozarts.com/api',
    ],
    tracesSampleRate: environment.sentryTracesSampleRate,
    ignoreErrors: [
        'Non-Error exception captured',
    ],
    attachStacktrace: true,
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch((err) => console.error(err));
