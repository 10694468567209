import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconComponent } from '@app/shared/components/icons/icon.component';

@Component({
    selector: 'app-timeline-step',
    templateUrl: './timeline-step.component.html',
    styleUrls: ['./timeline-step.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        IconComponent,
    ],
})
export class TimelineStepComponent {

    @Input({ required: true }) active!: boolean;

    @Input({ required: true }) done!: boolean;

    @Input({ required: true }) isLastStep = false;
}
