import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ORIGINAL_URL_PARAM } from '@app/core/authentication/services/redirect-url-factory.service';
import { assertIsString } from '@app/shared/type-guards';

function createRequestDetailPathSegments(requestId?: string | null): string[] {
    assertIsString(requestId);

    return [...createRequestsPathSegments(), requestId];
}

export function createRequestsPathSegments(): string[] {
    return [...createCertificationPathSegments(), 'requests'];
}

function createResubmitRequestPagePathSegments(
    assessmentId?: string | null,
    requestId?: string | null,
): string[] {
    assertIsString(requestId);

    return [...createSubmitRequestPagePathSegments(assessmentId), requestId];
}

function createSubmitRequestPagePathSegments(assessmentId?: string | null): string[] {
    return [...createAssessmentPathSegments(assessmentId), 'submit-request'];
}

function createEvaluationPathSegments(assessmentId?: string | null): string[] {
    return [...createAssessmentPathSegments(assessmentId), 'evaluation'];
}

function createAssessmentBaseValuesPathSegments(assessmentId?: string | null): string[] {
    return [...createAssessmentPathSegments(assessmentId), 'base-values'];
}

function createQualityGoalPathSegments(
    assessmentId?: string | null,
    qualityGoalId?: string | null,
): string[] {
    assertIsString(qualityGoalId);

    return [...createAssessmentPathSegments(assessmentId), qualityGoalId];
}

function createAssessmentPathSegments(assessmentId?: string | null): string[] {
    assertIsString(assessmentId);

    return [...createCertificationPathSegments(), 'assessments', assessmentId];
}

function createGoalDetailPathSegments(goalId?: string | null): string[] {
    assertIsString(goalId);

    return [...createGoalsPathSegments(), goalId];
}

export function createGoalsPathSegments(): string[] {
    return [...createCertificationPathSegments(), 'goals'];
}

function createAddGoalPathSegments(): string[] {
    return [...createGoalsPathSegments(), 'add-goal'];
}

function createCompanyDetailPathSegments(companyId?: string | null): string[] {
    assertIsString(companyId);

    return [...createCompaniesPathSegments(), companyId];
}

function createCompaniesPathSegments(): string[] {
    return [...createCertificationPathSegments(), 'companies'];
}

function createCertificationPathSegments(): string[] {
    return ['certification'];
}

export function createPageNotFoundPathSegments(): string[] {
    return ['/', '404'];
}

function createRootPathSegments(): string[] {
    return ['/'];
}

@Injectable()
export class NavigationService {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {
    }

    navigateToRoot(): Promise<boolean> {
        return this.navigate(createRootPathSegments());
    }

    navigateToGoals(): Promise<boolean> {
        return this.navigate(createGoalsPathSegments());
    }

    navigateToAddGoal(): Promise<boolean> {
        return this.navigate(createAddGoalPathSegments());
    }

    navigateToGoal(goalId?: string | null): Promise<boolean> {
        return this.navigate(createGoalDetailPathSegments(goalId));
    }

    navigateToAssessment(assessmentId?: string | null): Promise<boolean> {
        return this.navigate(createAssessmentPathSegments(assessmentId));
    }

    navigateToAssessmentBaseValues(
        assessmentId?: string | null,
        extras?: NavigationExtras,
    ): Promise<boolean> {
        return this.navigate(
            createAssessmentBaseValuesPathSegments(assessmentId),
            extras,
        );
    }

    navigateToAssessmentQualityGoal(
        assessmentId?: string | null,
        qualityGoalId?: string | null,
        extras?: NavigationExtras,
    ): Promise<boolean> {
        return this.navigate(
            createQualityGoalPathSegments(assessmentId, qualityGoalId),
            extras,
        );
    }

    navigateToSubmitRequestPage(assessmentId?: string | null): Promise<boolean> {
        return this.navigate(createSubmitRequestPagePathSegments(assessmentId));
    }

    navigateToResubmitRequestPage(
        assessmentId?: string | null,
        requestId?: string | undefined | null,
    ): Promise<boolean> {
        return this.navigate(createResubmitRequestPagePathSegments(assessmentId, requestId));
    }

    navigateToEvaluationPage(assessmentId?: string | null): Promise<boolean> {
        return this.navigate(createEvaluationPathSegments(assessmentId));
    }

    navigateToRequestPage(requestId?: string | null, options?: NavigationExtras): Promise<boolean> {
        return this.navigate(createRequestDetailPathSegments(requestId), options);
    }

    navigateToCompanyPage(companyId?: string | null): Promise<boolean> {
        return this.navigate(createCompanyDetailPathSegments(companyId));
    }

    navigateToPageNotFoundPage(): Promise<boolean> {
        return this.navigate(createPageNotFoundPathSegments());
    }

    async navigateToOriginalUrlOrRoot(): Promise<boolean> {
        const originalUrl = this.route.snapshot.queryParamMap.get(ORIGINAL_URL_PARAM);

        const redirectedToOriginalUrl = !!originalUrl && await this.router.navigateByUrl(originalUrl);

        return redirectedToOriginalUrl || this.navigateToRoot();
    }

    private navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate(commands, extras);
    }
}
