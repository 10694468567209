import { DataVisualizationDefinition } from '@app/shared/models/assessment/data-visualization-definition.model';
import { ExpressionValue } from '@app/shared/models/assessment/expression-value.model';
import { Type } from 'class-transformer';
import { IsArray, IsDefined, ValidateNested } from 'class-validator';
import { ExpressionValueShare } from "@app/shared/models/assessment/expression-value-share.model";

export class DataVisualization {
    @IsDefined()
    @ValidateNested()
    @Type(() => DataVisualizationDefinition)
    definition!: DataVisualizationDefinition;

    @IsDefined({ each: true })
    @IsArray()
    @ValidateNested()
    @Type(() => ExpressionValue)
    expressionValues!: ExpressionValue[];

    get singleValue(): ExpressionValue | undefined {
        return 1 === this.expressionValues.length
            ? this.expressionValues[0]
            : undefined;
    }

    get singleValueShares(): ExpressionValueShare[] {
        return this.singleValue?.shares || [];
    }
}
