export enum UserRole {
    ADMIN = 'ROLE_ADMIN',
    AUDIT_COMPANY_ADMIN = 'ROLE_AUDIT_COMPANY_ADMIN',
    AUDITOR = 'ROLE_AUDITOR',
    WINERY_USER = 'ROLE_WINERY_USER',
}

export const USER_ROLE_NAMES: { [key in UserRole]: string } = {
    [UserRole.ADMIN]: 'Administrator',
    [UserRole.AUDIT_COMPANY_ADMIN]: 'Zert.-Unt.-Admin',
    [UserRole.AUDITOR]: 'Auditor',
    [UserRole.WINERY_USER]: 'Betrieb',
};

const AUDITOR_ROLES = [UserRole.AUDITOR, UserRole.AUDIT_COMPANY_ADMIN];

export function isAuditCompanyMemberRole(role: UserRole): boolean {
    return AUDITOR_ROLES.includes(role);
}

export function isUserRole(role: any): role is UserRole {
    return Object.values(UserRole).includes(role);
}
