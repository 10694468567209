import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Authenticator } from '@app/core/authentication/services/authenticator.service';
import { RedirectUrlFactory } from '@app/core/authentication/services/redirect-url-factory.service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';

@Injectable({
    providedIn: 'root',
})
export class RedirectNonAuthenticatedInterceptor implements HttpInterceptor {

    private authenticator?: Authenticator;

    constructor(
        // Cannot inject the Authenticator here since it itself depends on
        // the HTTP-Client Angular would complain about a cyclic dependency
        private injector: Injector,
        private router: Router,
        private loginUrlFactory: RedirectUrlFactory,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(request)
            .pipe(
                catchError((error) => this.handleError(error, request)),
            );
    }

    private handleError(error: any, request: HttpRequest<any>) {
        // Only handle cases where we have a 401 non-authorized HTTP request
        // which means we either made a request non-authenticated or
        // with an invalid token
        if (!(error instanceof HttpErrorResponse) || error.status !== 401) {
            return throwError(error);
        }

        Sentry.addBreadcrumb({ message: 'HTTP request returned with a 401.' });

        if (!request.headers.has('authorization')) {
            Sentry.addBreadcrumb({
                // The message should not contain the string "auth" otherwise it will be
                // completely scrubbed by Sentry.
                // https://docs.sentry.io/product/data-management-settings/scrubbing/server-side-scrubbing/
                message: 'The request header had no au*thorization header hence we rethrow the error instead of redirecting to the login page.',
            });

            return throwError(error);
        }

        Sentry.addBreadcrumb({ message: 'Redirecting to the login page.' });

        const redirectUrl = this.loginUrlFactory.createLoginUrlWithOriginalUrl(this.router.routerState.snapshot.url);

        return this.getAuthenticator()
            .logout()
            .pipe(
                take(1),
                // We use switchMap here to make sure the navigation is complete
                switchMap(() => this.router.navigateByUrl(redirectUrl)),
                // We do another switchMap with EMPTY to make the compiler happy
                // since the router observable returned is not an
                // Observable<HttpEvent>
                switchMap(() => EMPTY),
            );
    }

    private getAuthenticator(): Authenticator {
        if (undefined === this.authenticator) {
            this.authenticator = this.injector.get(Authenticator);
        }

        return this.authenticator;
    }
}
