import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ComponentFactory, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, HostBinding,
    Input,
    OnChanges, Renderer2, SimpleChanges, ViewContainerRef,
} from '@angular/core';
import { MatLegacyProgressSpinner as MatProgressSpinner } from '@angular/material/legacy-progress-spinner';
import { assertIsString } from '@app/shared/type-guards';

type UnstyleCallback = () => void;

@Directive({
    selector: 'button[loading]',
})
export class ButtonLoadingDirective implements OnChanges {

    @Input() loading: BooleanInput;

    @Input() disabled: boolean = false;

    get nativeElement(): HTMLButtonElement {
        return this.button.nativeElement;
    }

    @HostBinding('disabled')
    get nativeElementDisabled(): boolean {
        const loading = coerceBooleanProperty(this.loading);

        return this.disabled || loading;
    }

    private spinner: ComponentRef<MatProgressSpinner> | null = null;

    private readonly spinnerFactory: ComponentFactory<MatProgressSpinner>;

    private readonly blockStyles = ['block', 'flex', 'grid', 'list-item', 'table'];

    private unstyleCallbacks: UnstyleCallback[] = [];

    constructor(
        private button: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2,
    ) {
        this.spinnerFactory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const loadingChanged = 'loading' in changes;

        if (!loadingChanged) {
            return;
        }

        if (coerceBooleanProperty(changes.loading.currentValue)) {
            this.styleButton();
            this.createSpinner();
        } else if (!changes.loading.firstChange) {
            this.unstyleButton();
            this.destroySpinner();
        }
    }

    private createSpinner(): void {
        if (!this.spinner) {
            this.spinner = this.viewContainerRef.createComponent(this.spinnerFactory);
            this.spinner.instance.diameter = 20;
            this.spinner.instance.mode = 'indeterminate';

            this.renderer.insertBefore(
                this.nativeElement,
                this.spinner.instance._elementRef.nativeElement,
                this.nativeElement.firstChild,
            );
        }
    }

    private destroySpinner(): void {
        if (this.spinner) {
            this.spinner.destroy();
            this.spinner = null;
        }
    }

    private styleButton(): void {
        const displayStyle = window.getComputedStyle(this.nativeElement).display;
        const isBlockStyle = this.blockStyles.includes(displayStyle);
        const buttonStyle = isBlockStyle ? 'flex' : 'inline-flex';

        this.unstyleCallbacks = [
            this.addButtonStyle('display', buttonStyle),
            this.addButtonStyle('justifyContent', 'center'),
            this.addButtonStyle('alignItems', 'center'),
            this.addButtonStyle('verticalAlign', 'bottom'),
        ];
    }

    private unstyleButton(): void {
        this.unstyleCallbacks.reverse().forEach((callback) => callback());
    }

    private addButtonStyle(cssAttribute: keyof CSSStyleDeclaration, cssValue: string): UnstyleCallback {
        assertIsString(cssAttribute);

        const originalValue = this.nativeElement.style[cssAttribute];

        this.renderer.setStyle(this.nativeElement, cssAttribute, cssValue);

        return originalValue
            ? () => this.renderer.setStyle(this.nativeElement, cssAttribute, originalValue)
            : () => this.renderer.removeStyle(this.nativeElement, cssAttribute);
    }
}
