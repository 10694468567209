import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { EMPTY, from, MonoTypeOperatorFunction, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

function createUnavailablePathSegments(): string[] {
    return [...createRootPathSegments(), 'unavailable'];
}

function createInternalServerErrorPathSegments(): string[] {
    return [...createRootPathSegments(), 'internal-error'];
}

function createForbiddenPathSegments(): string[] {
    return [...createRootPathSegments(), 'forbidden'];
}

function createRootPathSegments(): string[] {
    return ['error'];
}

function isLoginRequest(url: string): boolean {
    return url.includes('/authentication/login');
}

function isMigrateAccountRequest(url: string): boolean {
    return url.includes('/public/users/migrate');
}

@Injectable()
export class RequestErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private dialog: MatDialog,
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                catchError((error) => this.handleError(error, request)),
            );
    }

    private handleError(error: any, request: HttpRequest<any>): Observable<never> {
        if (!(error instanceof HttpErrorResponse)) {
            return throwError(error);
        }

        if (
            403 === error.status
            && !isLoginRequest(request.url)
            && !isMigrateAccountRequest(request.url)
        ) {
            return this.navigateAndComplete(createForbiddenPathSegments());
        }

        if (500 === error.status) {
            return this.navigateToPath(createInternalServerErrorPathSegments())
                .pipe(switchMap(() => throwError(error)));
        }

        if (503 === error.status) {
            return this.navigateAndComplete(createUnavailablePathSegments());
        }

        return throwError(error);
    }

    private navigateAndComplete(pathSegments: string[]) {
        return this.navigateToPath(pathSegments)
            .pipe(switchMap(() => EMPTY));
    }

    private navigateToPath(pathSegments: string[]): Observable<boolean> {
        return from(this.router.navigate(pathSegments, { skipLocationChange: true }))
            .pipe(this.closeDialogs());
    }

    private closeDialogs<T>(): MonoTypeOperatorFunction<T> {
        return tap(() => this.dialog.closeAll());
    }
}
