import { TransformationType, TransformFnParams } from 'class-transformer';

export function localDateToString({ value, type }: TransformFnParams): Date|string|null|undefined {
    if (null === value || undefined === value) {
        return value;
    }

    if (TransformationType.PLAIN_TO_CLASS !== type) {
        return (value as Date).toISOString().substr(0, 10);
    }

    if ('string' !== typeof value) {
        throw new Error(`Expected the value "${value}" to be a string`);
    }

    const valueAsISODate = `${value}T00:00:00.000Z`;
    const date = new Date(valueAsISODate);

    if (isNaN(date.getTime())) {
        throw new Error(`The string "${value}" is not a valid date`);
    }

    return date;
}
