import { Location } from '@angular/common';
import { Component, Host, Input, Optional } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
    selector: 'app-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
    @Input() label = 'Zurück';

    @Input() link?: string;

    private readonly hasRouterLink: boolean;

    constructor(
        private location: Location,
        private router: Router,
        @Optional() @Host() routerLink: RouterLink,
    ) {
        this.hasRouterLink = !!routerLink;
    }

    navigate() {
        if (this.hasRouterLink) {
            return;
        }

        return this.link ? this.router.navigateByUrl(this.link) : this.location.back();
    }
}
