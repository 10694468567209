export function roundToPrecision(value: number, precision: number): number {
    const decimals = 10 ** precision;
    const epsilon = (value >= 0 ? 1 : -1) * Number.EPSILON;

    return Math.round((value + epsilon) * decimals) / decimals;
}

export function equals(a: number, b: number, precision: number): boolean {
    return Math.abs(roundToPrecision(a - b, precision)) < Number.EPSILON;
}
