import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

export const ORIGINAL_URL_PARAM = 'originalUrl';

const IGNORED_LOGIN_ORIGINAL_URLS = [
    '/',
    '/authentication/confirm-email',
];

@Injectable({
    providedIn: 'root',
})
export class RedirectUrlFactory {

    constructor(private readonly router: Router) {
    }

    createLoginUrlWithOriginalUrl(originalUrl: string): UrlTree {
        const loginUrl = '/authentication/welcome';

        return this.createRedirectUrl(originalUrl, loginUrl, IGNORED_LOGIN_ORIGINAL_URLS);
    }

    createRedirectUrl(url: string, targetUrl: string, ignoredUrls: string[] = []): UrlTree {
        const redirectUrl = this.router.parseUrl(targetUrl);
        const urlWithoutParams = url.split('?')[0];

        if (!ignoredUrls.includes(urlWithoutParams)) {
            redirectUrl.queryParams[ORIGINAL_URL_PARAM] = url;
        }

        return redirectUrl;
    }
}
