import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { OperatorFunction, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

export const APP_TITLE = 'title';

function findMostInnerChildOfRoute(route: ActivatedRoute | null): ActivatedRoute | null {
    const firstChild = route?.firstChild;

    return firstChild
        ? findMostInnerChildOfRoute(firstChild)
        : route;
}

function getTitleFromChild(child: ActivatedRoute | null, appTitle: string): string {
    const routeTitle = child?.snapshot.data[APP_TITLE];

    if (!routeTitle) {
        return appTitle;
    }

    return `${routeTitle} - ${appTitle}`;
}

@Injectable({
    providedIn: 'root',
})
export class AppTitleService implements OnDestroy {

    private readonly ngUnsubscribe = new Subject<void>();

    private readonly appTitle: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private titleService: Title,
    ) {
        this.appTitle = this.titleService.getTitle();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    initializeHtmlTitleLoader(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                this.mapActivatedRouteToTitle(this.appTitle),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((title: string) => {
                this.titleService.setTitle(title);
            });
    }

    setTitle(title: string): void {
        this.titleService.setTitle(`${title} - ${this.appTitle}`);
    }

    private mapActivatedRouteToTitle(appTitle: string): OperatorFunction<Event, string> {
        return map(() => {
            const child = findMostInnerChildOfRoute(this.activatedRoute);

            return getTitleFromChild(child, appTitle);
        });
    }
}
