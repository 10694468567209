import { Component, Input, OnInit } from '@angular/core';
import { StandardFont } from '@app/shared/fonts';
import { equals, roundToPrecision } from '@app/shared/math.utils';
import { ChartType, Color, DefaultDataPoint, TooltipItem } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts/lib/base-chart.directive';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { NgChartsModule } from 'ng2-charts';
import { DataVisualization } from "@app/shared/models/assessment/data-visualization.model";
import { ExpressionValueShare } from "@app/shared/models/assessment/expression-value-share.model";
import {
    createFormatPercentage,
} from "@app/shared/components/assessment/doughnut-chart-data-visualization/services/percent-formatter";
import {
    DoughnutChartDataVisualizationLegendComponent,
} from "@app/shared/components/assessment/doughnut-chart-data-visualization/components/doughnut-chart-data-visualization-legend/doughnut-chart-data-visualization-legend.component";

const SHARE_CHART_TYPE: ChartType = 'doughnut';
type ShareChartType = typeof SHARE_CHART_TYPE;
type ShareChartConfig = BaseChartDirective<ShareChartType, DefaultDataPoint<ShareChartType>, string>;
type ShareChartDatasets =  ShareChartConfig['datasets'];
type ShareChartOptions =  ShareChartConfig['options'];

const MAX_SHARES = 7;
const MINIMUM_PERCENTAGE_VALUE = 5;
const PERCENTAGE_ROUNDING_PRECISION = 1;
const ROUNDING_PRECISION = 2 + PERCENTAGE_ROUNDING_PRECISION;
const MAX_LABEL_LENGTH = 74;

const COLORS: Color[] = [
    'rgba(105, 184, 185, 1)',
    'rgba(245, 205, 106, 1)',
    'rgba(232, 108, 139, 1)',
    'rgba(113, 170, 220, 1)',
    'rgba(147, 215, 127, 1)',
    'rgba(144, 141, 100, 1)',
    'rgba(200, 200, 200, 1)',
];

function truncateLabel(label: string): string {
    return label.length > MAX_LABEL_LENGTH
        ? label.substring(0, MAX_LABEL_LENGTH).trimRight() + '…'
        : label;
}

function extractSignificantShares(
    shares: ExpressionValueShare[],
    maxShares: number,
): ExpressionValueShare[] {
    return shares.length > maxShares
        ? shares.slice(0, maxShares - 1)
        : shares;
}

type LabelValuePair = {
    label: string;
    value: number;
};

function addOthersShare(
    labelValuePairs: LabelValuePair[],
    roundingPrecision: number,
): void {
    const formattedSharesSum = labelValuePairs.reduce(
        (sum, { value }) => sum + value,
        0.,
    );

    if (equals(formattedSharesSum, 1., roundingPrecision)) {
        return;
    }

    labelValuePairs.push({
        label: 'Andere',
        value: roundToPrecision(1 - formattedSharesSum, roundingPrecision),
    });
}

export function parseChartData(
    shares: ExpressionValueShare[],
    maxShares: number,
    minimumPercentageValue: number,
    roundingPrecision = 2,
): { labels: string[], data: number[] } {
    const formattedShares = extractSignificantShares(shares, maxShares)
        .map(({ label, value }) => ({
            label: truncateLabel(label),
            value: roundToPrecision(value, roundingPrecision),
        }))
        .filter(({ value }) => value >= (minimumPercentageValue / 100))
        .filter(({ value }) => !equals(value, 0., roundingPrecision));

    addOthersShare(formattedShares, roundingPrecision);

    const labels = formattedShares.map(({ label }) => label);
    const data = formattedShares.map(({ value }) => value);

    return { labels: labels, data: data };
}

function createChartConfig(
    labels: string[],
    data: number[],
): Pick<ShareChartConfig, 'datasets' | 'plugins' | 'options'> {
    return {
        datasets: [{
            label: 'Shares',
            data,
            backgroundColor: COLORS,
            hoverBackgroundColor: COLORS,
            hoverBorderColor: COLORS,
        }],
        plugins: [ChartDataLabels],
        options: {
            layout: {
                padding: {
                    top: 60,
                    bottom: 60,
                },
            },
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                datalabels: {
                    display: true,
                    color: StandardFont.color,
                    align: 'end',
                    offset: 50,
                    font: {
                        size: StandardFont.size,
                        family: StandardFont.family,
                        weight: StandardFont.weight,
                    },
                    formatter: createFormatPercentage(PERCENTAGE_ROUNDING_PRECISION),
                },
                legend: {
                    labels: {
                        font: {
                            size: StandardFont.size,
                            family: StandardFont.family,
                            weight: 'normal',
                        },
                        color: StandardFont.color,
                    },
                },
                tooltip: {
                    bodyFont: {
                        size: StandardFont.size,
                        family: StandardFont.family,
                        weight: String(StandardFont.weight),
                    },
                    callbacks: {
                        label: (tooltipItem: TooltipItem<'doughnut'>) => labels[tooltipItem.dataIndex],
                    },
                    displayColors: false,
                    padding: {
                        bottom: 8,
                        left: 8,
                        right: 8,
                        top: 10,
                    },
                },
            },
        },
    };
}

@Component({
    selector: 'app-doughnut-chart-data-visualization',
    templateUrl: './doughnut-chart-data-visualization.component.html',
    styleUrls: ['./doughnut-chart-data-visualization.component.scss'],
    standalone: true,
    imports: [
        NgChartsModule,
        DoughnutChartDataVisualizationLegendComponent,
    ],
})
export class DoughnutChartDataVisualizationComponent implements OnInit {

    @Input({ required: true }) dataVisualization!: DataVisualization;

    readonly colors = COLORS;

    labels: string[] = [];

    datasets: ShareChartDatasets = [];

    plugins: ShareChartConfig['plugins'] = [];

    options: ShareChartOptions = {};

    ngOnInit(): void {
        const { labels, data } = parseChartData(
            this.dataVisualization.singleValueShares,
            MAX_SHARES,
            MINIMUM_PERCENTAGE_VALUE,
            ROUNDING_PRECISION,
        );

        const { datasets, plugins, options } = createChartConfig(labels, data);

        this.labels = labels;
        this.datasets = datasets;
        this.plugins = plugins;
        this.options = options;
    }
}
