import { Inject, Injectable } from '@angular/core';
import { Hierarchy, HIERARCHY_TOKEN } from '@app/core/authentication/services/hierarchy';
import { UserRole } from '@app/shared/models/user-role.model';

@Injectable({
    providedIn: 'root',
})
export class RoleHierarchy {

    private readonly map: {[key in UserRole]?: Set<UserRole>} = {};

    constructor(
        @Inject(HIERARCHY_TOKEN) private readonly hierarchy: Hierarchy,
    ) {
    }

    getReachableRoles(role: UserRole): Set<UserRole> {
        let reachableRoles = this.map[role];

        if (undefined !== reachableRoles) {
            return reachableRoles;
        }

        reachableRoles = this.map[role] =this.collectReachableRoles(role);

        return reachableRoles;
    }

    private collectReachableRoles(
        role: UserRole,
        collectedRoles: Set<UserRole> = new Set(),
        visitedRoles: Set<UserRole> = new Set(),
    ): Set<UserRole> {
        collectedRoles.add(role);

        const inheritedRoles = this.hierarchy[role];

        if (visitedRoles.has(role)) {
            return collectedRoles;
        } else {
            visitedRoles.add(role);
        }

        if (undefined === inheritedRoles) {
            return collectedRoles;
        }

        for (const inheritedRole of inheritedRoles) {
            const subInheritedRoles = this.collectReachableRoles(
                inheritedRole,
                collectedRoles,
                visitedRoles,
            );

            for (const subInheritedRole of subInheritedRoles) {
                collectedRoles.add(subInheritedRole);
            }
        }

        return collectedRoles;
    }
}
