import { CommonModule } from '@angular/common';
import { HttpClientModule as AngularHttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { CoreModule } from '@app/core/core.module';
import { AppErrorHandler } from '@app/core/services/app-error-handler.service';
import { SharedModule } from '@app/shared/shared.module';
import * as Sentry from '@sentry/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AngularHttpClientModule,
        AppRoutingModule,
        CommonModule,
        CoreModule,
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: AppErrorHandler,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
    }
}
