import { Injectable } from '@angular/core';
import { Authenticator } from '@app/core/authentication/services/authenticator.service';
import { RoleHierarchy } from '@app/core/authentication/services/role-hierarchy.service';
import { UserRole } from '@app/shared/models/user-role.model';
import { User } from '@app/shared/models/user.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {

    constructor(
        private readonly authenticator: Authenticator,
        private readonly roleHierarchy: RoleHierarchy,
    ) {
    }

    canLoginAs(userToImpersonate: User, viewer: User | null, isImpersonator: boolean): boolean {
        if (isImpersonator || !userToImpersonate.enabled) {
            return false;
        }

        const userToImpersonateRole = userToImpersonate.role;

        if (undefined === userToImpersonateRole) {
            return false;
        }

        const viewerRole = viewer?.role;

        const emailsAreDifferent = !!viewer && viewer.email !== userToImpersonate.email;
        const hasSamePermsOrHigher = !!viewerRole && this.isGranted(viewerRole, userToImpersonateRole);

        return emailsAreDifferent && hasSamePermsOrHigher;
    }

    isViewerGranted(role: UserRole): Observable<boolean> {
        return this.authenticator.getViewer()
            .pipe(
                map(
                    (viewer) => {
                        const viewerRole = viewer?.role;

                        if (!viewerRole) {
                            return false;
                        }

                        return this.isGranted(viewerRole, role);
                    },
                ),
                catchError(() => of(false)),
            );
    }

    isViewer(role: UserRole): Observable<boolean> {
        return this.authenticator.getViewer()
            .pipe(
                map((viewer) => viewer?.role === role),
                catchError(() => of(false)),
            );
    }

    isGranted(currentRole: UserRole, roleChecked: UserRole): boolean {
        if (currentRole === roleChecked) {
            return true;
        }

        const reachableRoles = this.roleHierarchy.getReachableRoles(currentRole);

        return reachableRoles.has(roleChecked);
    }
}
