import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Authenticator } from '@app/core/authentication/services/authenticator.service';
import { RedirectUrlFactory } from '@app/core/authentication/services/redirect-url-factory.service';
import { FailedChunkErrorHandler } from '@app/core/services/failed-chunk-error-handler.service';
import * as Sentry from '@sentry/angular';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { RequestAppVersionChecker } from "@app/core/http/request-app-version-checker.service";

interface PromiseError {
    rejection: Error,
}

function isPromiseError(error: any): error is PromiseError {
    return 'object' === typeof error
        && error['rejection'] instanceof Error;
}

function isMissingJwtTokenError(error: any): error is Error {
    return error instanceof Error
        && 'Could not get token from tokenGetter function.' === error.message;
}

function createSentryErrorHandler(): ErrorHandler {
    return Sentry.createErrorHandler({
        showDialog: false,
    });
}

@Injectable()
export class AppErrorHandler implements ErrorHandler {
    private readonly innerErrorHandler: ErrorHandler;

    constructor(
        private authenticator: Authenticator,
        private router: Router,
        private loginUrlFactory: RedirectUrlFactory,
        private zone: NgZone,
        versionChecker: RequestAppVersionChecker,
    ) {
        this.innerErrorHandler = new FailedChunkErrorHandler(
            createSentryErrorHandler(),
            versionChecker,
        );
    }

    handleError(error: any): void {
        if (isPromiseError(error)) {
            return this.handleError(error.rejection);
        }

        if (isMissingJwtTokenError(error)) {
            return this.handleMissingJwtTokenError();
        }

        this.innerErrorHandler.handleError(error);
    }

    private handleMissingJwtTokenError() {
        Sentry.addBreadcrumb({ message: 'Missing JWT token. Redirecting to the login page.' });

        this.logout()
            .pipe(switchMap(() => this.redirectToTheLoginPage()))
            .subscribe();
    }

    private logout(): Observable<null> {
        return this.authenticator
            .logout()
            .pipe(take(1));
    }

    private redirectToTheLoginPage(): Promise<boolean> {
        const redirectUrl = this.createRedirectUrl();

        const navigateToRedirectUrl = () => this.router.navigateByUrl(redirectUrl);

        return NgZone.isInAngularZone()
            ? navigateToRedirectUrl()
            : this.zone.run(() => navigateToRedirectUrl());

    }

    private createRedirectUrl(): UrlTree {
        const currentUrl = this.router.routerState.snapshot.url;

        return this.loginUrlFactory.createLoginUrlWithOriginalUrl(currentUrl);
    }
}
