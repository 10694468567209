<button color="green"
        class="download-evaluation"
        (click)="onDownloadEvaluation()"
        [loading]="downloadInProgress"
        [disabled]="downloadInProgress"
        type="button"
        i18n="@@evaluationDownloadButton">
    <app-icon name="download"></app-icon>
    Auswertung herunterladen
</button>
