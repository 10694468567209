import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route } from '@angular/router';
import { Authenticator } from '@app/core/authentication/services/authenticator.service';
import { isUserRole, UserRole } from '@app/shared/models/user-role.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const IS_ROLE = 'isRole';

function extractRoles(route: Route): UserRole[] {
    const roleData = route.data?.[IS_ROLE];

    if (isUserRole(roleData)) {
        return [roleData];
    }

    if (Array.isArray(roleData)) {
        return roleData.filter(isUserRole);
    }

    return [];
}

@Injectable({
    providedIn: 'root',
})
export class IsRoleGuard implements CanActivate {

    constructor(
        private readonly authenticator: Authenticator,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const routeConfig = route.routeConfig;

        if (null === routeConfig) {
            return of(false);
        }

        const roles = extractRoles(routeConfig);

        if (0 === roles.length) {
            return of(false);
        }

        return this.authenticator.getViewer()
            .pipe(map((viewer) => roles.some((role) => viewer?.role === role)));
    }
}
