<div class="container yes"
     [ngClass]="{
                    selected: yesValue === formControl.value,
                    narrow: narrow,
                    disabled: formControl.disabled,
                    'error-color': yesColor  === 'error'
                }"
     (click)="onClick(yesValue)"
     i18n="@@term.yes">
    Ja
</div>
<div class="container no"
     [ngClass]="{
                    selected: noValue === formControl.value,
                    narrow: narrow,
                    disabled: formControl.disabled,
                    'error-color': noColor  === 'error'
                }"
     (click)="onClick(noValue)"
     i18n="@@term.no">
    Nein
</div>
