import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Directive, ElementRef, Host, HostBinding, HostListener, Input, OnDestroy, OnInit, Optional,
    Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { FileUploadComponent } from '@app/shared/components/file-upload/file-upload.component';
import {
    ToggleButtonComponent,
} from '@app/shared/components/toggle-button/toggle-button.component';
import { IconName } from '@icons/svg-icons';
import { EMPTY, Subject, timer } from 'rxjs';
import { debounce, debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

const SHOW_INVALID_ERROR_DEBOUNCE_TIME_MS = 1000;

type HTMLInputTypeAttribute =
    'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';

let nextUniqueId = 0;

@Directive({
    selector: 'input[appInput], mat-checkbox[appInput], mat-select[appInput], app-toggle-button[appInput], app-file-upload[appInput], textarea[appInput], app-phone-number-input[appInput]',
    host: {
        '[required]': 'required',
    },
})
export class InputDirective implements OnInit, OnDestroy {
    private _required = false;

    private readonly isDebouncableControl: boolean;

    public readonly id: string;

    public hasFocus: boolean = false;

    private hadFocus = false;

    private showError?: boolean;

    readonly isCheckbox: boolean;

    readonly isStyled: boolean = false;

    private ngUnsubscribe = new Subject<void>();

    readonly hasClickableLabel: boolean;

    private readonly isHtmlInput: boolean;

    private get shouldDebounceError(): boolean {
        return this.isDebouncableControl
            // debounce only when showError is unset, i.e. initially
            && undefined === this.showError;
    }

    @Input()
    get required(): BooleanInput {
        return this._required;
    }

    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
    }

    @Input() icon?: IconName;

    @Input('type') initialType?: HTMLInputTypeAttribute;

    @HostBinding('attr.type')
    typeAttribute?: HTMLInputTypeAttribute;

    @HostBinding('attr.id')
    get idToBind(): string | null {
        // shouldn't bind id on this level for checkboxes
        return !this.isCheckbox ? this.id : null;
    }

    @HostBinding('class.alert')
    get errorState(): boolean | null {
        if (null === this.ngControl) {
            return false;
        }

        const submittedWithoutFocusing = (!this.hadFocus && true === this.ngControl.touched);

        return (this.showError || submittedWithoutFocusing)
            && this.ngControl.invalid
            && (this.ngControl.dirty || this.ngControl.touched);
    }

    @HostBinding('class.input-icon')
    get hasIcon(): boolean {
        return !!this.icon;
    }

    @HostListener('focus')
    onFocus() {
        this.hasFocus = !this.ngControl?.disabled;
        this.hadFocus = true;
    }

    @HostListener('focusout')
    onFocusOut() {
        this.hasFocus = false;
        this.showError = true;
    }

    constructor(@Optional() @Self() private ngControl: NgControl | null,
                @Host() @Self() @Optional() checkboxComponent: MatCheckbox,
                @Host() @Self() @Optional() toggleButton: ToggleButtonComponent,
                @Host() @Self() @Optional() matSelect: MatSelect,
                @Host() @Self() @Optional() fileInput: FileUploadComponent,
                elementRef: ElementRef) {
        this.isHtmlInput = elementRef.nativeElement instanceof HTMLInputElement;

        this.isCheckbox = !!checkboxComponent;

        this.isStyled = this.isCheckbox || !!toggleButton || !!fileInput;

        this.isDebouncableControl = !this.isCheckbox
            && !toggleButton
            && !matSelect;

        this.id = this.isCheckbox
            ? checkboxComponent.inputId // checkbox generates an id internally
            : `input-${nextUniqueId++}`;

        this.hasClickableLabel = !toggleButton && !matSelect;
    }

    ngOnInit(): void {
        if (this.ngControl?.touched) {
            this.showError = !this.ngControl.valid;
        }

        this.subscribeToStatusChanges();
        this.setupType();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    togglePasswordVisibility(): void {
        if (!this.isHtmlInput) {
            throw new Error('Expected a HTML input element.');
        }

        this.typeAttribute = 'password' === this.typeAttribute ? 'text' : 'password';
    }

    private subscribeToStatusChanges(): void {
        if (!this.ngControl?.statusChanges) {
            return;
        }

        this.ngControl.statusChanges
            .pipe(
                debounceTime(5), // debounce quick status changes
                tap((status) => {
                    if ('VALID' === status) {
                        this.showError = false; // reset error instantly as soon as the input becomes valid
                    }
                }),
                filter((status) => 'INVALID' === status),
                debounce(() => this.shouldDebounceError ? timer(SHOW_INVALID_ERROR_DEBOUNCE_TIME_MS) : EMPTY),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(
                () => this.showError = true,
            );
    }

    private setupType(): void {
        this.typeAttribute = this.isHtmlInput
            ? this.initialType || 'text'
            : undefined;
    }
}
