import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AuthorizationService } from '@app/core/authentication/services/authorization.service';
import { AssetService } from '@app/core/services/asset.service';
import { Workflow } from '@app/shared/models/workflow.model';
import { Company } from '@app/shared/models/company.model';
import { Request } from '@app/shared/models/request/request.model';
import { UserRole } from '@app/shared/models/user-role.model';
import { NavigationService } from '@app/shared/services/navigation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

function isEntireBusinessCertified(request?: Request): boolean {
    return Workflow.AUDIT === request?.workflow;
}

interface ColumnDefinition {
    label: string,

    needsCertification?: boolean,

    showDetails?: boolean;
}

const COLUMNS: ColumnDefinition[] = [
    {
        label: 'companyName',
    },
    {
        label: 'assets',
        needsCertification: true,
    },
    {
        label: 'details',
        showDetails: true,
    },
];

function mapToColumns(request: Request | undefined, showDetailedContacts: boolean): string[] {
    if (undefined === request) {
        return [];
    }

    return COLUMNS
        .filter((definition) => !definition.needsCertification || request.isCertified)
        .filter((definition) => !definition.showDetails || showDetailedContacts)
        .map((definition) => definition.label);
}

@Component({
    selector: 'app-certification-download-table',
    templateUrl: './certification-download-table.component.html',
    styleUrls: ['./certification-download-table.component.scss'],
})
export class CertificationDownloadTableComponent implements OnChanges, OnDestroy {

    @Input() request?: Request;

    @Input() showDetailedContacts = false;

    isWinery?: boolean;

    private readonly ngUnsubscribe = new Subject<void>();

    get companies(): Company[] {
        return this.request?.companies || [];
    }

    columns: string[] = [];

    get showLogos(): boolean {
        return isEntireBusinessCertified(this.request);
    }

    constructor(
        private assetService: AssetService,
        private authorizationService: AuthorizationService,
        private navigationService: NavigationService,
    ) {
        this.authorizationService.isViewer(UserRole.WINERY_USER)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((isWinery) => this.isWinery = isWinery);
    }

    ngOnChanges(changes: SimpleChanges) {
        const requestChanged = 'request' in changes;
        const showDetailedContactsChanged = 'showDetailedContacts' in changes;

        if (requestChanged || showDetailedContactsChanged) {
            this.columns = mapToColumns(this.request, this.showDetailedContacts);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onDownloadGermanCertificate(company: Company): void {
        this.assetService.downloadCompanyGermanCertificate(
                company.id,
                company.registerNumber,
                String(this.request?.growingSeasonId),
            )
            .subscribe();
    }

    onDownloadGermanPrintCertificate(company: Company): void {
        this.assetService.downloadCompanyGermanPrintCertificate(
                company.id,
                company.registerNumber,
                String(this.request?.growingSeasonId),
            )
            .subscribe();
    }

    onDownloadEnglishCertificate(company: Company): void {
        this.assetService.downloadCompanyEnglishCertificate(
                company.id,
                company.registerNumber,
                String(this.request?.growingSeasonId),
            )
            .subscribe();
    }

    onDownloadEnglishPrintCertificate(company: Company): void {
        this.assetService.downloadCompanyEnglishPrintCertificate(
                company.id,
                company.registerNumber,
                String(this.request?.growingSeasonId),
            )
            .subscribe();
    }

    onDownloadLogos(company: Company): void {
        this.assetService.downloadCompanyLogos(
                company.id,
                company.registerNumber,
                String(this.request?.growingSeasonId),
            )
            .subscribe();
    }

    onDetailClick(company: Company): void {
        if (!this.showDetailedContacts) {
            return;
        }

        this.navigationService.navigateToCompanyPage(company.id);
    }
}
