<div class="canvas-container">
    <canvas baseChart
            [datasets]="datasets"
            [plugins]="plugins"
            [options]="options"
            [legend]="true"
            type="doughnut">
    </canvas>
</div>

<p>
    <app-doughnut-chart-data-visualization-legend [colors]="colors"
                                                  [labels]="labels"></app-doughnut-chart-data-visualization-legend>
</p>
