import { EnvironmentContract } from '@environments/environment-contract';

export const environment: EnvironmentContract = {
    client: 'nwbw',
    production: true,
    environment: 'prod',
    enableDebugTools: false,
    apiUrl: 'https://tool.wein-nachhaltig.de/api',   // For testing the pdf generator use 'http://host.docker.internal:8035/api'
    allowedJwtDomains: ['tool.wein-nachhaltig.de',/^localhost/],
    markMeasureAsExcludedEnabled: true,
    sentryDsn: 'https://2a83078b9a814df08b7438fb44f71d11@sentry.webmozarts.com/9',
    sentryEnvironment: 'prod',
    sentryShowErrorDialog: false,
    sentryTracesSampleRate: 0.2,
    sentryRelease: 'nat@20.7',
    releaseVersion: '20.7',
    releaseTimestamp: 1730800900,
    minExpressionValueShareDisplay: 0.01,
    enableRouterTracing: false,
    logo: 'logo.png',
    loginImg: null,
};
