<app-state-box state="error">
    <app-state-box-headline i18n="@@goal.notQualifiedBox.headline">
        Sie sind leider aktuell nicht zur Zertifizierung qualifiziert.
    </app-state-box-headline>

    <p i18n="@@goal.notQualifiedBox.explanation">
        Derzeit erfüllen Sie nicht die Anforderungen für eine positive
        Zertifizierung. Es müssen mindestens sieben von neun
        Nachhaltigkeitskriterien im grünen und keinesfalls ein Kriterium im
        roten Bereich sein.
    </p>

    <p *ngIf="isPreliminaryAssessment"
       i18n="@@goal.notQualifiedBox.preliminaryAssessmentExplanation">
        Sie können sich dennoch für eine Zertifizierung vormerken. Bitte stellen
        Sie in diesem Fall sicher, dass Sie bis zum Einreichen Ihrer
        Zertifizierungsanfrage Ihre Bewirtschaftungsweise verbessern.
    </p>

    <button color="green"
            primary
            (click)="submit()"
            [disabled]="submitDisabled">
        {{ submitText }}
    </button>

    <label *ngIf="officialDeadline">
        <app-icon name="exclamation_circle"></app-icon>
        <span i18n="@@model.request.deadline">Einreichfrist: {{ officialDeadline | germanDate }}</span>
    </label>

    <p *ngIf="!submitDisabled && submitLaterInfo"
       i18n="@@goal.notQualifiedBox.delayedSubmissionNote">
        Sie können Ihre Eingabe auch später einreichen. Öffnen Sie dazu den
        Menüpunkt <strong>Zertifizierung</strong> und klicken Sie auf Ihre
        Eingabe.
    </p>
</app-state-box>
