import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

function formatPhoneNumberString(phoneNumberString: string): string {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();

    let phoneNumber: PhoneNumber;

    try {
        phoneNumber = phoneNumberUtil.parse(phoneNumberString, 'AT');
    } catch (error) {
        return phoneNumberString;
    }

    return phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
}

@Component({
    selector: 'app-phone-number-input',
    templateUrl: './phone-number-input.component.html',
    styleUrls: ['./phone-number-input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: PhoneNumberInputComponent,
        multi: true,
    }],
})
export class PhoneNumberInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

    private onTouched?: () => void;

    private onChange?: (value: any) => void;

    private ngUnsubscribe = new Subject<void>();

    control = new UntypedFormControl('', []);

    ngOnInit() {
        this.subscribeToValueChanges();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    writeValue(value: any): void {
        if (!value) {
            return;
        }

        this.control.patchValue(value);
        this.control.markAsTouched();

        this.onPhoneNumberBlur();
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    onPhoneNumberBlur(): void {
        const controlValue = this.control.value;

        if (!controlValue || !this.control.valid) {
            return;
        }

        const formattedPhoneNumber = formatPhoneNumberString(controlValue);

        this.control.setValue(formattedPhoneNumber);
    }

    private subscribeToValueChanges(): void {
        this.control.valueChanges
            .pipe(
                tap((value) => {
                    this.onChange?.(value);
                    this.onTouched?.();
                }),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe();
    }
}
