import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import SelectOption, { createSelectOptions } from '@app/shared/components/form-field/select-option';
import { Gender, GENDER_NAMES } from '@app/shared/models/gender.model';

@Component({
    selector: 'app-gender-form-field',
    templateUrl: './gender-form-field.component.html',
    styleUrls: ['./gender-form-field.component.scss'],
})
export class GenderFormFieldComponent implements OnInit {

    readonly genderOptions: SelectOption[] = createSelectOptions(Gender, GENDER_NAMES);

    @Input({ required: true }) control!: UntypedFormControl;

    constructor() {
    }

    ngOnInit() {
        if (!this.control) {
            throw Error('No control provided.');
        }
    }
}
