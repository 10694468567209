import { IConfig } from 'ngx-mask';

export const WEAK_PASSWORD_ERROR_MESSAGE = $localize`:@@profile.system.weakPasswordErrorMessage:Bitte geben Sie ein Passwort mit zumindest 8 Zeichen, einem Kleinbuchstaben, einem Großbuchstaben und einer Ziffer ein.`;

export const PAGE_SIZE_OPTIONS = [20, 50, 100];
export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];

export const DECIMAL_MARKER: IConfig['decimalMarker'] = ',';

export const LOCALE = 'de-AT';
