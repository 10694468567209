import {
    AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild, ViewContainerRef,
} from '@angular/core';
import {
    Notification, NotificationService, NotificationStatus,
} from '@app/core/services/notification.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

const AUTO_CLOSE_INTERVAL_IN_MS = 5000;

@Component({
    selector: 'app-notification-message',
    templateUrl: './notification-message.component.html',
    styleUrls: ['./notification-message.component.scss'],
})
export class NotificationMessageComponent implements AfterViewInit, OnDestroy {

    error = false;

    message?: string;

    @ViewChild('viewContainer', { read: ViewContainerRef }) viewContainer!: ViewContainerRef;

    @ViewChild('notification', { read: TemplateRef }) notificationTemplate!: TemplateRef<any>;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        private readonly notificationService: NotificationService,
    ) {
    }

    ngAfterViewInit(): void {
        this.notificationService.getNotifications()
            .pipe(
                tap((notification) => this.onNotify(notification)),
                debounceTime(AUTO_CLOSE_INTERVAL_IN_MS),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(
                () => this.closeNotification(),
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public closeNotification(): void {
        this.viewContainer.clear();
    }

    private onNotify(notification: Notification): void {
        this.closeNotification();

        this.error = NotificationStatus.ERROR === notification.status;
        this.message = notification.message;

        this.viewContainer.createEmbeddedView(this.notificationTemplate);
    }
}
