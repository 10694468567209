<app-timeline-step *ngFor="let step of steps; let index = index; last as isLast"
                   [active]="isStepSelectedByIndex(index)"
                   [done]="isStepDoneByIndex(index)"
                   [isLastStep]="isLast">

    <app-timeline-label *ngIf="step.label else renderWithoutRegularLabel" [innerHTML]="step.label" [id]="_getStepLabelId(index)">
    </app-timeline-label>

    <ng-template #renderWithoutRegularLabel>
        <app-timeline-label [id]="_getStepLabelId(index)">
            <ng-container *ngIf="step.stepLabel?.template as stepLabelTemplate" [ngTemplateOutlet]="stepLabelTemplate"></ng-container>
        </app-timeline-label>
    </ng-template>

    <ng-container *ngIf="isStepSelectedByIndex(index)">
        <ng-container [ngTemplateOutlet]="step.content"></ng-container>
    </ng-container>
</app-timeline-step>
