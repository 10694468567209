import { Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent {

    constructor() {
    }

}
