import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum NotificationStatus {
    SUCCESS,
    ERROR
}

export interface Notification {
    status: NotificationStatus,

    message: string,
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    private notifications = new Subject<Notification>();

    notify(status: NotificationStatus, message: string): void {
        this.notifications.next({ status, message });
    }

    getNotifications(): Observable<Notification> {
        return this.notifications.asObservable();
    }

    notifyDefaultError(): void {
        this.notify(
            NotificationStatus.ERROR,
            'Ein Fehler ist aufgetreten',
        );
    }
}
