import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dropdown-button-option',
    templateUrl: './dropdown-button-option.component.html',
    styleUrls: ['./dropdown-button-option.component.scss'],
})
export class DropdownButtonOptionComponent {

    @Input() disabled: boolean = false;

    constructor() {
    }

}
