<header (click)="toggleContent()" [ngClass]="{ 'toggle-position-after': togglePosition === 'after', interactive: interactive }">
    <ng-container *ngIf="togglePosition === 'before' then toggle"></ng-container>

    <div class="headline">
        <ng-content select="[header]">
        </ng-content>
    </div>

    <ng-container *ngIf="togglePosition === 'after' then toggle"></ng-container>

    <ng-template #toggle>
        <app-icon *ngIf="interactive"
                  centered
                  name="chevron_down"></app-icon>
    </ng-template>
</header>

<main>
    <ng-content></ng-content>
</main>

<p *ngIf="!isOpen && summary" class="summary">
    {{ summary }}
</p>
