import { Injectable } from '@angular/core';
import { TokenOrNull } from '@app/core/authentication/models/access-token.model';
import { AccessTokenStorage } from '@app/core/authentication/services/access-token-storage.service';
import { AuthenticationService } from '@app/core/authentication/services/authentication.service';
import { ImpersonatedUserTokenStorage } from '@app/core/authentication/services/impersonated-user-token-storage.service';
import { UserService } from '@app/core/services/user.service';
import { User } from '@app/shared/models/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class Authenticator {

    private readonly isImpersonatorSource = new ReplaySubject<boolean>(1);

    public readonly isImpersonator = this.isImpersonatorSource.asObservable();

    private readonly viewerSource = new ReplaySubject<User | null>(1);

    private readonly viewer = this.viewerSource.asObservable();

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly userService: UserService,
        private readonly accessTokenStorage: AccessTokenStorage,
        private readonly jwtHelper: JwtHelperService,
        private readonly impersonatedUserTokenStorage: ImpersonatedUserTokenStorage,
    ) {
        this.isImpersonatorSource.next(null !== impersonatedUserTokenStorage.getUserId());

        this.initializeViewer();
    }

    getViewer(): Observable<User | null> {
        return this.viewer;
    }

    refetchViewer(): Observable<User | null> {
        return this.userService
            .fetchViewer()
            .pipe(
                tap((viewer) => this.viewerSource.next(viewer)),
            );
    }

    login(username: string, password: string): Observable<User | null> {
        this.impersonatedUserTokenStorage.removeUserId();
        this.isImpersonatorSource.next(false);

        return this.authenticationService
            .login(username, password)
            .pipe(
                switchMap(
                    (token) => {
                        this.accessTokenStorage.setToken(token);

                        return this.refetchViewer();
                    },
                ),
                catchError((error) => this.logout().pipe(switchMap(() => throwError(error)))),
            );
    }

    logout(): Observable<null> {
        this.accessTokenStorage.removeToken();
        this.viewerSource.next(null);

        this.impersonatedUserTokenStorage.removeUserId();
        this.isImpersonatorSource.next(false);

        return of(null);
    }

    loginAsUser(id: string): Observable<User> {
        this.impersonatedUserTokenStorage.setUserId(id);

        return this.refetchViewer().pipe(
            switchMap((viewer) => {
                if (null === viewer) {
                    this.impersonatedUserTokenStorage.removeUserId();
                    this.isImpersonatorSource.next(false);

                    // In the impersonation failed; we reverted our changes but
                    // we need to fetch the user again (not as impersonated)
                    // since the viewer is currently `null`
                    return this.refetchViewer()
                        .pipe(
                            switchMap(() => throwError(new Error('Could not log in as user'))),
                        );
                }

                this.isImpersonatorSource.next(true);

                return of(viewer);
            }),
        );
    }

    restoreOriginalUser(): Observable<User> {
        this.impersonatedUserTokenStorage.removeUserId();

        return this.refetchViewer().pipe(
            switchMap((viewer) => {
                this.isImpersonatorSource.next(false);

                if (null === viewer) {
                    // In theory we should already be logged out and redirected
                    // to the login if necessary;
                    // Hard to reproduce so we cross the fingers that this won't
                    // happen.
                    return throwError(new Error('Could not restore the original user'));
                }

                return of(viewer);
            }),
        );
    }

    /**
     * @deprecated
     */
    isCurrentlyAuthenticated(): boolean {
        return this.isTokenValid(this.accessTokenStorage.getToken());
    }

    isAuthenticated(): Observable<boolean> {
        return this.viewer
            .pipe(map((viewer) => null !== viewer));
    }

    confirmEmail(emailConfirmationToken: string): Observable<User | null> {
        return this.userService
            .confirmEmail(emailConfirmationToken)
            .pipe(
                switchMap(() => this.refetchViewer()),
            );
    }

    private initializeViewer(): void {
        const token = this.accessTokenStorage.getToken();

        if (null === token || !this.isTokenValid(token)) {
            this.viewerSource.next(null);
        } else {
            this.refetchViewer()
                .subscribe();
        }
    }

    private isTokenValid(token: TokenOrNull): boolean {
        return null !== token && !this.jwtHelper.isTokenExpired(token.getValue());
    }
}
