import { Unit } from '@app/shared/models/unit';

export enum InputType {
    NUMBER_INPUT = 'numberInput',
    DROPDOWN = 'dropdown',
    CHECKBOX = 'checkbox',
}

const INPUT_TYPE_BY_UNIT: Record<Unit, InputType|null> = {
    [Unit.AMOUNT]: InputType.NUMBER_INPUT,
    [Unit.AREA]: InputType.NUMBER_INPUT,
    [Unit.BOOLEAN]: InputType.CHECKBOX,
    [Unit.ENERGY]: InputType.NUMBER_INPUT,
    [Unit.LITERS]: InputType.NUMBER_INPUT,
    [Unit.MILLIMETER]: InputType.NUMBER_INPUT,
    [Unit.PERCENT]: InputType.NUMBER_INPUT,
    [Unit.VOLUME]: InputType.NUMBER_INPUT,
    [Unit.WEIGHT]: InputType.NUMBER_INPUT,
    [Unit.NOTE]: null,
};

const REQUIRED_MESSAGE: Record<InputType, string> = {
    [InputType.DROPDOWN]: 'Bitte wählen Sie einen Wert aus.',
    [InputType.NUMBER_INPUT]: 'Bitte geben Sie einen Wert ein.',
    [InputType.CHECKBOX]: 'TODO', // Unused required message
};

export function getInputTypeByUnit(unit: Unit): InputType | null {
    return INPUT_TYPE_BY_UNIT[unit];
}

export function getRequiredMessage(inputType: InputType): string {
    return REQUIRED_MESSAGE[inputType];
}
