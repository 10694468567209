import { Injectable } from '@angular/core';

const LOCAL_STORAGE_KEY = 'impersonatedUserId';

@Injectable({
    providedIn: 'root',
})
export class ImpersonatedUserTokenStorage {
    setUserId(userId: string): void {
        localStorage.setItem(LOCAL_STORAGE_KEY, userId);
    }

    removeUserId(): void {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    }

    getUserId(): string | null {
        return localStorage.getItem(LOCAL_STORAGE_KEY);
    }
}
