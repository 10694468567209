import {
    Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { parseControlValue } from '@app/assessment/services/measure-control-value-converter';
import { DECIMAL_MARKER } from '@app/constants';
import forceInvalid from '@app/shared/components/forms/validators/forceInvalid.validator';
import requiredValidator from '@app/shared/components/forms/validators/required.validator';
import { InputType } from '@app/shared/models/input-type';
import { BaseValueDefinition } from '@app/shared/models/process/base-value-definition.model';
import { Unit } from '@app/shared/models/unit';
import { IconName } from '@icons/svg-icons';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

type BaseValueChangeCallback = (value: string | number | null) => void;

export function parseBaseValueInput(
    rawValue: string | number | null,
    baseValueDefinition: BaseValueDefinition,
): string | number | null {
    if (baseValueDefinition.unit) {
        return parseControlValue(rawValue, baseValueDefinition.unit);
    }

    if (InputType.NUMBER_INPUT === baseValueDefinition.inputType) {
        // We assume that all number inputs have a unit
        throw new Error(
            `Base value definition ${baseValueDefinition.id} has a number input but without a unit.`,
        );
    }

    const trimmedValue = 'string' === typeof rawValue
        ? rawValue.trim()
        : rawValue;

    return null === trimmedValue || '' === trimmedValue
        ? null
        : trimmedValue;
}

@Component({
    selector: 'app-base-value-input',
    templateUrl: './base-value-input.component.html',
    styleUrls: ['./base-value-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseValueInputComponent),
            multi: true,
        },
    ],
})
export class BaseValueInputComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {

    private onTouched?: () => void;

    private onChange?: BaseValueChangeCallback;

    readonly baseValueControl = new UntypedFormControl(
        null,
        [
            requiredValidator,
            // The control needs to be invalid in order for the
            // `app-form-field-error` field to get rendered
            forceInvalid(() => !!this.errorMessage),
        ],
    );

    private ngUnsubscribe = new Subject<void>();

    @Input() touched: boolean = false;

    @Input({ required: true }) baseValueDefinition!: BaseValueDefinition;

    @Input() iconAfterInput: IconName | null = null;

    @Input() displayAsYesNoToggle: boolean = false;

    @Input() multipleCompanies: boolean | null = null;

    @Input() errorMessage: string | null = null;

    readonly DECIMAL_MARKER = DECIMAL_MARKER;

    readonly InputType = InputType;

    readonly Unit = Unit;

    constructor() {
    }

    ngOnInit() {
        this.subscribeToFormValueChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        const touchedChange = 'touched' in changes;
        const shouldMarkAsTouched = touchedChange && this.touched;

        if (shouldMarkAsTouched) {
            this.baseValueControl.markAsTouched();
        }

        if ('errorMessage' in changes) {
            this.baseValueControl.updateValueAndValidity();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    writeValue(value: number | string | null): void {
        if (null === value && null === this.baseValueControl.value) {
            return;
        }

        this.baseValueControl.patchValue(value);
        this.baseValueControl.markAsTouched();
    }

    registerOnChange(fn: BaseValueChangeCallback): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.baseValueControl.disable();
        } else {
            this.baseValueControl.enable();
        }
    }

    private subscribeToFormValueChanges(): void {
        this.baseValueControl.valueChanges
            .pipe(
                tap(() => {
                    this.onChange?.(this.getBaseValue());
                    this.onTouched?.();
                }),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe();
    }

    private getBaseValue(): string | number | null {
        return parseBaseValueInput(this.baseValueControl.value, this.baseValueDefinition);
    }
}
