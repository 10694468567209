import { Component } from '@angular/core';
import { AppTitleService } from '@app/app/services/app-title.service';
import { SentryScopeSetter } from '@app/core/authentication/services/sentry-scope-setter.service';
import { RouterErrorService } from '@app/core/services/router-error.service';
import { NotificationService, NotificationStatus } from '@app/core/services/notification.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    constructor(
        appTitleService: AppTitleService,
        private notificationService: NotificationService,
        errorService: RouterErrorService,
        sentryScopeSetter: SentryScopeSetter,
    ) {
        window.addEventListener(
            'offline',
            () => this.notificationService.notify(NotificationStatus.ERROR, 'Keine Internetverbindung'),
        );
        window.addEventListener(
            'online',
            () => this.notificationService.notify(NotificationStatus.SUCCESS, 'Sie sind wieder online'),
        );

        appTitleService.initializeHtmlTitleLoader();
        errorService.initialize();
        sentryScopeSetter.initialize();
    }
}
