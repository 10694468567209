import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Gender } from '@app/shared/models/gender.model';
import { UserFilter } from '@app/shared/models/user-filter.model';
import { UserRole } from '@app/shared/models/user-role.model';
import { User } from '@app/shared/models/user.model';
import { Page, pageOf, SerializingHttpClient } from '@webmozarts/http-client';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

class EmailKnown {
    private _emailKnown: boolean = false;

    set emailKnown(value: string) {
        this._emailKnown = !!value;
    }

    get isKnownEmail(): boolean {
        return this._emailKnown;
    }
}

class ResetPasswordTokenValidity {
    private _exists = false;

    private _expired = false;

    set tokenExists(value: boolean) {
        this._exists = value;
    }

    get exists(): boolean {
        return this._exists;
    }

    set tokenExpired(value: boolean) {
        this._expired = value;
    }

    get expired(): boolean {
        return this._expired;
    }
}

export enum ResetPasswordTokenStatus {
    GONE,
    EXPIRED,
    VALID,
}

function isClientError(error: any) {
    return error instanceof HttpErrorResponse
        && error.status >= 400
        && error.status < 500;
}

export function isEmailInUseError(error: any): boolean {
    // Must match error message defined in the UserController
    const emailInUseErrorMessage = 'The given email address is already in use.';

    return error instanceof HttpErrorResponse
        && 400 === error.status
        && error.error
        && error.error['hydra:description'] === emailInUseErrorMessage;
}

function convertFiltersToUriParam(filter?: UserFilter): string {
    let params = new HttpParams();

    if (!filter) {
        return params.toString();
    }

    if (filter.query) {
        params = params.append('query', filter.query);
    }

    if ('boolean' === typeof filter.status) {
        params = params.append('filter[status]', filter.status.toString());
    }

    if (filter.auditCompanyId) {
        params = params.append('filter[auditCompanyId]', filter.auditCompanyId);
    }

    if (true === filter.orderByLastLoggedInAt) {
        params = params.set('orderBy', 'lastLoggedInAt');
    }

    if (isValidPageNumberOrPageSize(filter.page) && 1 !== filter.page) {
        params = params.append('page', String(filter.page));
    }

    if (isValidPageNumberOrPageSize(filter.pageSize) && Number.isFinite(filter.pageSize)) {
        params = params.append('pageSize', String(filter.pageSize));
    }

    return params.toString();
}

function isValidPageNumberOrPageSize(value: number | null | undefined): boolean {
    return Number.isSafeInteger(value);
}

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(private http: SerializingHttpClient) {
    }

    get(id: string): Observable<User> {
        return this.http
            .get(
                User,
                `/users/${id}`,
            );
    }

    createUser(
        firstName: string,
        lastName: string,
        email: string,
        role: UserRole,
        gender: Gender,
        phoneNumber?: string,
        auditCompanyId?: string,
    ): Observable<User> {
        return this.http
            .post(
                User,
                '/users',
                {
                    id: uuidv4().toString(),
                    firstName,
                    lastName,
                    email,
                    role,
                    gender,
                    ...(phoneNumber && { phoneNumber }),
                    ...(auditCompanyId && { auditCompanyId }),
                },
            );
    }

    updateUser(
        id: string | undefined,
        firstName: string | null | undefined,
        lastName: string | null | undefined,
        email: string | null | undefined,
        role: UserRole | undefined,
        gender: Gender | null | undefined,
        phoneNumber?: string | null,
        auditCompanyId?: string | null,
    ): Observable<User> {
        return this.http
            .put(
                User,
                `/users/${id}`,
                {
                    id,
                    firstName,
                    lastName,
                    email,
                    role,
                    gender,
                    ...(null !== phoneNumber && { phoneNumber }),
                    ...(null !== auditCompanyId && { auditCompanyId }),
                },
            );
    }

    fetchViewer(): Observable<User | null> {
        return this.http
            .get(
                User,
                '/users/viewer',
            )
            .pipe(
                catchError((error) => {
                    if (isClientError(error)) {
                        return of(null);
                    }

                    return throwError(error);
                }),
            );
    }

    isKnownEmail(email: string): Observable<boolean> {
        return this.http
            .post(
                EmailKnown,
                `/public/users/is-known-email`,
                { email },
            )
            .pipe(
                map((emailKnown) => emailKnown.isKnownEmail),
            );
    }

    confirmEmail(emailConfirmationToken: string): Observable<null> {
        return this.http
            .post(
                null,
                `/public/users/confirm-email/${emailConfirmationToken}`,
                null,
            );
    }

    resendConfirmationEmail(): Observable<void> {
        return this.http
            .post(
                null,
                '/users/resend-confirmation-email',
                {},
            )
            .pipe(
                mapTo(void 0),
            );
    }

    register(email: string, password: string): Observable<User> {
        return this.http
            .post(
                User,
                '/public/users',
                {
                    id: uuidv4().toString(),
                    email,
                    password,
                },
            );
    }

    changePassword(oldPassword: string, newPassword: string): Observable<User> {
        return this.http
            .post(
                User,
                '/users/change-password',
                { oldPassword, newPassword },
            );
    }

    isResetPasswordTokenValid(passwordResetToken: string): Observable<ResetPasswordTokenStatus> {
        return this.http
            .post(
                ResetPasswordTokenValidity,
                '/public/users/is-reset-password-token-valid',
                {
                    resetPasswordToken: passwordResetToken,
                },
            )
            .pipe(
                map((token) => {
                    if (!token.exists) {
                        return ResetPasswordTokenStatus.GONE;
                    }

                    if (token.expired) {
                        return ResetPasswordTokenStatus.EXPIRED;
                    }

                    return ResetPasswordTokenStatus.VALID;
                }),
            );
    }

    resetPassword(passwordResetToken: string, newPassword: string): Observable<User> {
        return this.http
            .post(
                User,
                '/public/users/reset-password',
                {
                    passwordResetToken: passwordResetToken,
                    password: newPassword,
                },
            );
    }

    changeEmail(password: string, newEmail: string): Observable<User> {
        return this.http
            .post(
                User,
                '/users/change-email',
                {
                    password: password,
                    email: newEmail,
                },
            );
    }

    requestPasswordReset(email: string): Observable<void> {
        return this.http
            .post(
                null,
                `/public/users/request_password_recovery/${email}`,
                {},
            )
            .pipe(mapTo(void 0));
    }

    migrateLegacyAccount(
        username: string,
        legacyPassword: string,
        password: string,
    ): Observable<User> {
        return this.http
            .post(
                User,
                '/public/users/migrate',
                {
                    id: uuidv4().toString(),
                    username,
                    legacyPassword,
                    password,
                },
            );
    }

    getUsers(filter?: UserFilter): Observable<Page<User>> {
        const params = convertFiltersToUriParam(filter);

        let url = '/users';
        if ('' !== params) {
            url += `?${params}`;
        }

        return this.http.get(pageOf(User), url);
    }

    enableUser(userId: string): Observable<User> {
        return this.enableOrDisableUser(userId, true);
    }

    disableUser(userId: string): Observable<User> {
        return this.enableOrDisableUser(userId, false);
    }

    private enableOrDisableUser(userId: string, enabled: boolean): Observable<User> {
        return this.http
            .put(
                User,
                `/users/${userId}/enabled`,
                { enabled },
            );
    }
}
