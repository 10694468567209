import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, Input, Optional,
} from '@angular/core';
import { IconName } from '@icons/svg-icons';
import { IconRegistry } from 'src/app/shared/components/icons/icon-registry.service';

@Component({
    selector: 'app-icon',
    template: `
        <ng-content></ng-content>
    `,
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class IconComponent {
    private svgIcon: SVGElement|null = null;
    private _centered: boolean = false;

    @Input()
    set name(iconName: IconName) {
        if (this.svgIcon) {
            this.element.nativeElement.removeChild(this.svgIcon);
        }

        const svgData = this.iconRegistry.getIcon(iconName);
        this.svgIcon = undefined !== svgData
            ? this.svgElementFromString(svgData)
            : null;

        if (null === this.svgIcon) {
            return;
        }

        this.element.nativeElement.appendChild(this.svgIcon);
    }

    @HostBinding('class.centered')
    @Input()
    get centered(): BooleanInput {
        return this._centered;
    }

    set centered(value: BooleanInput) {
        this._centered = coerceBooleanProperty(value);
    }

    constructor(
        private element: ElementRef,
        private iconRegistry: IconRegistry,
        @Optional() @Inject(DOCUMENT) private document: any,
) {
    }

    private svgElementFromString(svgContent: string): SVGElement {
        const div = this.document.createElement('DIV');
        div.innerHTML = svgContent;

        return div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
    }
}
