import { Component, HostBinding, Input } from '@angular/core';

export type StateBoxState = 'success' | 'error' | 'info' | 'warning';

@Component({
    selector: 'app-state-box',
    templateUrl: './state-box.component.html',
    styleUrls: ['./state-box.component.scss'],
})
export class StateBoxComponent {

    @HostBinding('class')
    @Input({ required: true }) state!: StateBoxState;

    constructor() {
    }
}
