import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { environment } from "@environments/environment";
import { MatDialog, MatDialogRef, MatDialogState } from "@angular/material/dialog";
import { ReloadAppDialogComponent } from "@app/core/components/reload-app-dialog/reload-app-dialog.component";

export const APP_TIMESTAMP_HEADER = 'x-release-timestamp';

function parseTimestamp(value: string): number|null {
    const parsedValue = parseInt(value, 10);
    const isInteger = String(parsedValue) === value;

    return isInteger ? parsedValue : null;
}

@Injectable({
    providedIn: 'root',
})
export class RequestAppVersionChecker {

    private modalRef?: MatDialogRef<unknown>;

    constructor(
        private dialog: MatDialog,
    ) {
    }

    checkIfAppIsUpToDate<T>(response: HttpResponse<T>|HttpErrorResponse): void {
        if (!this.isAppUpToDate(response)) {
            this.showReloadModal();
        }
    }

    showReloadModal(): void {
        if (undefined !== this.modalRef && MatDialogState.OPEN === this.modalRef.getState()) {
            // Ensure we do not open a modal more than once per session. Once the user
            // reloads the page, this code is reloaded fresh as well so the modal can
            // be opened again.
            return;
        }

        this.modalRef = this.dialog.open(ReloadAppDialogComponent, { disableClose: true });
    }

    isAppUpToDate<T>(response: HttpResponse<T>|HttpErrorResponse): boolean {
        const rawApiReleaseTimestamp = response.headers.get(APP_TIMESTAMP_HEADER);

        if (null === rawApiReleaseTimestamp) {
            return true;
        }

        const apiReleaseTimestamp = parseTimestamp(rawApiReleaseTimestamp);

        return null !== apiReleaseTimestamp && apiReleaseTimestamp <= environment.releaseTimestamp;
    }
}
