import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent implements OnChanges {

    @HostBinding('class.is-open')
    isOpen: boolean = false;

    @Input() expanded: boolean = false;

    @Input() togglePosition: 'before' | 'after' = 'after';

    @Input() interactive: boolean = true;

    @Input() summary: string = '';

    constructor(
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('expanded' in changes) {
            this.isOpen = this.expanded;
        }

        if ('interactive' in changes && !this.interactive) {
            this.isOpen = true;
        }
    }

    toggleContent(): void {
        if (!this.interactive) {
            return;
        }

        this.isOpen = !this.isOpen;
    }
}
