<nav>
    <ul class="toolbar-link-container">
        <li *ngFor="let toolbarNavItem of toolbarNavItems">
            <ng-container *ngIf="toolbarNavItem.external; then external; else internal"></ng-container>
            <ng-template #external>
                <a class="toolbar-link"
                   href="{{toolbarNavItem.url}}"
                   target="_blank"
                   rel="noopener">
                    {{toolbarNavItem.label}}
                </a>
            </ng-template>
            <ng-template #internal>
                <a class="toolbar-link"
                   routerLink="{{toolbarNavItem.url}}"
                   routerLinkActive="active-link">
                    {{toolbarNavItem.label}}
                </a>
            </ng-template>
        </li>
    </ul>
</nav>
