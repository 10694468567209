import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ImpersonatedUserTokenStorage } from '@app/core/authentication/services/impersonated-user-token-storage.service';
import { JWT_OPTIONS, JwtConfig } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ImpersonateUserInterceptor implements HttpInterceptor {

    private readonly headerName: string;

    constructor(
        private readonly impersonatedUserTokenStorage: ImpersonatedUserTokenStorage,
        @Inject(JWT_OPTIONS) readonly config: JwtConfig,
    ) {
        // The default value is coming from
        // https://github.com/auth0/angular2-jwt/blob/main/projects/angular-jwt/src/lib/jwt.interceptor.ts#L34
        this.headerName = config.headerName || 'Authorization';
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // We add the impersonate header if and only if we are doing an
        // authenticated request.
        // Instead of relying on the whole internal logic of JwtInterceptor, we
        // just check if the authorization header is present.
        // This requires however that this interceptor is registered _after_
        // the JWT one.
        const userId = this.impersonatedUserTokenStorage.getUserId();

        if (request.headers.has(this.headerName) && null !== userId) {
            request = request.clone({
                setParams: {
                    _switch_user: userId,
                },
            });
        }

        return next.handle(request);
    }
}
