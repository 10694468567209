<app-form-field>
    <app-label>Anrede</app-label>
    <mat-select [formControl]="control"
                placeholder="Anrede wählen..."
                i18n-placeholder="@@formField.genderFormField.placeholder"
                appInput
                required>
        <mat-option *ngFor="let genderOption of genderOptions" value="{{genderOption.value}}">
            {{genderOption.name}}
        </mat-option>
    </mat-select>

    <app-form-field-error i18n="@@formField.genderFormField.error">
        Bitte wählen Sie eine Anrede aus.
    </app-form-field-error>
</app-form-field>
