<ng-content select="[slot=before]"></ng-content>

<label for="file-upload" #label>
    <input id="file-upload" type="file">
    <button color="green" (click)="label.click()">
        <ng-content></ng-content>
    </button>

    <span *ngIf="fileName">{{fileName}}</span>
</label>
