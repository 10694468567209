import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StandardFont } from '@app/shared/fonts';
import { ChartType, Color, DefaultDataPoint, Scriptable, ScriptableContext } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts/lib/base-chart.directive';
import { NgChartsModule } from "ng2-charts";

type ArrayElement<ArrType> = ArrType extends readonly (infer ElementType)[]
    ? ElementType
    : never;

const SPIDER_CHART_TYPE: ChartType = 'radar';
type SpiderChartType = typeof SPIDER_CHART_TYPE;
type SpiderChartConfig = BaseChartDirective<SpiderChartType, DefaultDataPoint<SpiderChartType>, string>;
type SpiderChartLabels =  SpiderChartConfig['labels'];
type SpiderChartDatasets =  SpiderChartConfig['datasets'];
type SpiderChartPlugins =  SpiderChartConfig['plugins'];
type SpiderChartDataset =  ArrayElement<SpiderChartDatasets>;
type SpiderChartOptions =  SpiderChartConfig['options'];

enum RgbColor {
    GREEN = '46, 179, 58',
    YELLOW = '253, 195, 52',
    RED = '229, 81, 57',
}

const getPointColor: Scriptable<Color, ScriptableContext<SpiderChartType>> = (context) => {
    const index = context.dataIndex;
    const data = context.dataset?.data || [];

    if (undefined === index) {
        throw new Error('Point has an undefined value');
    }

    const value = data[index];

    if (undefined === value || null === value) {
        throw new Error('Point has an undefined value');
    }

    if (value >= 2) {
        return createRgba(RgbColor.GREEN);
    }

    if (value >= 1) {
        return createRgba(RgbColor.YELLOW);
    }

    return createRgba(RgbColor.RED);
};

function createRgba(rgb: RgbColor, opacity = 1): string {
    return `rgba(${rgb}, ${opacity})`;
}

const DEFAULT_POINT_RADIUS = 10;

function createRadialChartOptions(hideLabels: boolean): Partial<SpiderChartOptions> {
    return {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
            datalabels: {
                display: false,
            },
        },
        scales: {
            r: {
                angleLines: {
                    display: true,
                    color: '#eee',
                    lineWidth: 2,
                },
                beginAtZero: true,
                ticks: {
                    maxTicksLimit: 4,
                    stepSize: 1,
                    display: false,
                },
                grid: {
                    display: true,
                    color: '#eee',
                    lineWidth: 0,
                    z: 1,
                },
                pointLabels:{
                    font: {
                        size: StandardFont.size,
                        family: StandardFont.family,
                        weight: String(StandardFont.weight),
                    },
                    color: StandardFont.color,
                    callback: (label: string) => hideLabels ? '' : label,
                } ,
            },
        },
    };
}

const ACTUAL_DATASET_CONFIG: Partial<SpiderChartDataset> = {
    pointRadius: DEFAULT_POINT_RADIUS,
    pointHoverRadius: DEFAULT_POINT_RADIUS,
    pointBorderWidth: 2,
    pointHoverBorderWidth: 2,
    pointBorderColor: 'white',
    pointHoverBorderColor: 'white',
    pointBackgroundColor: getPointColor,
    pointHoverBackgroundColor: 'white',
    fill: 'end',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderColor: 'transparent',
};

function getPlaceholderDataset(): SpiderChartDataset {
    const config = {
        ...ACTUAL_DATASET_CONFIG,
        pointRadius: 0,
        pointHoverRadius: 0,
    };

    return {
        ...config,
        data: PLACEHOLDER_DATA,
    };
}

const BACKGROUND_DATASET_CONFIG: Partial<SpiderChartDataset> = {
    borderColor: 'transparent',
    borderWidth: 0,
    pointRadius: 0,
    fill: 'origin',
};

const PLACEHOLDER_CATEGORY_COUNT = 7;

const PLACEHOLDER_DATA = Array(PLACEHOLDER_CATEGORY_COUNT).fill(0);
const PLACEHOLDER_LABELS = Array(PLACEHOLDER_CATEGORY_COUNT).fill('');

@Component({
    selector: 'app-spider-chart',
    templateUrl: './spider-chart.component.html',
    styleUrls: ['./spider-chart.component.scss'],
    standalone: true,
    imports: [
        NgChartsModule,
    ],
})
export class SpiderChartComponent implements OnChanges {

    @Input() data: number[] = [];

    @Input() labels: string[] = [];

    @Input() hideLabels = false;

    @Input('placeholderChart') isPlaceholderChart = false;

    public datasets: SpiderChartDatasets = [];

    public plugins: SpiderChartPlugins = [ChartDataLabels];

    public radialChartOptions: SpiderChartOptions = createRadialChartOptions(this.hideLabels);

    get chartLabels(): SpiderChartLabels {
        return this.isPlaceholderChart ? PLACEHOLDER_LABELS : this.labels;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const hideLabelsChanged = 'hideLabels' in changes;

        this.datasets = this.createDatasets();

        if (hideLabelsChanged) {
            this.setChartOptions(this.hideLabels);
        }
    }

    private setChartOptions(hideLabels: boolean) {
        this.radialChartOptions = createRadialChartOptions(hideLabels);
    }

    private createDatasets(): SpiderChartDatasets {
        return [
            this.createActualDataset(),
            ...this.createBackgroundDatasets(),
        ];
    }

    private createActualDataset(): SpiderChartDataset {
        if (this.isPlaceholderChart) {
            return getPlaceholderDataset();
        }

        return {
            ...ACTUAL_DATASET_CONFIG,
            data: this.data,
        };
    }

    private createBackgroundDatasets(): SpiderChartDataset[] {
        return [
            this.createBackgroundDataset(RgbColor.RED, 1),
            this.createBackgroundDataset(RgbColor.YELLOW, 2),
            this.createBackgroundDataset(RgbColor.GREEN, 3),
        ];
    }

    private createBackgroundDataset(rgb: RgbColor, threshold: number): SpiderChartDataset {
        let data = this.data;
        if (this.isPlaceholderChart) {
            data = PLACEHOLDER_DATA;
        }

        return {
            ...BACKGROUND_DATASET_CONFIG,
            data: data.map(() => threshold),
            backgroundColor: createRgba(rgb),
        };
    }
}
