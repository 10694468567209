import { Component } from '@angular/core';

@Component({
    selector: 'app-state-box-headline',
    templateUrl: './state-box-headline.component.html',
    styleUrls: ['./state-box-headline.component.scss'],
})
export class StateBoxHeadlineComponent {

    constructor() {
    }

}
