import { Component, Input } from '@angular/core';
import { Observable, OperatorFunction, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataVisualization } from "@app/shared/models/assessment/data-visualization.model";
import { SpiderChartComponent } from "@app/shared/components/spider-chart/spider-chart.component";
import { AsyncPipe, NgIf } from "@angular/common";

type ChartInput = {
    data: number[],
    labels: string[],
};

function mapToChartInput(): OperatorFunction<DataVisualization, ChartInput> {
    return map((dataVisualization) => {
        return dataVisualization.expressionValues.reduce<ChartInput>(
            (result, expressionValue) => {
                const label = expressionValue.expression.label;
                const value = expressionValue.value;

                if (typeof value !== 'number') {
                    return result;
                }

                return {
                    labels: result.labels.concat(label),
                    data: result.data.concat(value),
                };
            },
            { labels: [], data: [] },
        );
    });
}

@Component({
    selector: 'app-spider-chart-data-visualization',
    templateUrl: './spider-chart-data-visualization.component.html',
    styleUrls: ['./spider-chart-data-visualization.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        SpiderChartComponent,
    ],
})
export class SpiderChartDataVisualizationComponent {

    public chartInput$?: Observable<ChartInput>;

    private readonly dataVisualizationSource = new ReplaySubject<DataVisualization>();

    @Input() set dataVisualization(dataVisualization: DataVisualization) {
        this.dataVisualizationSource.next(dataVisualization);
    }

    @Input() hideLabels = false;

    constructor() {
        this.chartInput$ = this.dataVisualizationSource
            .pipe(mapToChartInput());
    }
}
