import { Directive } from '@angular/core';

@Directive({
    standalone: true,
    selector: 'button[primary]',
    host: {
        class: 'button-primary',
    },
})
export class PrimaryDirective {
}
