import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { Authenticator } from '@app/core/authentication/services/authenticator.service';
import { RedirectUrlFactory } from '@app/core/authentication/services/redirect-url-factory.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IsEmailConfirmedGuard implements CanActivate {

    constructor(
        private readonly authenticator: Authenticator,
        private readonly redirectUrlFactory: RedirectUrlFactory,
    ) {
    }

    canActivate(
        _route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return this.isAllowed(state.url);
    }

    private isAllowed(url: string): Observable<boolean | UrlTree> {
        return this.authenticator
            .getViewer()
            .pipe(
                map((viewer) => true === viewer?.emailConfirmed || this.getEmailConfirmUrl(url)),
            );
    }

    private getEmailConfirmUrl(url: string): UrlTree {
        return this.redirectUrlFactory.createRedirectUrl(
            url,
            '/authentication/confirm-email',
            [
                '/',
            ],
        );
    }
}
