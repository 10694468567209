import { Component } from '@angular/core';

@Component({
    templateUrl: './page-not-found.page.html',
    styleUrls: ['./page-not-found.page.scss'],
})
export class PageNotFoundPage {

    constructor() {
    }

}
