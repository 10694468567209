import { Directive, Input } from '@angular/core';

type ButtonColor = 'green' | 'red' | 'dark-grey';

@Directive({
    standalone: true,
    selector: 'button[color]',
    host: {
        '[class]': 'hostClass',
    },
})
export class ButtonColorDirective {

    @Input() color: ButtonColor | '' = '';

    constructor() {
    }

    get hostClass() {
        return '' !== this.color
            ? `button-${this.color}`
            : null;
    }
}
