import { Injectable } from '@angular/core';
import { AccessToken } from '@app/core/authentication/models/access-token.model';

const LOCAL_STORAGE_KEY = 'accessToken';

@Injectable({
    providedIn: 'root',
})
export class AccessTokenStorage {
    getToken(): AccessToken | null {
        const item = localStorage.getItem(LOCAL_STORAGE_KEY);

        return null !== item ? AccessToken.forValue(item) : null;
    }

    setToken(token: AccessToken): void {
        localStorage.setItem(LOCAL_STORAGE_KEY, token.getValue());
    }

    removeToken(): void {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
}
