import { Measure } from '@app/shared/models/assessment/measure.model';
import { IsNullable } from '@webmozarts/http-client';
import { Type } from 'class-transformer';
import { IsArray, IsDefined, IsString, ValidateNested } from 'class-validator';

export class MeasureList {
    @IsDefined()
    id!: string;

    @IsString()
    @IsNullable()
    label!: string | null;

    @IsDefined({ each: true })
    @IsArray()
    @ValidateNested()
    @Type(() => Measure)
    measures!: Measure[];
}
