import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const LOCAL_STORAGE_KEY = 'xdebug';
const XDEBUG_QUERY_PARAMETER = 'XDEBUG_SESSION';

@Injectable({
    providedIn: 'root',
})
export class EnableXdebugInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const enableXdebug = !!localStorage.getItem(LOCAL_STORAGE_KEY);

        if (enableXdebug) {
            request = request.clone({
                setParams: {
                    [XDEBUG_QUERY_PARAMETER]: '1',
                },
            });
        }

        return next.handle(request);
    }
}
