import { Pipe, PipeTransform } from '@angular/core';

function isNullable(value: unknown): value is null | undefined {
    return null == value;
}

function isEmpty(value: unknown): value is null | undefined | '' {
    return isNullable(value)
        || '' === value;
}

@Pipe({
    name: 'default',
})
export class DefaultPipe implements PipeTransform {

    transform<T, S>(value: T, defaultValue: NonNullable<S>): NonNullable<T|S> {
        return isEmpty(value)
            ? defaultValue
            : value as NonNullable<T>;
    }

}
