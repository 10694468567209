import { FormControlValueType } from '@app/assessment/services/quality-goal-form.service';
import { roundToPrecision } from '@app/shared/math.utils';
import { mapUnitToPrecision, Unit } from '@app/shared/models/unit';

export function parseControlValue(
    value: FormControlValueType,
    unit: Unit,
): number | null {
    if (null === value) {
        return null;
    }

    if (unit === Unit.NOTE) {
        return null;
    }

    if ('boolean' === typeof value) {
        return value ? 1 : 0;
    }

    const numericValue = 'number' === typeof value
        ? value
        : parseFloat(value);

    if (isNaN(numericValue)) {
        return null;
    }

    if (unit === Unit.PERCENT) {
        return numericValue / 100;
    }

    return numericValue;
}

export function formatSubmittedValue(
    submittedValue: string | number | null,
    unit: Unit | null,
    customPrecision?: number,
): FormControlValueType {
    if (null === submittedValue) {
        return null;
    }

    if (unit === Unit.NOTE){
        return null;
    }

    if ('string' === typeof submittedValue) {
        return submittedValue;
    }

    if (unit === Unit.BOOLEAN) {
        return !!submittedValue;
    }

    if (unit === Unit.PERCENT) {
        submittedValue = submittedValue * 100;
    }

    if (0 === submittedValue) {
        // Empirical research showed that setting the input value to (string) "0" improves the chances that the input value is actually selected on select()
        return '0';
    }

    if (null === unit && customPrecision === undefined) {
        return submittedValue;
    }

    return roundNumericValue(submittedValue, unit, customPrecision ?? null);
}

function roundNumericValue(value: number, unit: Unit | null, customPrecision: number | null): number {
    if (null === unit && null === customPrecision) {
        return value;
    }

    const precision = customPrecision ?? mapUnitToPrecision(unit!);

    return roundToPrecision(value, precision);
}
