import { CategoryRating } from '@app/shared/models/assessment/category-rating.model';
import { MeasureConstraint } from '@app/shared/models/assessment/measure-constraint';
import { Unit } from '@app/shared/models/unit';
import { IsNullable } from '@webmozarts/http-client';
import { Type } from 'class-transformer';
import { IsArray, IsDefined, IsEnum, IsString, ValidateNested } from 'class-validator';

export class Measure {
    @IsDefined()
    id!: string;

    @IsDefined()
    label!: string;

    @IsDefined({ each: true })
    @IsArray()
    @IsEnum(MeasureConstraint, { each: true })
    constraints!: MeasureConstraint[];

    @IsDefined({ each: true })
    @IsArray()
    @ValidateNested()
    @Type(() => CategoryRating)
    categoryRatings!: CategoryRating[];

    @IsDefined()
    @IsNullable()
    description!: string | null;

    @IsDefined()
    @IsEnum(Unit)
    @Type()
    unit!: Unit;

    isNote(): boolean {
        return this.unit === Unit.NOTE;
    }

    @IsDefined()
    @IsString()
    inspectionInfo: string = '';
}
