<label *ngFor="let option of primaryOptions"
       [class.selected]="option.value === control.value">
    {{ option.label }}
    <input type="radio"
           [formControl]="control"
           [value]="option.value"></label>
<ng-container *ngIf="additionalOptions.length > 0">
    <label i18n="@@component.tab-bar-content.more"
           class="menu-trigger"
           [class.selected]="additionalOptionSelected"
           [matMenuTriggerFor]="dropdownButton">
        Weitere
        <app-icon name="chevron_down"></app-icon>
    </label>
    <mat-menu #dropdownButton="matMenu" xPosition="after" yPosition="below">
        <label class="menu-item"
               *ngFor="let option of additionalOptions"
               [class.selected]="option.value === control.value">
            {{ option.label }}
            <input type="radio" [formControl]="control" [value]="option.value">
        </label>
    </mat-menu>
</ng-container>
