import { Component } from '@angular/core';

@Component({
    selector: 'app-main-company-badge',
    templateUrl: './main-company-badge.component.html',
    styleUrls: [],
})
export class MainCompanyBadgeComponent {

    constructor() {
    }

}
