<ng-container *ngIf="dataVisualization && dataVisualization.expressionValues.length > 1">
    <ng-container [ngSwitch]="dataVisualization.definition.representation">
        <app-spider-chart-data-visualization *ngSwitchCase="'spiderChart'"
                                             [dataVisualization]="dataVisualization"
                                             [hideLabels]="hideLabels">
        </app-spider-chart-data-visualization>

        <app-doughnut-chart-data-visualization *ngSwitchCase="'shareDonutChart'"
                                               [dataVisualization]="dataVisualization">
        </app-doughnut-chart-data-visualization>
    </ng-container>
</ng-container>
