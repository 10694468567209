import { Workflow } from '@app/shared/models/workflow.model';
import { localDateToString } from '@app/shared/transformers/local-date-to-string.transformer';
import { IsNullable } from '@webmozarts/http-client';
import { Transform, Type } from 'class-transformer';
import { IsBoolean, IsDefined, IsEnum } from 'class-validator';

function hasDatePassed(date: Date | null): boolean {
    const now = new Date();

    return null === date || date < now;
}

export class CertificationPreview {
    @IsDefined()
    id!: string;

    @IsDefined()
    globalId!: string;

    @IsDefined()
    summary!: string;
}

export class Certification extends CertificationPreview {

    @IsDefined()
    growingSeasonId!: string;

    @IsDefined()
    @IsEnum(Workflow)
    @Type()
    workflow!: Workflow;

    @IsDefined()
    @IsBoolean()
    skipAdvanceNotification!: boolean;

    @IsDefined()
    @Transform(localDateToString)
    @IsNullable()
    availableFrom!: Date | null;

    @IsDefined()
    @Transform(localDateToString)
    @IsNullable()
    officialAdvanceNotificationDeadline!: Date | null;

    @IsDefined()
    @Transform(localDateToString)
    @IsNullable()
    officialCertificationRequestDeadline!: Date | null;

    @IsDefined()
    @Transform(localDateToString)
    @IsNullable()
    actualAdvanceNotificationDeadline!: Date | null;

    @IsDefined()
    @Transform(localDateToString)
    @IsNullable()
    actualCertificationRequestDeadline!: Date | null;

    @IsDefined()
    @Transform(localDateToString)
    @IsNullable()
    certificationRequestAvailableFrom!: Date | null;

    @IsDefined()
    hasGoal!: boolean;

    @IsDefined()
    title!: string;

    get isAvailableNow(): boolean {
        return hasDatePassed(this.availableFrom);
    }

    get isCertificationRequestAvailableNow(): boolean {
        return hasDatePassed(this.certificationRequestAvailableFrom);
    }
}
