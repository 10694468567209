import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Authenticator } from '@app/core/authentication/services/authenticator.service';
import { UserRole } from '@app/shared/models/user-role.model';
import { User } from '@app/shared/models/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const HOMEPAGE_BY_ROLE: Record<UserRole, string> = {
    [UserRole.ADMIN]: '/users',
    [UserRole.AUDIT_COMPANY_ADMIN]: '/certification/requests',
    [UserRole.AUDITOR]: '/certification/requests',
    [UserRole.WINERY_USER]: '/certification/goals',
};

function getViewerUrlTree(viewer: User | null, router: Router): UrlTree {
    const path = getViewerHomepage(viewer);

    return router.createUrlTree([path]);
}

function getViewerHomepage(viewer: User | null): string {
    const viewerRole = viewer?.role;

    if (!viewerRole) {
        return '/authentication/welcome';
    }

    return HOMEPAGE_BY_ROLE[viewerRole];
}

@Injectable({
    providedIn: 'root',
})
export class RedirectToViewerHomepageGuard implements CanActivate {

    constructor(
        private readonly authenticator: Authenticator,
        private readonly router: Router,
    ) {
    }

    canActivate(): Observable<UrlTree> {
        return this.authenticator.getViewer()
            .pipe(
                map((viewer) => getViewerUrlTree(viewer, this.router)),
            );
    }
}
