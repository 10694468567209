import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Assessment } from "@app/shared/models/assessment/assessment.model";
import { DataVisualization } from "@app/shared/models/assessment/data-visualization.model";
import { NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import {
    SpiderChartDataVisualizationComponent,
} from "@app/shared/components/assessment/spider-chart-data-visualization/spider-chart-data-visualization.component";
import {
    DoughnutChartDataVisualizationComponent,
} from "@app/shared/components/assessment/doughnut-chart-data-visualization/doughnut-chart-data-visualization.component";

@Component({
    selector: 'app-data-visualization',
    templateUrl: './data-visualization.component.html',
    styleUrls: ['./data-visualization.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgSwitch,
        NgSwitchCase,
        DoughnutChartDataVisualizationComponent,
        SpiderChartDataVisualizationComponent,
    ],
})
export class DataVisualizationComponent implements OnChanges {

    @Input({ required: true }) assessment!: Assessment;

    @Input({ required: true }) globalId!: string;

    @Input() hideLabels = false;

    dataVisualization?: DataVisualization;

    ngOnChanges(changes: SimpleChanges): void {
        const assessmentChanged = 'assessment' in changes;
        const globalId = 'globalId' in changes;

        if (assessmentChanged || globalId) {
            this.dataVisualization = this.assessment.findDataVisualizationByGlobalId(this.globalId);
        }
    }
}
