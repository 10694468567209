import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-not-qualified-box',
    templateUrl: './not-qualified-box.component.html',
    styleUrls: ['./not-qualified-box.component.scss'],
})
export class NotQualifiedBoxComponent {

    @Input({ required: true }) submitText!: string;

    @Input({ required: true }) submitDisabled!: boolean;

    @Input() officialDeadline: Date | null | undefined;

    @Input() isPreliminaryAssessment = false;

    @Input({ required: true }) submitLaterInfo!: boolean;

    @Output() onSubmit = new EventEmitter<void>();

    submit(): void {
        this.onSubmit.emit();
    }
}
