import { Component, Input } from '@angular/core';
import { Assessment } from '@app/shared/models/assessment/assessment.model';

const OVERALL_SPIDER_DIAGRAM_ID = 'e5a73dab-4b53-4783-9791-7ca9fe4e53ab';

@Component({
    selector: 'app-overall-spider-diagram',
    templateUrl: './overall-spider-diagram.component.html',
    styleUrls: ['./overall-spider-diagram.component.scss'],
})
export class OverallSpiderDiagramComponent {

    @Input({ required: true }) assessment!: Assessment;

    @Input() hideLabels = false;

    readonly globalId = OVERALL_SPIDER_DIAGRAM_ID;
}
