import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_TITLE } from '@app/app/services/app-title.service';
import { IsAuthenticatedGuard } from '@app/core/authentication/guards/is-authenticated.guard';
import { IsEmailConfirmedGuard } from '@app/core/authentication/guards/is-email-confirmed.guard';
import {
    IS_ROLE_GRANTED, IsRoleGrantedGuard,
} from '@app/core/authentication/guards/is-role-granted.guard';
import { IS_ROLE, IsRoleGuard } from '@app/core/authentication/guards/is-role.guard';
import { RedirectToViewerHomepageGuard } from '@app/core/authentication/guards/redirect-to-viewer-homepage.guard';
import { PageNotFoundPage } from '@app/error-pages/components/page-not-found/page-not-found.page';
import { BasePageComponent } from '@app/shared/components/base-page/base-page.component';
import { LayoutComponent } from '@app/shared/components/layout/layout.component';
import { PAGE_WIDTH, PageWidth } from '@app/shared/components/layout/page-width';
import { UserRole } from '@app/shared/models/user-role.model';
import ClientSpecificRoutes  from '@app/client-specific-routes';

const routes: Routes = [
    {
        path: 'certificate',
        loadChildren: () => import('./certificate/certificate.module').then((m) => m.CertificateModule),
    },
    {
        path: '',
        component: BasePageComponent,
        children: [
            {
                path: 'users',
                loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
                canActivate: [IsAuthenticatedGuard, IsEmailConfirmedGuard, IsRoleGrantedGuard],
                data: {
                    [IS_ROLE_GRANTED]: UserRole.AUDIT_COMPANY_ADMIN,
                },
            },
            {
                path: '',
                component: LayoutComponent,
                data: {
                    [PAGE_WIDTH]: PageWidth.EXTRA_SMALL,
                },
                children: [
                    ...ClientSpecificRoutes,
                    {
                        path: 'authentication',
                        loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
                    },
                    {
                        path: 'certification',
                        loadChildren: () => import('./certification/certification.module').then((m) => m.CertificationModule),
                        canActivate: [IsAuthenticatedGuard],
                    },
                    {
                        path: 'audit-company-profile',
                        loadChildren: () => import('./audit-company-profile/audit-company-profile.module').then((m) => m.AuditCompanyProfileModule),
                        canActivate: [IsAuthenticatedGuard, IsRoleGuard],
                        data: {
                            [IS_ROLE]: UserRole.AUDIT_COMPANY_ADMIN,
                        },
                    },
                    {
                        path: 'export',
                        loadChildren: () => import('./export/export.module').then((m) => m.ExportModule),
                        canActivate: [IsAuthenticatedGuard, IsRoleGrantedGuard],
                        data: {
                            [IS_ROLE_GRANTED]: UserRole.ADMIN,
                        },
                    },
                    {
                        path: 'error',
                        loadChildren: () => import('./error-pages/error-pages.module').then((m) => m.ErrorPagesModule),
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        // Placeholder component. Won't be rendered because RedirectToViewerHomepageGuard navigates away earlier already
                        component: PageNotFoundPage,
                        canActivate: [IsAuthenticatedGuard, IsEmailConfirmedGuard, RedirectToViewerHomepageGuard],
                    },
                    {
                        path: '404',
                        component: PageNotFoundPage,
                        pathMatch: 'full',
                        data: { [APP_TITLE]: 'Seite nicht gefunden' },
                    },
                    {
                        path: '**',
                        redirectTo: '/404',
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        enableTracing: false,
    })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
