import { Gender } from '@app/shared/models/gender.model';
import { AuditCompany } from '@app/shared/models/audit-company.model';
import { UserRole } from '@app/shared/models/user-role.model';
import { dateToString, IsNullable } from '@webmozarts/http-client';
import { Transform, Type } from 'class-transformer';
import { IsDefined, IsEnum, ValidateNested } from 'class-validator';

export class User {
    @IsDefined()
    id!: string;

    @IsDefined()
    email!: string;

    @IsDefined()
    @IsNullable()
    firstName!: string | null;

    @IsDefined()
    @IsNullable()
    lastName!: string | null;

    @IsDefined()
    @IsNullable()
    fullName!: string | null;

    @IsDefined()
    emailConfirmed!: boolean;

    @IsDefined()
    @IsNullable()
    phoneNumber!: string | null;

    @IsDefined()
    anonymous!: boolean;

    @IsDefined()
    enabled!: boolean;

    @IsDefined()
    @IsEnum(UserRole)
    @Type()
    role!: UserRole;

    @IsDefined()
    @IsNullable()
    @IsEnum(Gender)
    @Type()
    gender!: Gender | null;

    @IsDefined()
    @Transform(dateToString)
    @IsNullable()
    lastLoggedInAt!: Date | null;

    @IsDefined()
    @IsNullable()
    @ValidateNested()
    @Type(() => AuditCompany)
    auditCompany!: AuditCompany | null;
}
