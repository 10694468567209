export enum PageWidth {
    EXTRA_EXTRA_SMALL = 'xxs',
    EXTRA_SMALL = 'xs',
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
}

export function isPageWidth(pageWidth: any): pageWidth is PageWidth {
    return Object.values(PageWidth).includes(pageWidth);
}

export const PAGE_WIDTH = 'PAGE_WIDTH';
