import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { formatSubmittedValue } from '@app/assessment/services/measure-control-value-converter';
import { FormControlValueType } from '@app/assessment/services/quality-goal-form.service';
import { maskConfig } from '@app/core/core.module';
import {
    getMaskByUnit, getSuffixByUnit, mapPrecisionToMask, Unit,
} from '@app/shared/models/unit';
import { NgxMaskPipe } from 'ngx-mask';

function formatValue(value: string | number | null, unit: Unit | null, customPrecision?: number): FormControlValueType {
    const formattedValue = formatSubmittedValue(value, unit, customPrecision);

    if (unit === Unit.BOOLEAN) {
        return formattedValue ? 'Ja' : 'Nein';
    }

    return formattedValue;
}

@Component({
    selector: 'app-rendered-value',
    templateUrl: './rendered-value.component.html',
    styleUrls: ['./rendered-value.component.scss'],
    standalone: true,
    imports: [
        NgxMaskPipe,
        NgIf,
    ],
})
export class RenderedValueComponent implements OnInit, OnChanges {

    readonly Unit = Unit;

    @Input({ required: true }) value!: string | number | null;

    @Input({ required: true }) unit!: Unit | null;

    @Input() customPrecision?: number;

    @Input() evaluated: boolean | null = true;

    suffix?: string;

    mask?: string | null;

    formattedStringValue?: string;

    thousandSeparator = maskConfig.thousandSeparator;

    constructor() {
    }

    ngOnInit(): void {
        this.initializeValues();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const valueChanged = 'value' in changes;
        const unitChanged = 'unit' in changes;

        if (valueChanged || unitChanged) {
            this.initializeValues();
        }
    }

    private initializeValues(): void {
        this.suffix = getSuffixByUnit(this.unit);
        const formattedValue = formatValue(this.value, this.unit, this.customPrecision);

        this.mask = this.createMask(formattedValue);

        this.formattedStringValue = formattedValue?.toString();
    }

    private createMask(formattedValue: FormControlValueType): string | null {
        if ('number' === typeof this.customPrecision) {
            return mapPrecisionToMask(this.customPrecision);
        }

        return this.unit && 'string' !== typeof formattedValue
            ? getMaskByUnit(this.unit)
            : null;
    }
}
