import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Authenticator } from '@app/core/authentication/services/authenticator.service';
import { User } from '@app/shared/models/user.model';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
    readonly isImpersonator: Observable<boolean>;

    readonly accountName = this.authenticator.getViewer()
        .pipe(map(this.mapToAccountName()));

    constructor(
        private readonly authenticator: Authenticator,
        private readonly router: Router,
    ) {
        this.isImpersonator = authenticator.isImpersonator;
    }

    onLogout(): void {
        this.authenticator.logout()
            .pipe(
                // We soft-reload the page to trigger the guards again which
                // will redirect the user if the permissions are not met
                // to access to this page
                switchMap(() => this.router.navigateByUrl('/authentication/welcome')),
                take(1),
            )
            .subscribe();
    }

    onRestoreOriginalUser(): void {
        this.authenticator.restoreOriginalUser()
            .pipe(
                // We soft-reload the page to trigger the guards again which
                // will redirect the user if the permissions are not met
                // to access to this page
                switchMap(() => this.router.navigateByUrl('')),
                take(1),
            )
            .subscribe();
    }

    private mapToAccountName(): (viewer: User | null) => string|undefined {
        return (viewer: User | null) => {
            if (null === viewer) {
                return undefined;
            }

            if (viewer.anonymous) {
                return 'Anonym';
            }

            const accountName = [viewer.firstName, viewer.lastName].filter((n) => n).join(' ');

            if (accountName) {
                return accountName;
            }

            return viewer.email;
        };
    }
}
