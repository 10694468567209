import { Component, ElementRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileUploadComponent,
            multi: true,
        },
    ],
})
export class FileUploadComponent implements ControlValueAccessor {
    private propagateChange: Function = () => {};

    fileName: string | undefined;

    @HostListener('change', ['$event.target.files'])
    emitFiles(event: FileList) {
        const newFile = event && event.item(0);

        if (newFile) {
            this.fileName = newFile.name;
        }

        this.propagateChange(newFile);
    }

    constructor(private host: ElementRef<HTMLInputElement>) {
    }

    writeValue() {
        // Clear file input
        this.host.nativeElement.value = '';
    }

    registerOnChange(fn: Function) {
        this.propagateChange = fn;
    }

    registerOnTouched(_fn: Function) {
        // Do nothing
    }
}
