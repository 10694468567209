import { Component, Input } from '@angular/core';
import { BadgeColor } from '@app/shared/components/badge/badge.component';
import { REQUEST_TYPE_NAMES, RequestType } from '@app/shared/models/request/request-type.model';

const REQUEST_TYPE_BADGE_COLOR: Record<RequestType, BadgeColor> = {
    [RequestType.ADVANCE_NOTIFICATION]: 'blue',
    [RequestType.CERTIFICATION_REQUEST]: 'dark-green',
};

@Component({
    selector: 'app-request-type-badge',
    templateUrl: './request-type-badge.component.html',
    styleUrls: ['./request-type-badge.component.scss'],
})
export class RequestTypeBadgeComponent {

    @Input({ required: true }) requestType!: RequestType;

    get name(): string {
        return REQUEST_TYPE_NAMES[this.requestType];
    }

    get badgeColor(): BadgeColor {
        return REQUEST_TYPE_BADGE_COLOR[this.requestType];
    }

    constructor() {
    }
}
