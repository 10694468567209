<ng-container *ngIf="0 !== richBaseValues.length">
    <app-state-box state="warning">
        <app-state-box-headline>
            <app-icon class="headline-icon"
                      name="exclamation_circle"
                      centered>
            </app-icon>
            {{ boxHeadline }}
        </app-state-box-headline>

        <div *ngFor="let richBaseValue of richBaseValues">
            {{richBaseValue.label}}:
            <app-rendered-value [unit]="richBaseValue.unit"
                                [value]="richBaseValue.value"></app-rendered-value>
        </div>

        <p *ngIf="isWinery" i18n="@@futureBaseValuesSummary.wineryNote">
            Die Eingaben beziehen sich auf die Grunddaten des Vorjahres. Sollten
            Sie die Bewirtschaftungsfläche des Vorjahres um über 10% bzw. 3
            Hektar überschreiten, weisen wir darauf hin, dass eine
            Vorortkontrolle erforderlich ist.
        </p>
    </app-state-box>
</ng-container>
