<table mat-table [dataSource]="baseValueRows" *ngIf="baseValueRows.length > 0">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef i18n="@@model.baseValue.name">Bezeichnung</th>
        <td mat-cell *matCellDef="let baseValueRow">
            <span>{{baseValueRow.name}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef class="col-value" i18n="@@model.baseValue.value">Wert</th>
        <td mat-cell *matCellDef="let baseValueRow" class="col-value">
            <span>
                <app-rendered-value [value]="baseValueRow.value"
                                    [unit]="baseValueRow.unit">
                </app-rendered-value>
            </span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
