export type TokenOrNull = AccessToken | null;

export class AccessToken {
    private value?: string;

    static forValue(value: string): AccessToken {
        const token = new AccessToken();
        token.token = value;

        return token;
    }

    set token(value: string) {
        this.value = value;
    }

    getValue(): string {
        const value = this.value;

        if (undefined === value) {
            throw new Error('Did not expect to have undefined as an access token value');
        }

        return value;
    }
}
