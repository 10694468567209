import { Component, OnDestroy } from '@angular/core';
import { Authenticator } from '@app/core/authentication/services/authenticator.service';
import { createNavigationItems } from '@app/shared/components/header/navigation-items';
import { ToolbarNavItem } from '@app/shared/components/toolbar-nav/toolbar-nav-item';
import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
    navigationItems: ToolbarNavItem[] = [];

    readonly isAuthenticated: Observable<boolean>;

    private readonly ngUnsubscribe = new Subject<void>();

    readonly logoPath = `/assets/img/${environment.logo}`;

    constructor(
        private readonly authenticator: Authenticator,
    ) {
        this.isAuthenticated = this.authenticator.isAuthenticated()
            .pipe(takeUntil(this.ngUnsubscribe));

        this.authenticator.getViewer()
            .pipe(
                map((viewer) => createNavigationItems(viewer?.role)),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((navigationItems) => this.navigationItems = navigationItems);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
