<app-card>
    <app-card-item headline="Neue Version verfügbar"
                   i18n-headline="@@reloadAppDialog.headline">
        <p i18n="@@reloadAppDialog.description">Es wurde eine neue Version der Anwendung gefunden. Bitte laden Sie die Seite neu.</p>
    </app-card-item>

    <app-card-item>
        <app-toolbar>
            <ng-container slot="end">
                <button color="green"
                        primary
                        (click)="onReload()"
                        i18n="@@reloadAppDialog.reloadButton">
                    Neu laden
                </button>
            </ng-container>
        </app-toolbar>
    </app-card-item>
</app-card>
