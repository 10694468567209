<ng-container #viewContainer>
</ng-container>

<ng-template #notification>
    <div [ngClass]="{ container: true, error: error }" (click)="closeNotification()">
        <p class="message">
            <app-icon [name]="error ? 'exclamation_circle' : 'check_circle'"></app-icon>
            {{message}}
        </p>
    </div>
</ng-template>
