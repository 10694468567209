import { Component, Input } from '@angular/core';
import { AssetService } from '@app/core/services/asset.service';
import { assertAssessment, Assessment } from '@app/shared/models/assessment/assessment.model';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-evaluation-download-button',
    templateUrl: './evaluation-download-button.component.html',
    styleUrls: ['./evaluation-download-button.component.scss'],
})
export class EvaluationDownloadButtonComponent {

    @Input() assessment?: Assessment;

    downloadInProgress = false;

    constructor(
        private assetService: AssetService,
    ) {
    }

    onDownloadEvaluation(): void {
        const assessment = this.assessment;
        assertAssessment(assessment);

        this.downloadInProgress = true;

        this.assetService.downloadEvaluation(assessment)
            .pipe(finalize(() => this.downloadInProgress = false))
            .subscribe();
    }
}
