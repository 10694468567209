import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseValue } from '@app/shared/models/assessment/base-value.model';
import { BaseValueDefinition } from '@app/shared/models/process/base-value-definition.model';
import { Unit } from '@app/shared/models/unit';

interface BaseValueRowDefinition {
    name: string,

    value: number | string,

    unit: Unit | null,
}

function getBaseValueRowResults(
    baseValues?: BaseValue[],
    baseValueDefinitions?: BaseValueDefinition[],
): BaseValueRowDefinition[] {
    if (!baseValueDefinitions) {
        return [];
    }

    return baseValueDefinitions.reduce(
        (result: BaseValueRowDefinition[], baseValueDefinition) => {
            const baseValue = baseValues?.find((baseValue) => baseValueDefinition.id === baseValue.baseValueDefinitionId);

            if (!baseValue) {
                return result;
            }

            result.push({
                name: baseValueDefinition.label,
                value: baseValue.value,
                unit: baseValueDefinition.unit,
            });

            return result;
        },
        [],
    );
}

@Component({
    selector: 'app-base-value-table',
    templateUrl: './base-value-table.component.html',
    styleUrls: ['./base-value-table.component.scss'],
})
export class BaseValueTableComponent implements OnChanges {

    @Input() baseValues?: BaseValue[];

    @Input() baseValueDefinitions?: BaseValueDefinition[];

    baseValueRows: BaseValueRowDefinition[] = [];

    readonly columns = [
        'name',
        'value',
    ];

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const baseValueDefinitionsChanged = 'baseValueDefinitions' in changes;
        const baseValuesChanged = 'baseValues' in changes;

        if (baseValueDefinitionsChanged || baseValuesChanged) {
            this.baseValueRows = getBaseValueRowResults(
                this.baseValues,
                this.baseValueDefinitions,
            );
        }
    }
}
