import { Component, Input } from '@angular/core';
import { NgClass, NgIf } from "@angular/common";

@Component({
    standalone: true,
    selector: 'app-card-item',
    templateUrl: './card-item.component.html',
    styleUrls: ['./card-item.component.scss'],
    imports: [
        NgClass,
        NgIf,
    ],
})
export class CardItemComponent {

    @Input() headline = '';

    constructor() {
    }
}
