import { createUnexpectedValueTypeError } from '@app/shared/rxjs/createUnexpectedValueTypeError';

export function isNotNull<T>(input: T | null): input is T {
    return null !== input;
}

export function isNotUndefined<T>(input?: T): input is T {
    return undefined !== input;
}

export function isNotNullOrUndefined<T>(input: T): input is NonNullable<T> {
    return null !== input && undefined !== input;
}

export function isString(input: any): input is string {
    return 'string' === typeof input;
}

export function isNumber(input: any): input is number {
    return 'number' === typeof input;
}

export function assertIsString(input: any): asserts input is string {
    if (isString(input)) {
        return;
    }

    throw createUnexpectedValueTypeError(input, 'string');
}
