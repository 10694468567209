export enum Unit {
    AMOUNT = 'amount',
    AREA = 'area',
    BOOLEAN = 'boolean',
    ENERGY = 'energy',
    LITERS = 'liters',
    MILLIMETER = 'millimeter',
    PERCENT = 'percent',
    VOLUME = 'volume',
    WEIGHT = 'weight',
    NOTE = 'note',
}

const UNIT_SUFFIX: Record<Unit, string> = {
    [Unit.AMOUNT]: ' x',
    [Unit.AREA]: ' ha',
    [Unit.BOOLEAN]: '',
    [Unit.ENERGY]: ' kWh',
    [Unit.LITERS]: ' l',
    [Unit.MILLIMETER]: ' mm',
    [Unit.PERCENT]: '%',
    [Unit.VOLUME]: ' m³',
    [Unit.WEIGHT]: ' kg',
    [Unit.NOTE]: '',
};

const PLACEHOLDER_BY_UNIT: Record<Unit, string> = {
    [Unit.AMOUNT]: 'Anzahl',
    [Unit.AREA]: 'in Hektar (ha)',
    [Unit.BOOLEAN]: '',
    [Unit.ENERGY]: 'in Kilowattstunden (kWh)',
    [Unit.LITERS]: 'in Liter (l)',
    [Unit.MILLIMETER]: 'in Millimeter (mm)',
    [Unit.PERCENT]: 'in Prozent (%)',
    [Unit.VOLUME]: 'in Kubikmeter (m³)',
    [Unit.WEIGHT]: 'in Kilogramm (kg)',
    [Unit.NOTE]: '',
};

export function isUnit(unit: any): unit is Unit {
    return Object.values(Unit).includes(unit);
}

export function getMaskByUnit(unit: Unit | null): string {
    if (null === unit) {
        return 'A*';
    }

    return mapPrecisionToMask(mapUnitToPrecision(unit));
}

export function mapPrecisionToMask(precision: number): string {
    return `separator.${precision}`;
}

export function getSuffixByUnit(unit: Unit | null): string {
    if (null === unit) {
        return '';
    }

    return UNIT_SUFFIX[unit];
}

export function getPlaceholderByUnit(unit: Unit | null): string {
    if (null === unit) {
        return '';
    }

    return PLACEHOLDER_BY_UNIT[unit];
}

export function mapUnitToPrecision(unit: Unit): number {
    switch (unit) {
        case Unit.AREA:
        case Unit.LITERS:
            return 2;

        case Unit.AMOUNT:
            return 1;

        default:
            return 0;
    }
}
