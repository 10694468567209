<ng-container *ngIf="control">
    <div [ngClass]="{
            'label-input-container': true,
            'label-position-top': hasLabel && !inlineLabel,
            'label-position-right': hasLabel && inlineLabel }">
        <label class="form-field-label"
               [ngClass]="{'inlined': inlineLabel, 'clickable': hasClickableLabel}"
               [for]="control.id"
               *ngIf="hasLabel">
            <ng-content select="app-label"></ng-content>
            <app-required-marker *ngIf="control.required"></app-required-marker>
        </label>

        <div class="input-row">
            <div [ngClass]="{
            'input-wrapper': true,
            'styled': control.isStyled,
            'alert': control.errorState,
            'highlight': highlight,
            'focus': control.hasFocus }">
                <app-icon *ngIf="icon" [name]="icon" class="icon-start"></app-icon>
                <ng-content select="[appInput]"></ng-content>
                <app-icon *ngIf="isPassword"
                          class="icon-password"
                          [name]="passwordIcon"
                          (click)="onTogglePasswordVisibility()">
                </app-icon>
            </div>

            <ng-content select="[slot=after-input]"></ng-content>
        </div>
    </div>

    <div class="form-field-hint">
        <ng-content select="app-form-field-hint"></ng-content>
    </div>

    <ng-content></ng-content>

    <div class="form-field-error" *ngIf="control.errorState">
        <ng-content select="app-form-field-error"></ng-content>
    </div>
</ng-container>
