import { formatDate } from '@angular/common';

export function localDateReached(deadline?: Date | null): boolean {
    if (!deadline) {
        return false;
    }

    const now = new Date();
    now.setHours(deadline.getHours());
    now.setMinutes(deadline.getMinutes());
    now.setSeconds(deadline.getSeconds());
    now.setMilliseconds(deadline.getMilliseconds());

    return now > deadline;
}

export function formatDateToYYYYMMDD(date: Date, separator?: string): string {
    return formatDate(date, ['yyyy', 'MM', 'dd'].join(separator ?? ''), 'en-US');
}
