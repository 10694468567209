import { IsNullable } from '@webmozarts/http-client';
import { IsDefined } from 'class-validator';

export class AuditCompany {
    @IsDefined()
    id!: string;

    @IsDefined()
    name!: string;

    @IsDefined()
    @IsNullable()
    primaryContactId!: string | null;

    @IsDefined()
    @IsNullable()
    hasContract!: boolean;

    @IsDefined()
    logoRelativePath!: string;

    @IsDefined()
    certificateFooter!: string;

    @IsDefined()
    certificateTextEntireBusinessGerman!: string;

    @IsDefined()
    certificateTextGrapeSupplierGerman!: string;

    @IsDefined()
    certificateSignatureLineGerman!: string;

    @IsDefined()
    certificateTextEntireBusinessEnglish!: string;

    @IsDefined()
    certificateTextGrapeSupplierEnglish!: string;

    @IsDefined()
    certificateSignatureLineEnglish!: string;

    @IsDefined()
    certificateSignatureRelativePath!: string;

}
