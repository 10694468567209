<ng-container *ngIf="(isImpersonator | async) === false else impersonatorMenu">
    <div [matMenuTriggerFor]="menu" class="menu-trigger"
         i18n="@@menu.myMenu">
        Mein Konto<ng-container *ngIf="accountName | async as name">: {{name}}</ng-container><app-icon name="chevron_down"></app-icon>
    </div>

    <mat-menu #menu="matMenu" xPosition="after" yPosition="below">
        <button mat-menu-item
                [routerLink]="'/authentication/change-email'"
                i18n="@@menu.changeEmailButton">
            E-Mail-Adresse ändern
        </button>
        <button mat-menu-item
                [routerLink]="'/authentication/change-password'"
                i18n="@@menu.changePasswordButton">
            Passwort ändern
        </button>
        <button mat-menu-item
                (click)="onLogout()"
                i18n="@@action.logout">
            Abmelden
        </button>
    </mat-menu>

</ng-container>

<ng-template #impersonatorMenu>
    <a (click)="onRestoreOriginalUser()"
       i18n="@@menu.restoreOriginalUserLink">
        Benutzer verlassen
        <app-icon class="loginAs" name="login_as"></app-icon>
    </a>
</ng-template>
