import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { DECIMAL_MARKER, LOCALE } from '@app/constants';
import { ImpersonateUserInterceptor } from '@app/core/authentication/interceptors/impersonate-user.interceptor';
import {
    RedirectNonAuthenticatedInterceptor,
} from '@app/core/authentication/interceptors/redirect-non-authenticated.interceptor';
import { AccessTokenStorage } from '@app/core/authentication/services/access-token-storage.service';
import { HIERARCHY, HIERARCHY_TOKEN } from '@app/core/authentication/services/hierarchy';
import { EnsureModuleIsLoadedOnlyOnceGuard } from '@app/core/ensure-module-is-loaded-only-once.guard';
import { jwtOptionsFactory } from '@app/core/jwt-options-factory';
import { RequestErrorInterceptor } from '@app/error-pages/interceptors/request-error.interceptor';
import { IconRegistry } from '@app/shared/components/icons/icon-registry.service';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClientModule } from '@webmozarts/http-client';
import localeDE from '@angular/common/locales/de';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { CheckAppVersionInterceptor } from "@app/core/http/interceptors/check-app-version.interceptor";
import { ReloadAppDialogComponent } from "@app/core/components/reload-app-dialog/reload-app-dialog.component";
import { EnableXdebugInterceptor } from "@app/core/authentication/interceptors/enable-xdebug.interceptor";
import { icons } from "@app/core/icons";

export const maskConfig: Partial<IConfig> = {
    thousandSeparator: '.',
    // Needs to be set additionally for each input because of this bug: https://github.com/JsDaddy/ngx-mask/issues/825
    decimalMarker: DECIMAL_MARKER,
};

const httpInterceptors: NgModule['providers'] = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CheckAppVersionInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: RedirectNonAuthenticatedInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestErrorInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ImpersonateUserInterceptor,
        multi: true,
    },
];

if (!environment.production) {
    httpInterceptors.push({
        provide: HTTP_INTERCEPTORS,
        useClass: EnableXdebugInterceptor,
        multi: true,
    });
}

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forRoot([]),
        HttpClientModule.forRoot({
            config: {
                apiUrl: environment.apiUrl,
            },
        }),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [AccessTokenStorage],
            },
        }),
        ReloadAppDialogComponent,
        StoreModule.forRoot({}, {}),
        // Instrumentation must be imported after importing StoreModule
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
    ],
    providers: [
        IconRegistry,
        ...httpInterceptors,
        {
            provide: HIERARCHY_TOKEN,
            useValue: HIERARCHY,
        },
        {
            provide: LOCALE_ID,
            useValue: LOCALE,
        },
        provideEnvironmentNgxMask(maskConfig),
    ],
})
export class CoreModule extends EnsureModuleIsLoadedOnlyOnceGuard {
    // Ensure that CoreModule is only loaded into AppModule

    // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
    constructor(@Optional() @SkipSelf() parentModule: CoreModule,
                private iconRegistry: IconRegistry) {
        super(parentModule);

        this.iconRegistry.registerIcons(icons);

        registerLocaleData(localeDE, 'de');
    }

}
