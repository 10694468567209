import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { StripTagsPipe } from '@app/shared/pipes/strip-tags/strip-tags.pipe';

@Pipe({
    name: 'nl2br',
})
export class Nl2BrPipe implements PipeTransform {

    constructor(
        private stripTagsPipe: StripTagsPipe,
        private sanitizer: DomSanitizer,
    ) {
    }

    transform(value: unknown): SafeHtml | undefined {
        if (typeof value !== 'string') {
            return;
        }

        const textStripped = this.stripTagsPipe.transform(value);

        const textParsed = textStripped.replace(/\r\n|\r|\n/g, '<br />');

        return textParsed ? this.sanitizer.bypassSecurityTrustHtml(textParsed) : undefined;
    }

}
