import { Component } from '@angular/core';

@Component({
    selector: 'app-timeline-label',
    templateUrl: './timeline-label.component.html',
    styleUrls: ['./timeline-label.component.scss'],
    standalone: true,
})
export class TimelineLabelComponent {
    constructor() {
    }
}
