<section [ngClass]="{'active': active, 'last': isLastStep}">
    <aside>
        <ng-container *ngIf="done">
                <span class="icon-done">
                    <app-icon name="check"></app-icon>
                </span>
        </ng-container>
        <ng-container *ngIf="active">
            <span class="icon-active"></span>
        </ng-container>
        <ng-container *ngIf="!active && !done">
            <span class="icon-upcoming"></span>
        </ng-container>
        <span class="line" [ngClass]="{'done': done}"></span>
    </aside>
    <main>
        <ng-content select="app-timeline-label"></ng-content>
        <div class="content" *ngIf="active">
            <ng-content *ngIf="active"></ng-content>
        </div>
    </main>
</section>
