import { Injectable, OnDestroy } from '@angular/core';
import {
    Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent,
} from '@angular/router';
import * as Sentry from '@sentry/angular';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

const ROUTER_EVENTS_TO_LOG = [
    NavigationCancel,
    NavigationError,
];

function shouldLogRouterEvent(event: RouterEvent): boolean {
    return ROUTER_EVENTS_TO_LOG.some((loggableEventType) => event instanceof loggableEventType);
}

function logRouterEvent(router: Router, event: RouterEvent): void {
    if (event instanceof NavigationCancel) {
        return logNavigationCancel(event, router);
    }

    return logGenericRouterEvent(event, router);
}

function logNavigationCancel(event: NavigationCancel, router: Router): void {
    const currentNavigation = router.getCurrentNavigation()!;

    const navigationIdMismatch = event.id !== currentNavigation.id;

    const message = `Cancelled navigation`;

    const context = {
        id: event.id,
        initialUrl: currentNavigation.initialUrl.toString(),
        targetUrl: event.url,
        reason: event.reason,
        trigger: currentNavigation.trigger,
        extras: JSON.stringify(currentNavigation.extras),
        ...navigationIdMismatch && {
            currentNavigationId: currentNavigation.id,
            currentNavigationFinalUrl: currentNavigation.finalUrl?.toString(),
        },
    };

    logNavigationBreadcrumb(message, event, context);
}

function logGenericRouterEvent(event: RouterEvent, router: Router): void {
    const currentNavigation = router.getCurrentNavigation();

    const message = `RouterErrorService: ${extractRouterEventName(event)}`;

    const currentNavigationContext = {
        id: currentNavigation?.id,
        initialUrl: currentNavigation?.initialUrl.toString(),
        trigger: currentNavigation?.trigger,
        extras: JSON.stringify(currentNavigation?.extras),
        extractedUrl: currentNavigation?.extractedUrl.toString(),
        finalUrl: currentNavigation?.finalUrl?.toString(),
    };

    logNavigationBreadcrumb(message, event, currentNavigationContext);
}

function logNavigationBreadcrumb(
    message: string,
    event: RouterEvent,
    navigationContext: object,
): void {
    Sentry.addBreadcrumb({
        category: 'navigation',
        message,
        data: {
            'router event': event,
            'current navigation': navigationContext,
        },
        level: 'error',
    });
}

function extractRouterEventName(event: RouterEvent): string {
    if (event instanceof NavigationStart) {
        return 'NavigationStart';
    }

    if (event instanceof NavigationCancel) {
        return 'NavigationCancel';
    }

    if (event instanceof NavigationEnd) {
        return 'NavigationEnd';
    }

    if (event instanceof NavigationError) {
        return 'NavigationError';
    }

    return event.constructor.name;
}

@Injectable({
    providedIn: 'root',
})
export class RouterErrorService implements OnDestroy {

    private ngUnsubscribe = new Subject<void>();

    constructor(
        private router: Router,
    ) {
    }

    initialize() {
        this.router
            .events
            .pipe(
                filter((e: Event|RouterEvent): e is RouterEvent => e instanceof RouterEvent),
                filter(shouldLogRouterEvent),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((event) => {
                logRouterEvent(this.router, event);
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
