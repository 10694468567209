import { Directive } from '@angular/core';

@Directive({
    selector: 'app-form-field-error',
    host: {
        style: 'display: block',
    },
})
export class FormFieldErrorDirective {
}
