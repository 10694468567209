import { Injectable, OnDestroy } from '@angular/core';
import { Authenticator } from '@app/core/authentication/services/authenticator.service';
import { User } from '@app/shared/models/user.model';
import * as Sentry from '@sentry/angular';
import { MonoTypeOperatorFunction, pipe, Subject } from 'rxjs';
import { distinctUntilChanged, skip, startWith, takeUntil } from 'rxjs/operators';

function handleViewerChange(user: User | null): void {
    setSentryUser(user);
    addAuthenticationBreadcrumb(null !== user ? `Logged in as ${user.email}.` : 'Logged out.');
}

function setSentryUser(user: User|null): void {
    Sentry.setUser(mapUserToSentryUser(user));
    Sentry.setTag('role', user?.role);
}

function mapUserToSentryUser(user: User | null): Sentry.User | null {
    return null === user
        ? null
        : {
            email: user.email,
        };
}

function handleImpersonationChange(isImpersonated: boolean): void {
    setImpersonatedTag(isImpersonated);
    addAuthenticationBreadcrumb(isImpersonated ? 'Impersonating user.' : 'Impersonation finished.');
}

function setImpersonatedTag(isImpersonated: boolean): void {
    Sentry.setTag("impersonated", isImpersonated ? 'yes' : 'no');
}

function addAuthenticationBreadcrumb(message: string): void {
    Sentry.addBreadcrumb({
        category: 'authentication',
        message,
    });
}

function filterInitialNull(): MonoTypeOperatorFunction<User | null> {
    return pipe(
        startWith(null),
        distinctUntilChanged(),
        skip(1),
    );
}

function filterInitialFalse(): MonoTypeOperatorFunction<boolean> {
    return pipe(
        startWith(false),
        distinctUntilChanged(),
        skip(1),
    );
}

@Injectable({
    providedIn: 'root',
})
export class SentryScopeSetter implements OnDestroy {

    private readonly ngUnsubscribe = new Subject<void>();

    constructor(
        private authenticator: Authenticator,
    ) {
    }

    initialize(): void {
        this.authenticator.getViewer()
            .pipe(filterInitialNull())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(handleViewerChange);
        this.authenticator.isImpersonator
            .pipe(filterInitialFalse())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(handleImpersonationChange);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
