import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class WindowReloader {
    reload(): void {
        window.location.reload();
    }
}
