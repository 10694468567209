import { Component } from '@angular/core';
import { ToolbarNavItem } from '@app/shared/components/toolbar-nav/toolbar-nav-item';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    navItems: ToolbarNavItem[] = [
        {
            label: 'AGB',
            url: '/terms',
        },
        {
            label: 'Datenschutz',
            url: '/privacy-terms',
        },
        {
            label: 'Impressum',
            url: '/imprint',
        },
    ];

    get currentYear(): number {
        return new Date().getFullYear();
    }

    version = environment.releaseVersion;

    constructor() {
    }
}
