import { Injectable } from '@angular/core';

import { IconName, SvgIcon } from '@icons/svg-icons';

@Injectable({
    providedIn: 'root',
})
export class IconRegistry {

    private registry = new Map<string, string>();

    public registerIcons(icons: SvgIcon[]): void {
        icons.forEach((icon: SvgIcon) => this.registry.set(icon.name, icon.data));
    }

    public getIcon(iconName: IconName): string | undefined {
        if (!this.registry.has(iconName)) {
            console.error(`No icon with the name ${iconName} could be found. Did you add it to the Icon registry?`);
        }

        return this.registry.get(iconName);
    }
}
