import { AccessTokenStorage } from '@app/core/authentication/services/access-token-storage.service';
import { JwtConfig } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';

export function jwtOptionsFactory(accessTokenStorage: AccessTokenStorage): JwtConfig {
    return {
        tokenGetter: () => {
            const token = accessTokenStorage.getToken();

            if (null === token) {
                return null;
            }

            return token.getValue();
        },
        allowedDomains: environment.allowedJwtDomains,
        disallowedRoutes: [
            /\/api\/authentication\/login/,
            /\/api\/public\//,
        ],
        throwNoTokenError: true,
    };
}
