import { ErrorHandler } from '@angular/core';
import { RequestAppVersionChecker } from "@app/core/http/request-app-version-checker.service";

const CHUNK_FAILED_MESSAGE_REGEX = /Loading chunk [\d]+ failed/;

function isFailedChunkError(error: any): error is Error {
    return 'object' === typeof error
        && null !== error
        && error.message
        && CHUNK_FAILED_MESSAGE_REGEX.test(error.message);
}

export class FailedChunkErrorHandler implements ErrorHandler {
    constructor(
        private readonly errorHandler: ErrorHandler,
        private readonly versionChecker: RequestAppVersionChecker,
    ) {
    }

    handleError(error: any): void {
        if (isFailedChunkError(error)) {
            this.versionChecker.showReloadModal();
        } else {
            this.errorHandler.handleError(error);
        }
    }
}
