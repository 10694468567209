import { AbstractControl, ValidationErrors } from '@angular/forms';

function isEmptyTrimmedInputValue(value: any): boolean {
    if ('string' === typeof value) {
        return 0 === value.trim().length;
    }

    // See https://github.com/angular/angular/blob/5960d4c51372c16fa72eb4f620e2d9c2314b206a/packages/forms/src/validators.ts#L16
    return null == value || 0 === value.length;
}

export default function requiredValidator(control: AbstractControl): ValidationErrors|null {
    return isEmptyTrimmedInputValue(control.value) ? { required: true } : null;
}
