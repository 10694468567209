import { LOCALE } from '@app/constants';

function createFormatter(fractionDigits: number): Intl.NumberFormat {
    return new Intl.NumberFormat(
        LOCALE,
        {
            style: 'percent',
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        },
    );
}

export function createFormatPercentage(roundingPrecision: number): (value: number) => string {
    return (value: number) => formatPercentage(
        createFormatter(roundingPrecision),
        value,
    );
}

function formatPercentage(formatter: Intl.NumberFormat, value: number): string {
    return formatter.format(value).replace(' ', '');
}
