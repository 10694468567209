export function formatToDuration(value: number): string {
    const hours = Number(Math.trunc(value).toFixed(0));
    const decimalPart = Number((value - hours).toFixed(6));
    const minutes = Math.round(decimalPart * 60);

    const zeroPaddedHours = hours.toString().padStart(2, '0');
    const zeroPaddedMinutes = minutes.toString().padStart(2, '0');

    return `${zeroPaddedHours}:${zeroPaddedMinutes}`;
}

export function parseDuration(value: string): number {
    const [hoursString, minutesString] = value.split(':');

    const hours = parseFloat(hoursString);
    const minutes = parseFloat(minutesString) / 60;

    return Number((hours + minutes).toFixed(6));
}
