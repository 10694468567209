import { InjectionToken } from '@angular/core';
import { UserRole } from '@app/shared/models/user-role.model';

export type Hierarchy = { [key in UserRole]?: UserRole[] };

// To keep in sync with api/config/packages/security.yaml#security.role_hierarchy
export const HIERARCHY: Hierarchy = {
    [UserRole.ADMIN]: [UserRole.WINERY_USER, UserRole.AUDIT_COMPANY_ADMIN],
    [UserRole.AUDIT_COMPANY_ADMIN]: [UserRole.AUDITOR],
    [UserRole.AUDITOR]: [],
    [UserRole.WINERY_USER]: [],
};

export const HIERARCHY_TOKEN = new InjectionToken('HIERARCHY_TOKEN');
