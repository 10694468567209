import { Component } from '@angular/core';
import { MatDialogModule } from "@angular/material/dialog";
import { CardItemComponent } from "@app/shared/components/card/card-item/card-item.component";
import { CardComponent } from "@app/shared/components/card/card.component";
import { ToolbarComponent } from "@app/shared/components/toolbar/toolbar.component";
import { ButtonColorDirective } from "@app/shared/components/button/button-color.directive";
import { PrimaryDirective } from "@app/shared/components/button/primary.directive";
import { WindowReloader } from "@app/core/services/reloader.service";

@Component({
    standalone: true,
    templateUrl: './reload-app-dialog.component.html',
    styleUrls: ['./reload-app-dialog.component.scss'],
    imports: [
        ButtonColorDirective,
        CardComponent,
        CardItemComponent,
        MatDialogModule,
        PrimaryDirective,
        ToolbarComponent,
    ],
})
export class ReloadAppDialogComponent {

    loading = false;

    constructor(
        private windowReloader: WindowReloader,
    ) {
    }

    onReload() {
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.windowReloader.reload();
    }
}
