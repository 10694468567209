import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'germanDateTime',
})
export class GermanDateTimePipe implements PipeTransform {
    private readonly datePipe: DatePipe;

    constructor(@Inject(LOCALE_ID) localId: string) {
        this.datePipe = new DatePipe(localId);
    }

    transform(value: any): string | null {
        return this.datePipe.transform(value, 'dd.MM.yyyy HH:mm');
    }
}
