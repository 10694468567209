import { Pipe, PipeTransform } from '@angular/core';
import anonymizeEmail from '@app/authentication/components/registration/anonymize-email';

@Pipe({
    name: 'anonymizeEmail',
})
export class AnonymizeEmailPipe implements PipeTransform {
    transform(email?: string): string | undefined {
        return email ? anonymizeEmail(email) : email;
    }
}
