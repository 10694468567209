import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { assertIsString } from '@app/shared/type-guards';

type UnstyleCallback = () => void;

@Directive({
    selector: ':not(button)[loading]',
})
export class LoadingDirective implements OnChanges {

    @Input({ required: true }) loading!: BooleanInput;

    get nativeElement(): HTMLElement {
        return this.element.nativeElement;
    }

    private unstyleCallbacks: UnstyleCallback[] = [];

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const loadingChanged = 'loading' in changes;

        if (!loadingChanged) {
            return;
        }

        if (coerceBooleanProperty(changes.loading.currentValue)) {
            this.styleElement();
        } else if (!changes.loading.firstChange) {
            this.unstyleElement();
        }
    }

    private styleElement(): void {
        this.unstyleCallbacks = [
            this.addElementStyle('opacity', '0.5'),
            this.addElementStyle('pointerEvents', 'none'),
        ];
    }

    private unstyleElement(): void {
        this.unstyleCallbacks.reverse().forEach((callback) => callback());
    }

    private addElementStyle(cssAttribute: keyof CSSStyleDeclaration, cssValue: string): UnstyleCallback {
        assertIsString(cssAttribute);

        const originalValue = this.nativeElement.style[cssAttribute];

        this.renderer.setStyle(this.nativeElement, cssAttribute, cssValue);

        return originalValue
            ? () => this.renderer.setStyle(this.nativeElement, cssAttribute, originalValue)
            : () => this.renderer.removeStyle(this.nativeElement, cssAttribute);
    }
}
