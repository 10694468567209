<ng-container *ngIf="companies">
    <table mat-table [dataSource]="companies">
        <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef i18n="@@model.company">Betrieb</th>
            <td mat-cell *matCellDef="let company; let index = index"
                [class.clickable]="showDetailedContacts"
                (click)="onDetailClick(company)">
                {{ company.publishedName }}
                <app-main-company-badge *ngIf="index === 0">
                </app-main-company-badge>
            </td>
        </ng-container>

        <ng-container matColumnDef="assets">
            <th mat-header-cell *matHeaderCellDef i18n="@@certificationTable.document">Dokumente</th>
            <td mat-cell *matCellDef="let company">
                <div class="asset-links">
                    <ng-container *ngIf="isWinery" [ngTemplateOutlet]="wineryDownload"
                                  [ngTemplateOutletContext]="{ company: company }">
                    </ng-container>
                    <ng-container *ngIf="!isWinery" [ngTemplateOutlet]="auditCompanyDownload"
                                  [ngTemplateOutletContext]="{ company: company }">
                    </ng-container>
                    <div *ngIf="showLogos"
                         class="asset-link"
                         (click)="onDownloadLogos(company)">
                        <app-icon name="nachhaltig_label"></app-icon>
                        Logos
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef class="col-details"></th>
            <td mat-cell *matCellDef="let company" class="col-details">
                <app-icon name="chevron_right" class="clickable" (click)="onDetailClick(company)">
                </app-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;">
        </tr>
    </table>
</ng-container>

<ng-template #wineryDownload let-company="company">
    <div class="asset-link" [ngClass]="{ disabled: !company.hasCertificates }"
         (click)="onDownloadGermanCertificate(company)"
         i18n="@@certificationTable.downloadGermanCertificationLinkAsWineryUser">
        <app-icon name="certification"></app-icon>
        Zertifikat (Deutsch)
    </div>
    <div class="asset-link" [ngClass]="{ disabled: !company.hasCertificates }"
         (click)="onDownloadEnglishCertificate(company)"
         i18n="@@certificationTable.downloadEnglishCertificationLinkAsWineryUser">
        <app-icon name="certification"></app-icon>
        Zertifikat (Englisch)
    </div>
</ng-template>


<ng-template #auditCompanyDownload let-company="company">
    <div class="asset-link" [ngClass]="{ disabled: !company.hasCertificates }"
         [matMenuTriggerFor]="dropdownGermanCertificates">
        <app-icon name="certification"></app-icon>
        <ng-container i18n="@@certificationTable.germanCertificates">Zertifikat (Deutsch)</ng-container>

        <mat-menu #dropdownGermanCertificates="matMenu" xPosition="after" yPosition="below">
            <button mat-menu-item (click)="onDownloadGermanCertificate(company)"
                    i18n="@@certificationTable.downloadGermanCertificationAsAuditCompanyUserButton">
                Zertifikat (Deutsch)
            </button>
            <button mat-menu-item (click)="onDownloadGermanPrintCertificate(company)"
                    i18n="@@certificationTable.downloadGermanPrintCertificationAsAuditCompanyUserButton">
                Zertifikat (Deutsch, Druck)
            </button>
        </mat-menu>
    </div>
    <div class="asset-link" [ngClass]="{ disabled: !company.hasCertificates }"
         [matMenuTriggerFor]="dropdownEnglishCertificates">
        <app-icon name="certification" i18n="@@certificationTable.englishCertificates"></app-icon>
        Zertifikat (Englisch)

        <mat-menu #dropdownEnglishCertificates="matMenu" xPosition="after" yPosition="below">
            <button mat-menu-item (click)="onDownloadEnglishCertificate(company)"
                    i18n="@@certificationTable.downloadEnglishCertificationAsAuditCompanyUserButton">
                Zertifikat (Englisch)
            </button>
            <button mat-menu-item (click)="onDownloadEnglishPrintCertificate(company)"
                    i18n="@@certificationTable.downloadEnglishCertificationAsAuditCompanyUserButton">
                Zertifikat (Englisch, Druck)
            </button>
        </mat-menu>
    </div>
</ng-template>
