export default interface SelectOption {
    value: any,
    name: string,
}

export function createSelectOptions<TEnumKey extends string, TEnumValue extends string>(
    enumVariable: Record<TEnumKey, TEnumValue>,
    enumNames: Record<TEnumValue, string>,
) {
    return Object.keys(enumVariable)
        .map((enumKey) => enumVariable[enumKey as keyof typeof enumVariable])
        .map((enumValue) => (
            {
                name: enumNames[enumValue],
                value: enumValue,
            }
        ));
}
