import { DataRepresentation } from '@app/shared/models/data-representation';
import { Unit } from '@app/shared/models/unit';
import { Type } from 'class-transformer';
import { IsDefined, IsEnum } from 'class-validator';

export class DataVisualizationDefinition {
    @IsDefined()
    id!: string;

    @IsDefined()
    globalId!: string;

    @IsDefined()
    @IsEnum(Unit)
    @Type()
    unit!: Unit;

    @IsDefined()
    @IsEnum(DataRepresentation)
    @Type()
    representation!: DataRepresentation;
}
