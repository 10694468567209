import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthorizationService } from '@app/core/authentication/services/authorization.service';
import { isUserRole } from '@app/shared/models/user-role.model';
import { Observable, of } from 'rxjs';

export const IS_ROLE_GRANTED = 'isRoleGranted';

@Injectable({
    providedIn: 'root',
})
export class IsRoleGrantedGuard implements CanActivate {

    constructor(
        private readonly authorizationService: AuthorizationService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const routeConfig = route.routeConfig;

        if (null === routeConfig) {
            return of(false);
        }

        const role = routeConfig.data?.[IS_ROLE_GRANTED];

        if (!isUserRole(role)) {
            return of(false);
        }

        return this.authorizationService.isViewerGranted(role);
    }
}
