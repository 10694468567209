<app-toolbar class="header" role="banner">
    <ng-container slot="start">
        <a routerLink="/">
            <img class="logo" [src]="logoPath" alt="logo"/>
        </a>
        <app-toolbar-nav [toolbarNavItems]="navigationItems"></app-toolbar-nav>
    </ng-container>

    <ng-container slot="end">
        <div class="endContainer">
            <app-menu *ngIf="isAuthenticated | async"></app-menu>
            <app-notification-message></app-notification-message>
        </div>
    </ng-container>
</app-toolbar>
