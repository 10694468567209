<app-form-field>
    <app-label [ngClass]="{'font-weight-normal': Unit.BOOLEAN === baseValueDefinition.unit}" [innerHTML]="baseValueDefinition.label | stripTagsExcept: ['link-tags']"></app-label>

    <mat-select *ngIf="baseValueDefinition.inputType === InputType.DROPDOWN && !displayAsYesNoToggle"
                [formControl]="baseValueControl"
                placeholder="Bitte wählen..."
                i18n-placeholder="@@assessment.baseValueInput.form.baseValue.placeholder"
                appInput
                required>
        <ng-container *ngFor="let selectableValue of baseValueDefinition.selectableValues">
            <mat-option [value]="selectableValue.value">
                {{selectableValue.label}}
            </mat-option>
        </ng-container>
    </mat-select>

    <app-toggle-button *ngIf="baseValueDefinition.inputType === InputType.DROPDOWN && displayAsYesNoToggle"
                       [formControl]="baseValueControl"
                       yes="Ja"
                       i18n-yes="@@term.yes"
                       no="Nein"
                       i18n-no="@@term.no"
                       appInput
                       noColor="error"
                       required></app-toggle-button>

    <mat-checkbox *ngIf="Unit.BOOLEAN === baseValueDefinition.unit"
                  [formControl]="baseValueControl"
                  color="primary"
                  appInput>
    </mat-checkbox>

    <input *ngIf="baseValueDefinition.inputType === InputType.NUMBER_INPUT && Unit.BOOLEAN !== baseValueDefinition.unit"
           [formControl]="baseValueControl"
           [placeholder]="baseValueDefinition.placeholder"
           [suffix]="baseValueDefinition.suffix"
           [mask]="baseValueDefinition.mask"
           [decimalMarker]="DECIMAL_MARKER"
           appInput
           required>

    <app-form-field-error *ngIf="baseValueControl.hasError('required')"
                          i18n="@@assessment.baseValueInput.form.baseValue.requiredError">
        {{ baseValueDefinition.requiredMessage }}
    </app-form-field-error>

    <app-form-field-error *ngIf="errorMessage">
        {{ errorMessage }}
    </app-form-field-error>

    <p *ngIf="!!multipleCompanies && baseValueDefinition.inputType === InputType.NUMBER_INPUT"
       i18n="@@assessment.baseValueInput.form.baseValue.numberInput">
        Bitte addieren Sie die Werte aller Betriebe aus Ihrem Familienverbund.
    </p>

    <p slot="after-input" class="icon-wrapper"*ngIf="iconAfterInput">
        <app-icon [name]="iconAfterInput">
        </app-icon>
    </p>
</app-form-field>
