import { Component, Input } from '@angular/core';
import { BadgeColor } from '@app/shared/components/badge/badge.component';
import {
    WORKFLOW_NAMES, Workflow,
} from '@app/shared/models/workflow.model';

const WORKFLOW_BADGE_COLOR: Record<Workflow, BadgeColor | undefined> = {
    [Workflow.AUDIT]: 'brown',
    [Workflow.AUDIT_WITH_ADVANCE_NOTIFICATION]: 'purple',
    [Workflow.EVALUATION]: undefined,
};

@Component({
    selector: 'app-workflow-badge',
    templateUrl: './workflow-badge.component.html',
    styleUrls: ['./workflow-badge.component.scss'],
})
export class WorkflowBadgeComponent {

    @Input({ required: true }) workflow!: Workflow;

    get name(): string {
        return WORKFLOW_NAMES[this.workflow];
    }

    get badgeColor(): BadgeColor | undefined {
        return WORKFLOW_BADGE_COLOR[this.workflow];
    }

    constructor() {
    }
}
