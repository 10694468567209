import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationService } from '@app/core/authentication/services/authorization.service';
import { BaseValue } from '@app/shared/models/assessment/base-value.model';
import { BaseValueDefinition } from '@app/shared/models/process/base-value-definition.model';
import { Unit } from '@app/shared/models/unit';
import { UserRole } from '@app/shared/models/user-role.model';
import { isNotNull } from '@app/shared/type-guards';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type RichBaseValue = {
    value: string | number,
    label: string,
    unit: Unit | null,
};

function createRichBaseValue(
    baseValue: BaseValue,
    baseValueDefinitions: BaseValueDefinition[],
): RichBaseValue | null {
    const baseValueDefinition = baseValueDefinitions
        .find((baseValueDefinition) => baseValueDefinition.id === baseValue.baseValueDefinitionId);

    if (undefined === baseValueDefinition) {
        return null;
    }

    return {
        value: baseValue.value,
        label: baseValueDefinition.label,
        unit: baseValueDefinition.unit,
    };
}

function merge(baseValues: BaseValue[], baseValueDefinitions: BaseValueDefinition[]): RichBaseValue[] {
    return baseValues
        .map((baseValue) => createRichBaseValue(baseValue, baseValueDefinitions))
        .filter(isNotNull);
}

@Component({
    selector: 'app-future-base-values-summary',
    templateUrl: './future-base-values-summary.component.html',
    styleUrls: ['./future-base-values-summary.component.scss'],
})
export class FutureBaseValuesSummaryComponent implements OnInit, OnChanges, OnDestroy {

    private readonly ngUnsubscribe = new Subject<void>();

    richBaseValues: RichBaseValue[] = [];

    @Input() baseValues: BaseValue[] = [];

    @Input() baseValueDefinitions: BaseValueDefinition[] = [];

    isWinery = true;

    get boxHeadline(): string {
        return this.isWinery
            ? $localize`:@@futureBaseValuesSummary.headlineForWinery:Sie haben geänderte Grunddaten übermittelt:`
            : $localize`:@@futureBaseValuesSummary.headlineForNonWinery:Der Betrieb hat geänderte Grunddaten übermittelt:`;
    }

    constructor(
        private authorizationService: AuthorizationService,
    ) {
    }

    ngOnInit(): void {
        this.authorizationService.isViewer(UserRole.WINERY_USER)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((isWinery) => this.isWinery = isWinery);
    }

    ngOnChanges() {
        this.richBaseValues = merge(this.baseValues, this.baseValueDefinitions);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
