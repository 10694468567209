import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

type ToggleButtonColor = 'primary' | 'error';

@Component({
    selector: 'app-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleButtonComponent),
            multi: true,
        },
    ],
})
export class ToggleButtonComponent implements ControlValueAccessor {
    private _narrow = false;

    formControl = new UntypedFormControl(null);

    @HostBinding('class.indefinite')
    get indefinite(): boolean {
        const { value } = this.formControl;

        return value !== this.yesValue
            && value !== this.noValue;
    }

    @Input('yes') yesValue: any = true;

    @Input('no') noValue: any = false;

    @Input()
    get narrow(): BooleanInput {
        return this._narrow;
    }

    set narrow(value: BooleanInput) {
        this._narrow = coerceBooleanProperty(value);
    }

    @Input() yesColor: ToggleButtonColor = 'primary';

    @Input() noColor: ToggleButtonColor = 'primary';

    private onTouched: any = () => {};

    writeValue(value: any) {
        if (!this.isValidValue(value)) {
            throw new Error(`Invalid toggle value "${value}"`);
        }

        this.formControl.setValue(value, { emitEvent: false });
    }

    registerOnChange(onChangeObserver: any) {
        this.formControl.valueChanges.subscribe(onChangeObserver);
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }
    }

    onClick(value: boolean): void {
        if (this.formControl.disabled) {
            return;
        }

        this.formControl.setValue(value);
        this.onTouched();
    }

    private isValidValue(value: any): boolean {
        return this.getValidValues().includes(value);
    }

    private getValidValues(): any[] {
        return [null, this.yesValue, this.noValue];
    }
}
