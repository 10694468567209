export enum Workflow {
    AUDIT = 'audit',
    AUDIT_WITH_ADVANCE_NOTIFICATION = 'auditWithAdvanceNotification',
    EVALUATION = 'evaluation',
}

export const WORKFLOW_NAMES: Record<Workflow, string> = {
    [Workflow.AUDIT]: 'Gesamter Betrieb',
    [Workflow.AUDIT_WITH_ADVANCE_NOTIFICATION]: 'Traubenlieferant',
    [Workflow.EVALUATION]: 'Testbewertung',
};
