import { Pipe, PipeTransform } from '@angular/core';
import * as sanitize from 'sanitize-html';

@Pipe({
    name: 'stripTags',
})
export class StripTagsPipe implements PipeTransform {
    transform(value: string): string {
        return sanitize(value, {
            allowedTags: [],
            allowedAttributes: {},
        });
    }

}
