import { QualityGoalStatus } from '@app/shared/models/assessment/quality-goal-status';
import { Type } from 'class-transformer';
import { IsDefined, IsEnum } from 'class-validator';

export class QualityGoalResult {
    @IsDefined()
    qualityGoalId!: string;

    @IsDefined()
    @IsEnum(QualityGoalStatus)
    @Type()
    status!: QualityGoalStatus;
}
