import { Component, HostBinding, Input, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isPageWidth, PAGE_WIDTH, PageWidth } from '@app/shared/components/layout/page-width';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const DEFAULT_PAGE_WIDTH = PageWidth.EXTRA_SMALL;

@Component({
    selector: 'app-page',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnDestroy {

    private ngUnsubscribe = new Subject<void>();

    @HostBinding('class')
    @Input() width: PageWidth = DEFAULT_PAGE_WIDTH;

    @HostBinding('class.no-sizing')
    @Input() hasNestedLayout = false;

    constructor(
        route: ActivatedRoute,
        @SkipSelf() @Optional() private readonly parentLayoutComponent?: LayoutComponent,
    ) {
        if (parentLayoutComponent) {
            parentLayoutComponent.hasNestedLayout = true;
        }

        route.data
            .pipe(
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((data) => {
                const pageWidth = data[PAGE_WIDTH];

                this.width = isPageWidth(pageWidth)
                    ? pageWidth
                    : DEFAULT_PAGE_WIDTH;
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        if (this.parentLayoutComponent) {
            this.parentLayoutComponent.hasNestedLayout = false;
        }
    }
}
