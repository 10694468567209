import { Component, Input } from '@angular/core';
import { ToolbarNavItem } from '@app/shared/components/toolbar-nav/toolbar-nav-item';

@Component({
    selector: 'app-toolbar-nav',
    templateUrl: './toolbar-nav.component.html',
    styleUrls: ['./toolbar-nav.component.scss'],
})

export class ToolbarNavComponent {
    @Input() toolbarNavItems: ToolbarNavItem[] = [];

    constructor() {
    }
}
