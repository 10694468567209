<span>
    <ng-container *ngIf="false === evaluated else renderValue">
        Nicht zutreffend
    </ng-container>

    <ng-template #renderValue>
        <ng-container *ngIf="undefined !== formattedStringValue">
                {{ mask ? (formattedStringValue! | mask: mask:{ thousandSeparator }) : formattedStringValue }}{{ suffix }}
        </ng-container>
    </ng-template>
</span>
