import { ValidationErrors, ValidatorFn } from '@angular/forms';

const ERROR_KEY = 'forceInvalid';

export default function forceInvalid(shouldInvalidate: () => boolean): ValidatorFn {
    return (): ValidationErrors | null => {
        return shouldInvalidate()
            ? { [ERROR_KEY]: true }
            : null;
    };
}
