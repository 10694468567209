import { Component, Input } from '@angular/core';
import { Color } from 'chart.js';
import { NgFor } from '@angular/common';
import {
    DoughnutChartDataVisualizationLabelComponent,
} from "@app/shared/components/assessment/doughnut-chart-data-visualization/components/doughnut-chart-data-visualization-label/doughnut-chart-data-visualization-label.component";

@Component({
    selector: 'app-doughnut-chart-data-visualization-legend',
    templateUrl: './doughnut-chart-data-visualization-legend.component.html',
    styleUrls: ['./doughnut-chart-data-visualization-legend.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        DoughnutChartDataVisualizationLabelComponent,
    ],
})
export class DoughnutChartDataVisualizationLegendComponent {

    @Input({ required: true }) colors!: Color[];

    @Input({ required: true }) labels!: string[];
}
