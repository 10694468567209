import { AssessmentPhase } from '@app/shared/models/assessment/assessment-phase';
import { AssessmentType } from '@app/shared/models/assessment/assessment-type.model';
import { BaseValue } from '@app/shared/models/assessment/base-value.model';
import { DataVisualization } from '@app/shared/models/assessment/data-visualization.model';
import { FeedbackItem } from '@app/shared/models/assessment/feedback-item.model';
import { QualityGoalResult } from '@app/shared/models/assessment/quality-goal-result.model';
import { CertificationPreview } from '@app/shared/models/certification/certification.model';
import { Workflow } from '@app/shared/models/workflow.model';
import { dateToString, IsNullable } from '@webmozarts/http-client';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { IsArray, IsBoolean, IsDefined, IsEnum, IsString, ValidateNested } from 'class-validator';

const GHG_EMISSIONS_TABLE_DATA_VISUALIZATION_GLOBAL_ID = '05af22fc-3b3b-47a2-8b24-d0cc2da3c364';
const GHG_EMISSIONS_DOUGHNUT_DATA_VISUALIZATION_GLOBAL_ID = 'cdf590c8-4e24-443a-a51c-924bd6297089';
const GHG_EMISSIONS_SHARES_RECOMMENDATIONS_DATA_VISUALIZATION_GLOBAL_ID = 'fc514c9b-9e84-44a1-9d8e-ffec652bceb2';

export class Assessment {
    @IsDefined()
    id!: string;

    @IsDefined()
    growingSeasonId!: string;

    @IsDefined()
    @IsEnum(AssessmentType)
    @Type()
    type!: AssessmentType;

    @IsDefined()
    @IsEnum(Workflow)
    @Type()
    workflow!: Workflow;

    @IsDefined()
    @ValidateNested()
    @Type(() => CertificationPreview)
    certification!: CertificationPreview;

    @IsDefined()
    @Transform(dateToString)
    updatedAt!: Date;

    @IsDefined({ each: true })
    @IsArray()
    @ValidateNested()
    @Type(() => BaseValue)
    baseValues!: BaseValue[];

    @IsDefined({ each: true })
    @IsArray()
    @ValidateNested()
    @Type(() => BaseValue)
    futureBaseValues!: BaseValue[];

    @IsDefined()
    goalId!: string;

    @IsDefined()
    evaluationSchemeId!: string;

    @IsArray()
    @IsDefined({ each: true })
    @IsString({ each: true })
    selectedProcessIds!: string[];

    @IsDefined()
    @IsNullable()
    qualifiedForCertification!: boolean | null;

    @IsDefined()
    @IsEnum(AssessmentPhase)
    @Type()
    phase!: AssessmentPhase;

    @IsDefined()
    progress!: number;

    @IsDefined()
    complete!: boolean;

    @IsDefined({ each: true })
    @IsArray()
    @ValidateNested()
    @Type(() => QualityGoalResult)
    qualityGoalResults!: QualityGoalResult[];

    @IsDefined()
    @IsNullable()
    previousGrowingSeasonId!: string | null;

    @IsDefined({ each: true })
    @IsArray()
    @ValidateNested()
    @Type(() => FeedbackItem)
    feedbackItems!: FeedbackItem[];

    @IsDefined()
    multipleCompanies!: boolean;

    @IsDefined()
    @IsNullable()
    inspectorComment!: string | null;

    @IsDefined()
    @IsNullable()
    certifierComment!: string | null;

    @Exclude()
    _dataVisualizations!: DataVisualization[];

    @Expose()
    @IsDefined({ each: true })
    @IsArray()
    @ValidateNested()
    @Type(() => DataVisualization)
    get dataVisualizations(): DataVisualization[] {
        return this._dataVisualizations;
    }

    set dataVisualizations(value: DataVisualization[]) {
        this._dataVisualizations = value;

        this.dataVisualizationsByGlobalId = new Map(
            this._dataVisualizations.map(
                (dataVisualization) => [
                    dataVisualization.definition.globalId,
                    dataVisualization,
                ],
            ),
        );
    }

    @IsDefined()
    @IsBoolean()
    baseValuesComplete!: boolean;

    get isNew(): boolean {
        // Note: if it wasn't a new assessment, there would be at least 2 selected processes
        return 0 === this.selectedProcessIds?.length;
    }

    @Exclude()
    private dataVisualizationsByGlobalId: Map<string, DataVisualization>|undefined = undefined;

    findDataVisualizationByGlobalId(globalId: string): DataVisualization | undefined {
        return this.dataVisualizationsByGlobalId!.get(globalId);
    }

    get hasGhgEmissions(): boolean {
        return !!this.ghgEmissionsTableDataVisualization
            || (!!this.ghgEmissionsDoughnutDataVisualization && !!this.ghgEmissionsSharesRecommendationsDataVisualization);
    }

    get ghgEmissionsTableDataVisualization(): DataVisualization|undefined  {
        return this.findDataVisualizationByGlobalId(GHG_EMISSIONS_TABLE_DATA_VISUALIZATION_GLOBAL_ID);
    }

    get ghgEmissionsDoughnutDataVisualization(): DataVisualization|undefined  {
        return this.findDataVisualizationByGlobalId(GHG_EMISSIONS_DOUGHNUT_DATA_VISUALIZATION_GLOBAL_ID);
    }

    get ghgEmissionsSharesRecommendationsDataVisualization(): DataVisualization|undefined  {
        return this.findDataVisualizationByGlobalId(GHG_EMISSIONS_SHARES_RECOMMENDATIONS_DATA_VISUALIZATION_GLOBAL_ID);
    }
}

export function isAssessment(value: any): value is Assessment {
    return value instanceof Assessment;
}

export function assertAssessment(assessment: Assessment | undefined): asserts assessment is Assessment {
    if (undefined !== assessment) {
        return;
    }

    throw new Error('Assessment is not defined.');
}
