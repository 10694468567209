import { Type } from 'class-transformer';
import { IsArray, IsDefined, IsEnum, IsString } from 'class-validator';

export enum FeedbackType {
    ACHIEVEMENT= 'Errungenschaft',
    DEFICIENCY = 'Mangel',
}

export class FeedbackItem {
    @IsDefined()
    measureId!: string;

    @IsDefined()
    @IsEnum(FeedbackType)
    @Type()
    type!: FeedbackType;

    @IsArray()
    @IsDefined({ each: true })
    @IsString({ each: true })
    categoryIds!: string[];
}
