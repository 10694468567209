import {
    SvgIcon,
    svgIconArrowLeft,
    svgIconArrowRight,
    svgIconArrowUp,
    svgIconBinoculars,
    svgIconCalendar,
    svgIconCertification,
    svgIconCheck,
    svgIconCheckCircle,
    svgIconChecklist,
    svgIconChevronDown,
    svgIconChevronRight,
    svgIconCircle,
    svgIconCircleCurrent,
    svgIconCircleDone,
    svgIconCircleN,
    svgIconClingingGlasses,
    svgIconClose,
    svgIconDownload,
    svgIconEdit,
    svgIconExclamationCircle,
    svgIconEye,
    svgIconEyeSlash,
    svgIconFileEdit,
    svgIconGrapes,
    svgIconHandHoldingPaper,
    svgIconInspection,
    svgIconLoginAs,
    svgIconMailWaiting,
    svgIconNachhaltigLabel,
    svgIconPlus,
    svgIconQuestionCircle,
    svgIconQuestionCircleInverted,
    svgIconRibbon,
    svgIconSearch,
    svgIconSparks,
    svgIconStarCircle,
    svgIconSustainabilityNeutral,
    svgIconTimes,
    svgIconTimesCircle,
    svgIconUpload,
    svgIconUserPlusCircle,
    svgIconWrench,
} from '@icons/svg-icons';

export const icons: SvgIcon[] = [
    svgIconArrowLeft,
    svgIconArrowRight,
    svgIconArrowUp,
    svgIconBinoculars,
    svgIconCalendar,
    svgIconCertification,
    svgIconCheck,
    svgIconCheckCircle,
    svgIconChecklist,
    svgIconChevronDown,
    svgIconChevronRight,
    svgIconCircle,
    svgIconClingingGlasses,
    svgIconCircleCurrent,
    svgIconCircleDone,
    svgIconCircleN,
    svgIconClose,
    svgIconDownload,
    svgIconEdit,
    svgIconExclamationCircle,
    svgIconEye,
    svgIconEyeSlash,
    svgIconFileEdit,
    svgIconGrapes,
    svgIconHandHoldingPaper,
    svgIconInspection,
    svgIconLoginAs,
    svgIconMailWaiting,
    svgIconNachhaltigLabel,
    svgIconPlus,
    svgIconQuestionCircle,
    svgIconQuestionCircleInverted,
    svgIconRibbon,
    svgIconSearch,
    svgIconSparks,
    svgIconStarCircle,
    svgIconSustainabilityNeutral,
    svgIconTimes,
    svgIconTimesCircle,
    svgIconUpload,
    svgIconUserPlusCircle,
    svgIconWrench,
];
