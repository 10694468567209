import { Component, Input } from '@angular/core';
import { Color } from 'chart.js';

@Component({
    selector: 'app-doughnut-chart-data-visualization-label',
    templateUrl: './doughnut-chart-data-visualization-label.component.html',
    styleUrls: ['./doughnut-chart-data-visualization-label.component.scss'],
    standalone: true,
})
export class DoughnutChartDataVisualizationLabelComponent {

    @Input({ required: true }) color!: Color;

    @Input({ required: true }) label!: string;
}
