import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import {
    MAT_LEGACY_SELECT_CONFIG as MAT_SELECT_CONFIG,
    MatLegacySelectModule as MatSelectModule,
} from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { RouterModule } from '@angular/router';
import { BaseValueInputComponent } from '@app/shared/components/assessment/base-value-input/base-value-input.component';
import { BackButtonComponent } from '@app/shared/components/back-button/back-button.component';
import { BadgeComponent } from '@app/shared/components/badge/badge.component';
import { BaseValueTableComponent } from '@app/shared/components/base-value-table/base-value-table.component';
import { ButtonColorDirective } from '@app/shared/components/button/button-color.directive';
import { PrimaryDirective } from '@app/shared/components/button/primary.directive';
import { CardItemComponent } from '@app/shared/components/card/card-item/card-item.component';
import { CardComponent } from '@app/shared/components/card/card.component';
import {
    CertificationDownloadTableComponent,
} from '@app/shared/components/certification-table/certification-download-table.component';
import {
    DialogCloseButtonComponent,
} from '@app/shared/components/dialog/dialog-close-button/dialog-close-button.component';
import { DialogComponent } from '@app/shared/components/dialog/dialog.component';
import {
    DropdownButtonOptionComponent,
} from '@app/shared/components/dropdown-button/dropdown-button-option/dropdown-button-option.component';
import {
    EvaluationDownloadButtonComponent,
} from '@app/shared/components/evaluation-download-button/evaluation-download-button.component';
import { ExpansionPanelComponent } from '@app/shared/components/expansion-panel/expansion-panel.component';
import { FileUploadComponent } from '@app/shared/components/file-upload/file-upload.component';
import { FooterComponent } from '@app/shared/components/footer/footer.component';
import { FormFieldErrorDirective } from '@app/shared/components/form-field/form-field-error.directive';
import { FormFieldHintDirective } from '@app/shared/components/form-field/form-field-hint.directive';
import { FormFieldComponent } from '@app/shared/components/form-field/form-field.component';
import { InputDirective } from '@app/shared/components/form-field/input.directive';
import { LabelDirective } from '@app/shared/components/form-field/label.directive';
import { RequiredMarkerComponent } from '@app/shared/components/form-field/required-marker/required-marker.component';
import { FormErrorComponent } from '@app/shared/components/forms/form-error/form-error.component';
import {
    FutureBaseValuesSummaryComponent,
} from '@app/shared/components/future-base-values-summary/future-base-values-summary.component';
import { NotQualifiedBoxComponent } from '@app/shared/components/goal/not-qualified-box/not-qualified-box.component';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import {
    NotificationMessageComponent,
} from '@app/shared/components/header/notification-message/notification-message.component';
import { IconComponent } from '@app/shared/components/icons/icon.component';
import { LayoutComponent } from '@app/shared/components/layout/layout.component';
import { MenuComponent } from '@app/shared/components/menu/menu.component';
import { PageHeadlineComponent } from '@app/shared/components/page-headline/page-headline.component';
import { StepperComponent } from '@app/shared/components/stepper/stepper.component';
import { ToolbarNavComponent } from '@app/shared/components/toolbar-nav/toolbar-nav.component';
import { ToolbarComponent } from '@app/shared/components/toolbar/toolbar.component';
import { getGermanPaginatorIntl } from '@app/shared/german-paginator-intl';
import { AnonymizeEmailPipe } from '@app/shared/pipes/anonymize-email.pipe';
import { DefaultPipe } from '@app/shared/pipes/default.pipe';
import { GermanDateTimePipe } from '@app/shared/pipes/german-date-time.pipe';
import { GermanDatePipe } from '@app/shared/pipes/german-date.pipe';
import { Nl2BrPipe } from '@app/shared/pipes/nl2br.pipe';
import { StripTagsPipe } from '@app/shared/pipes/strip-tags/strip-tags.pipe';
import { NavigationService } from '@app/shared/services/navigation.service';
import { NgChartsModule } from 'ng2-charts';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { DropdownButtonComponent } from 'src/app/shared/components/dropdown-button/dropdown-button.component';
import { AutocompleteOffDirective } from 'src/app/shared/directives/autocomplete-off.directive';
import { WorkflowBadgeComponent } from './components/badge/workflow-badge/workflow-badge.component';
import { MainCompanyBadgeComponent } from './components/badge/main-company-badge/main-company-badge.component';
import { RequestTypeBadgeComponent } from './components/badge/request-type-badge/request-type-badge.component';
import { BasePageComponent } from './components/base-page/base-page.component';
import { ButtonLoadingDirective } from './components/button/button-loading.directive';
import { GenderFormFieldComponent } from './components/form-field/gender-form-field/gender-form-field.component';
import { PhoneNumberInputComponent } from './components/form-field/phone-number-input/phone-number-input.component';
import { OverallSpiderDiagramComponent } from './components/overall-spider-diagram/overall-spider-diagram.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RenderedValueComponent } from './components/rendered-value/rendered-value.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StateBoxHeadlineComponent } from './components/state-box/state-box-headline/state-box-headline.component';
import { StateBoxComponent } from './components/state-box/state-box.component';
import {
    SustainabilityIndicatorComponent,
} from './components/sustainability-indicator/sustainability-indicator.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { LoadingDirective } from './directives/loading.directive';
import { DurationPipe } from './pipes/duration.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { StyledHtmlComponent } from './components/styled-html/styled-html.component';
import { TabBarComponent } from '@app/shared/components/tab-bar/tab-bar.component';
import { StripTagsExceptPipe } from "@app/shared/pipes/strip-tags-except/strip-tags-except.pipe";
import {
    DataVisualizationComponent,
} from "@app/shared/components/assessment/data-visualization/data-visualization.component";

const SHARED_ASSESSMENT_COMPONENTS = [
    BaseValueInputComponent,
];

const STANDALONE_COMPONENTS = [
    ButtonColorDirective,
    CardComponent,
    CardItemComponent,
    PrimaryDirective,
    ToolbarComponent,
    SustainabilityIndicatorComponent,
    IconComponent,
    StepperComponent,
    RenderedValueComponent,
    StripTagsExceptPipe,
];

const SHARED_COMPONENTS = [
    BaseValueTableComponent,
    LayoutComponent,
    FileUploadComponent,
    FormFieldComponent,
    PageHeadlineComponent,
    BackButtonComponent,
    DialogComponent,
    FormErrorComponent,
    FutureBaseValuesSummaryComponent,
    RequiredMarkerComponent,
    DialogCloseButtonComponent,
    BadgeComponent,
    ProgressBarComponent,
    ToggleButtonComponent,
    StateBoxComponent,
    StateBoxHeadlineComponent,
    OverallSpiderDiagramComponent,
    WorkflowBadgeComponent,
    MainCompanyBadgeComponent,
    RequestTypeBadgeComponent,
    CertificationDownloadTableComponent,
    NotQualifiedBoxComponent,
    GenderFormFieldComponent,
    PhoneNumberInputComponent,
    DropdownButtonComponent,
    DropdownButtonOptionComponent,
    SpinnerComponent,
    EvaluationDownloadButtonComponent,
    ExpansionPanelComponent,
    ...SHARED_ASSESSMENT_COMPONENTS,
];

const PIPES = [
    AnonymizeEmailPipe,
    GermanDateTimePipe,
    GermanDatePipe,
    YesNoPipe,
    DefaultPipe,
    DurationPipe,
    Nl2BrPipe,
    StripTagsPipe,
];

const DIRECTIVES = [
    AutocompleteOffDirective,
    ButtonLoadingDirective,
    FormFieldErrorDirective,
    FormFieldHintDirective,
    InputDirective,
    LabelDirective,
    LoadingDirective,
];

@NgModule({
    declarations: [
        ...SHARED_COMPONENTS,
        ...PIPES,
        ...DIRECTIVES,
        ToolbarNavComponent,
        HeaderComponent,
        FooterComponent,
        MenuComponent,
        NotificationMessageComponent,
        BasePageComponent,
        StyledHtmlComponent,
    ],
    imports: [
        ...STANDALONE_COMPONENTS,
        CommonModule,
        RouterModule,
        MatDialogModule,
        MatMenuModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgChartsModule,
        MatTableModule,
        MatProgressSpinnerModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MatLegacyCheckboxModule,
        TabBarComponent,
        DataVisualizationComponent,
    ],
    exports: [
        ...SHARED_COMPONENTS,
        ...STANDALONE_COMPONENTS,
        ...PIPES,
        ...DIRECTIVES,
        MatSelectModule,
        ReactiveFormsModule,
        StyledHtmlComponent,
        TabBarComponent,
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useValue: getGermanPaginatorIntl(),
        },
        NavigationService,
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { disableOptionCentering: true },
        },
        {
            provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
            useValue: { expandedHeight: '48px' },
        },
        ...PIPES,
    ],
})
export class SharedModule {
}
