import { MeasureBlockConstraint } from '@app/shared/models/assessment/measure-block-constraint';
import { MeasureList } from '@app/shared/models/assessment/measure-list.model';
import { Measure } from '@app/shared/models/assessment/measure.model';
import { Unit } from '@app/shared/models/unit';
import { IsNullable } from '@webmozarts/http-client';
import { Type } from 'class-transformer';
import { IsArray, IsDefined, IsEnum, ValidateNested } from 'class-validator';

export class MeasureBlock {
    @IsDefined({ each: true })
    @IsArray()
    @ValidateNested()
    private _measureLists!: MeasureList[];

    private _measures: Measure[] = [];

    @IsDefined()
    id!: string;

    @IsDefined()
    @IsNullable()
    @IsEnum(MeasureBlockConstraint)
    constraint!: MeasureBlockConstraint | null;

    @IsDefined()
    @IsNullable()
    label!: string | null;

    @Type(() => MeasureList)
    set measureLists(value: MeasureList[]) {
        this._measureLists = value;

        this._measures = this.measureLists.flatMap((list) => list.measures);
    }

    get measureLists(): MeasureList[] {
        return this._measureLists;
    }

    get measures(): Measure[] {
        return this._measures;
    }

    get unit(): Unit {
        return this.measures.find((measure) => measure.unit !== Unit.NOTE)?.unit ?? Unit.NOTE;
    }
}
