<app-back-button></app-back-button>
<app-card>
    <app-card-item headline="Seite nicht gefunden"
                   i18n-headline="@@errorPages.pageNotFoundPage.headline">
        <app-state-box state="error">
            <app-state-box-headline i18n="@@errorPages.pageNotFoundPage.message">
                Diese Seite wurde nicht gefunden. Haben Sie die richtige URL eingegeben?
            </app-state-box-headline>
        </app-state-box>
    </app-card-item>
</app-card>
