import { Pipe, PipeTransform } from '@angular/core';
import { formatToDuration } from '@app/shared/time.utils';

@Pipe({
    name: 'duration',
})
export class DurationPipe implements PipeTransform {

    transform(value: unknown): string | undefined {
        if ('number' !== typeof value) {
            return;
        }

        return formatToDuration(value);
    }

}
