import { Measure } from '@app/shared/models/assessment/measure.model';
import { QualityGoal } from '@app/shared/models/assessment/quality-goal.model';
import { Type } from 'class-transformer';
import { IsArray, IsDefined, ValidateNested } from 'class-validator';
import { IsNullable } from '@webmozarts/http-client';
import { Expression } from "@app/shared/models/assessment/expression.model";
import {
    ExpressionValueRecommendation,
} from "@app/shared/models/assessment/expression-value-recommendation.model";

export class ExpressionValueShare {
    @IsDefined()
    id!: string;

    @IsDefined()
    @IsNullable()
    @ValidateNested()
    @Type(() => Expression)
    expression!: Expression | null;

    @IsDefined()
    @IsNullable()
    @ValidateNested()
    @Type(() => Measure)
    measure!: Measure | null;

    @IsDefined()
    @IsNullable()
    @ValidateNested()
    @Type(() => QualityGoal)
    qualityGoal!: QualityGoal | null;

    @IsDefined()
    value!: number;

    @IsArray()
    @IsDefined({ each: true })
    @ValidateNested()
    @Type(() => ExpressionValueRecommendation)
    recommendations!: ExpressionValueRecommendation[];

    get label(): string {
        return this.target.label;
    }

    private get target(): Measure | Expression {
        return (this.measure || this.expression)!;
    }
}
