import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {

    @Input() progress: number = 0;

    private readonly max: number = 1;

    constructor() {
    }

    public get progressInDecimals(): number {
        if (!this.progress) {
            return 0;
        }

        const progressInDecimals = Math.round(this.progress * 100);
        const maxInDecimals = this.max * 100;

        return Math.min(progressInDecimals, maxInDecimals);
    }

}
