import { Measure } from '@app/shared/models/assessment/measure.model';
import { Type } from 'class-transformer';
import { IsDefined, ValidateNested } from 'class-validator';

export class ExpressionValueRecommendation {
    @IsDefined()
    @ValidateNested()
    @Type(() => Measure)
    measure!: Measure;

    @IsDefined()
    shareImprovement!: number;
}
