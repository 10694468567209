import { IsNullable } from '@webmozarts/http-client';
import { Type } from 'class-transformer';
import { IsDefined, IsEnum } from 'class-validator';

export enum Sustainability {
    BAD= 'bad',
    NEUTRAL = 'neutral',
    GOOD = 'good',
    VERY_GOOD = 'veryGood',
}

export class CategoryRating {
    @IsDefined()
    id!: string;

    @IsDefined()
    name!: string;

    @IsDefined()
    @IsNullable()
    description!: string | null;

    @IsDefined()
    @IsEnum(Sustainability)
    @Type()
    sustainability!: Sustainability;
}

export function isPositiveCategoryRating(categoryRating: CategoryRating): boolean {
    return [Sustainability.GOOD, Sustainability.VERY_GOOD ].includes(categoryRating.sustainability);
}

export function isNegativeCategoryRating(categoryRating: CategoryRating): boolean {
    return Sustainability.BAD === categoryRating.sustainability;
}
