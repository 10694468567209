import { IsDefined } from 'class-validator';

export class Expression {
    @IsDefined()
    id!: string;

    @IsDefined()
    globalId!: string;

    @IsDefined()
    label!: string;
}
