import { Injectable } from '@angular/core';
import { SerializingHttpClient } from '@webmozarts/http-client';
import { Observable } from 'rxjs';
import { AccessToken } from '../models/access-token.model';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {

    constructor(private http: SerializingHttpClient) {
    }

    login(username: string, password: string): Observable<AccessToken> {
        return this.http
            .post(
                AccessToken,
                '/authentication/login',
                {
                    username,
                    password,
                },
            );
    }
}
