import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { Authenticator } from '@app/core/authentication/services/authenticator.service';
import { RedirectUrlFactory } from '@app/core/authentication/services/redirect-url-factory.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {

    constructor(
        private readonly authenticator: Authenticator,
        private readonly redirectUrlFactory: RedirectUrlFactory,
    ) {
    }

    canActivate(
        _route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return this.isAllowed(state.url);
    }

    private isAllowed(originalUrl: string): Observable<boolean | UrlTree> {
        return this.authenticator.isAuthenticated()
            .pipe(
                map((authenticated) => {
                    if (authenticated) {
                        return true;
                    }

                    return this.redirectUrlFactory.createLoginUrlWithOriginalUrl(originalUrl);
                }),
            );
    }
}
