import { MeasureBlock } from '@app/shared/models/assessment/measure-block.model';
import { Measure } from '@app/shared/models/assessment/measure.model';
import { Type } from 'class-transformer';
import { IsArray, IsDefined, ValidateNested } from 'class-validator';

export class QualityGoal {
    @IsDefined({ each: true })
    @IsArray()
    @ValidateNested()
    private _measureBlocks!: MeasureBlock[];

    private _measures!: Measure[];

    @IsDefined()
    id!: string;

    @IsDefined()
    globalId!: string;

    @IsDefined()
    processId!: string;

    @IsDefined()
    label!: string;

    @IsDefined()
    description!: string;

    @Type(() => MeasureBlock)
    set measureBlocks(measureBocks: MeasureBlock[]) {
        this._measureBlocks = measureBocks;

        this._measures = measureBocks.flatMap((block) => block.measures);
    }

    get measureBlocks(): MeasureBlock[] {
        return this._measureBlocks;
    }

    get measures(): Measure[] {
        return this._measures;
    }
}
