/* 🤖 this file was generated by svg-to-ts */
export const svgIconArrowLeft: {
  name: 'arrow_left';
  data: string;
} = {
  name: 'arrow_left',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M14 6H.4M6.4 0l-6 6 6 6"/></g></svg>`
};
export const svgIconArrowRight: {
  name: 'arrow_right';
  data: string;
} = {
  name: 'arrow_right',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="1.5"><path d="M0 6h13.6M7.6 0l6 6-6 6"/></g></svg>`
};
export const svgIconArrowUp: {
  name: 'arrow_up';
  data: string;
} = {
  name: 'arrow_up',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="1.5"><path d="M12 28V.8M0 12.8l12-12 12 12"/></g></svg>`
};
export const svgIconBinoculars: {
  name: 'binoculars';
  data: string;
} = {
  name: 'binoculars',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 50" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linejoin="round" stroke-width="2.043"><path d="M24.723 37.291c0 6.233-5.053 11.288-11.288 11.288S2.147 43.524 2.147 37.291c0-6.235 5.053-11.288 11.288-11.288s11.288 5.053 11.288 11.288Z"/><path d="M24.723 11.33V2.298c-1.693-1.693-5.08-1.693-6.773 0l-3.386 9.03-3.387 3.387-6.772 15.803M31.496 37.291c0 6.233 5.052 11.288 11.287 11.288 6.236 0 11.288-5.055 11.288-11.288 0-6.235-5.052-11.288-11.288-11.288-6.235 0-11.287 5.053-11.287 11.288Z"/><path d="M31.496 11.33V2.44c1.693-1.693 5.079-1.693 6.772 0l3.387 8.888 3.386 3.387 6.773 15.803M19.079 20.36c0-2.495 4.043-4.516 9.03-4.516 4.987 0 9.03 2.02 9.03 4.516M24.723 37.291V16.172M31.496 37.291V16.172M6.662 37.291a6.775 6.775 0 0 1 6.773-6.772M36.01 37.291a6.775 6.775 0 0 1 6.773-6.772"/></g></svg>`
};
export const svgIconCalendar: {
  name: 'calendar';
  data: string;
} = {
  name: 'calendar',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linejoin="round" stroke-width="2"><path stroke-linecap="round" d="M6 7h3V3H6zM15 7h3V3h-3z"/><path d="M2 10.5h20M6 5H3v16h18V5h-3M9 5h6"/></g></svg>`
};
export const svgIconCertification: {
  name: 'certification';
  data: string;
} = {
  name: 'certification',
  data: `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" baseProfile="tiny" overflow="visible" viewBox="0 0 28 28" width="1em" height="1em"><g fill="none" stroke="currentColor" stroke-linecap="square" stroke-width="1.5"><path d="M8.9 20.5H2.5v-18h9.9L16.9 7v2"/><path d="M12.4 3.4V7H16"/></g><g transform="translate(12 12)"><circle cx="7" cy="7" r="7" fill="none" stroke="currentColor" stroke-linecap="square" stroke-width="1.5"/><path fill="none" stroke="currentColor" stroke-linecap="square" stroke-width="1.5" d="M8.165 11.347a4.481 4.481 0 0 0 3.182-5.512 4.645 4.645 0 0 0-1.175-2.066C9.062 2.72 7.477 2.213 5.835 2.653a4.481 4.481 0 0 0-3.182 5.512 4.481 4.481 0 0 0 5.512 3.182zM2.748 6.586l7.727-2.07M3.525 9.484l7.727-2.07"/></g></svg>`
};
export const svgIconCheckCircle: {
  name: 'check_circle';
  data: string;
} = {
  name: 'check_circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="1.5"><path d="m15 6.5-7.5 7L5 11"/><path d="M10 .75a9.22 9.22 0 0 0-6.541 2.709A9.221 9.221 0 0 0 .75 10a9.221 9.221 0 0 0 2.709 6.541A9.22 9.22 0 0 0 10 19.25a9.221 9.221 0 0 0 6.54-2.71A9.22 9.22 0 0 0 19.25 10a9.22 9.22 0 0 0-2.71-6.54A9.221 9.221 0 0 0 10 .75Z"/></g></svg>`
};
export const svgIconCheck: {
  name: 'check';
  data: string;
} = {
  name: 'check',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="2" d="M22.652 4.5 7.957 19.196 1 12.239"/></svg>`
};
export const svgIconChecklist: {
  name: 'checklist';
  data: string;
} = {
  name: 'checklist',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="1.5"><path d="M23.518 25.496h-19v-23h13l6 6z"/><path d="M17.518 2.496v6h6"/><path stroke-linecap="square" d="m11.518 9.496-2.5 2.5-1.5-1.5M11.518 14.496l-2.5 2.5-1.5-1.5M11.518 19.496l-2.5 2.5-1.5-1.5M14.518 16.496h5M14.518 11.496h5M14.518 21.496h5"/></g></svg>`
};
export const svgIconChevronDown: {
  name: 'chevron_down';
  data: string;
} = {
  name: 'chevron_down',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 10" width="1em" height="1em"><path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="square" stroke-width="1.5" d="M20 0 10 10 0 0"/></svg>`
};
export const svgIconChevronRight: {
  name: 'chevron_right';
  data: string;
} = {
  name: 'chevron_right',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 16" width="1em" height="1em"><path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="square" stroke-width="1.5" d="m0 0 8 8-8 8"/></svg>`
};
export const svgIconCircleCurrent: {
  name: 'circle_current';
  data: string;
} = {
  name: 'circle_current',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="1em" height="1em"><circle cx="10" cy="10" r="9" fill="none" stroke="currentColor" stroke-width="2"/></svg>`
};
export const svgIconCircleDone: {
  name: 'circle_done';
  data: string;
} = {
  name: 'circle_done',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="1em" height="1em"><path fill="currentColor" fill-rule="evenodd" d="M10 0c5.522 0 10 4.477 10 10s-4.478 10-10 10C4.476 20 0 15.523 0 10S4.476 0 10 0m4.7 5.69-7 6.999-2.8-2.8-1.06 1.061 3.86 3.86 8.06-8.06z"/></svg>`
};
export const svgIconCircleN: {
  name: 'circle_n';
  data: string;
} = {
  name: 'circle_n',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="1em" height="1em"><path fill="currentColor" fill-rule="evenodd" d="M10 0c5.522 0 10 4.477 10 10s-4.478 10-10 10C4.476 20 0 15.523 0 10S4.476 0 10 0M8.428 5.372H6.314V14.5h1.974v-3.234c0-1.078-.168-2.296-.266-3.304h.056l.896 1.988 2.38 4.55h2.114V5.372h-1.96v3.22c0 1.078.168 2.366.266 3.332h-.056l-.91-2.016z"/></svg>`
};
export const svgIconCircle: {
  name: 'circle';
  data: string;
} = {
  name: 'circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="1em" height="1em"><circle cx="10" cy="10" r="10" fill="currentColor"/></svg>`
};
export const svgIconClingingGlasses: {
  name: 'clinging_glasses';
  data: string;
} = {
  name: 'clinging_glasses',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="1em" height="1em"><g fill="currentColor" fill-rule="nonzero"><path d="M47.935 40.417a.968.968 0 0 0-1.251-.56l-2.675 1.031-3.85-10.078c3.47-1.854 5.105-6.073 3.661-9.852L41.047 13.7l-1.363-3.567a.972.972 0 0 0-1.252-.56l-12.944 4.993a.975.975 0 0 0-.557 1.257l2.778 7.27v.002l1.358 3.553a7.885 7.885 0 0 0 2.951 3.732 7.862 7.862 0 0 0 4.427 1.364c.645 0 1.282-.08 1.904-.236L42.2 41.586l-2.675 1.032a.975.975 0 0 0 .348 1.882c.115 0 .233-.02.347-.065l3.579-1.38 3.58-1.38a.975.975 0 0 0 .556-1.258M38.222 11.74l.747 1.954-9.905 7.515-1.977-5.175zm-1.777 18.057zc-2.452 0-4.69-1.546-5.57-3.847l-1.088-2.85 9.905-7.515 2.32 6.07c1.178 3.085-.361 6.558-3.431 7.742-.688.265-1.406.4-2.136.4M22.512 14.566 9.568 9.573a.965.965 0 0 0-1.252.56L6.953 13.7 4.18 20.96c-1.444 3.778.191 7.997 3.662 9.85L3.99 40.89l-2.675-1.032a.967.967 0 0 0-1.251.56.975.975 0 0 0 .557 1.257l3.578 1.38h.001l3.58 1.381a.97.97 0 0 0 1.252-.56.975.975 0 0 0-.558-1.257L5.8 41.586l3.85-10.078a7.861 7.861 0 0 0 6.331-1.128 7.884 7.884 0 0 0 2.952-3.732l1.358-3.552v-.002l2.778-7.27a.975.975 0 0 0-.557-1.258M17.124 25.95c-.879 2.301-3.117 3.847-5.569 3.847-.73 0-1.448-.135-2.136-.4-3.07-1.184-4.609-4.657-3.43-7.741l2.32-6.071 9.904 7.515zm1.812-4.741L9.03 13.694l.747-1.954 11.135 4.294zM24.969 4.473A.971.971 0 0 0 24 3.5a.971.971 0 0 0-.969.973v5.008c0 .537.434.973.969.973a.971.971 0 0 0 .969-.973zM19.637 11.221a.966.966 0 0 0 1.353.216.976.976 0 0 0 .214-1.36l-2.93-4.05a.966.966 0 0 0-1.353-.216.976.976 0 0 0-.214 1.36zM27.01 11.437a.966.966 0 0 0 1.353-.215l2.93-4.052a.976.976 0 0 0-.215-1.36.966.966 0 0 0-1.353.216l-2.93 4.051a.976.976 0 0 0 .215 1.36"/></g></svg>`
};
export const svgIconClose: {
  name: 'close';
  data: string;
} = {
  name: 'close',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5"><path d="M24 0 0 24M24 24 0 0"/></g></svg>`
};
export const svgIconDownload: {
  name: 'download';
  data: string;
} = {
  name: 'download',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="square" d="M23.018 18.214v1.913a2.87 2.87 0 0 1-2.87 2.869H3.888a2.869 2.869 0 0 1-2.87-2.87v-1.912"/><path d="M12.018 18.105V.996"/><path stroke-linecap="square" d="m19.018 11.105-7 7-7-7"/></g></svg>`
};
export const svgIconEdit: {
  name: 'edit';
  data: string;
} = {
  name: 'edit',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"><path stroke-linejoin="round" d="M8.486 20 0 23.639l3.638-8.487L18.79 0l4.848 4.85z"/><path d="m20.491 7.996-4.848-4.849M8.486 20.001l-4.848-4.85"/></g></svg>`
};
export const svgIconExclamationCircle: {
  name: 'exclamation_circle';
  data: string;
} = {
  name: 'exclamation_circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor"><path stroke-width="2" d="M20.998 11.844c-.043-2.497-1.057-4.742-2.689-6.346A8.697 8.697 0 0 0 12.017 3 9.255 9.255 0 0 0 5.583 5.78C3.96 7.432 2.958 9.686 3.001 12.158c.044 2.498 1.057 4.743 2.689 6.347a8.697 8.697 0 0 0 6.292 2.497 9.253 9.253 0 0 0 6.434-2.778c1.624-1.653 2.625-3.907 2.582-6.38Z"/><path stroke-linecap="square" stroke-linejoin="round" stroke-width="2" d="M12 13V7"/><path stroke-linejoin="round" stroke-width="1.6" d="M12.8 16.5a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0Z"/></g></svg>`
};
export const svgIconEyeSlash: {
  name: 'eye_slash';
  data: string;
} = {
  name: 'eye_slash',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="1em" height="1em"><g fill="currentColor" fill-rule="nonzero"><path d="M21.261 9.197a21.3 21.3 0 0 1 1.333.962c.94.735 1.794 1.52 2.545 2.307l.097.103.095.1.348.384.156.178.179.212.443.557-.402.506-.376.441-.348.383-.192.204a23.728 23.728 0 0 1-2.545 2.307c-2.722 2.125-5.62 3.409-8.594 3.409-1.361 0-2.707-.27-4.027-.765l1.183-1.182A9.623 9.623 0 0 0 14 19.75c2.585 0 5.19-1.154 7.67-3.091.88-.687 1.68-1.425 2.384-2.161l.178-.19.167-.181.114-.127-.114-.127-.345-.37a22.241 22.241 0 0 0-2.384-2.162c-.493-.385-.992-.74-1.494-1.06zm-11.143 9.73-1.14 1.14c-.46-.214-.916-.454-1.368-.72l1.102-1.1c.466.258.935.486 1.406.68M14 6.75c1.361 0 2.707.27 4.027.765l-1.181 1.183A9.624 9.624 0 0 0 14 8.25c-2.585 0-5.19 1.154-7.67 3.091-.88.687-1.68 1.425-2.384 2.161l-.178.19-.167.181-.115.127.115.127.345.37A22.241 22.241 0 0 0 6.33 16.66c.493.386.992.74 1.495 1.06l-1.086 1.085c-.448-.298-.893-.62-1.333-.963a23.728 23.728 0 0 1-2.545-2.307l-.097-.103-.095-.1-.348-.384-.156-.178-.179-.212L1.543 14l.402-.506.376-.441.348-.383.192-.204a23.728 23.728 0 0 1 2.545-2.307C8.128 8.034 11.026 6.75 14 6.75m6.39 1.902-1.1 1.101a13.933 13.933 0 0 0-1.405-.68l1.139-1.14c.459.214.915.454 1.366.72"/><path d="M18.166 12.294a4.5 4.5 0 0 1-5.872 5.871L13.5 16.96a3 3 0 0 0 3.458-3.458zm-5.7 4.285-1.084 1.082a4.524 4.524 0 0 1-1.043-1.043l1.083-1.082c.256.428.615.788 1.044 1.043M14 9.5c.605 0 1.181.12 1.708.335l-1.207 1.207a3 3 0 0 0-3.459 3.46l-1.207 1.206A4.5 4.5 0 0 1 14 9.5m3.66 1.882-1.082 1.083a3.014 3.014 0 0 0-1.042-1.043l1.082-1.083c.402.288.755.641 1.043 1.043"/><path d="M23 3.94 24.06 5 5 24.06 3.94 23z"/></g></svg>`
};
export const svgIconEye: {
  name: 'eye';
  data: string;
} = {
  name: 'eye',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="1em" height="1em"><g fill="currentColor" fill-rule="nonzero"><path d="M14 6.75c-2.973 0-5.872 1.284-8.594 3.409a23.728 23.728 0 0 0-2.545 2.307l-.192.204-.348.383-.376.44-.402.507.443.557.18.212.155.178.348.383.192.204a23.728 23.728 0 0 0 2.545 2.307c2.722 2.125 5.62 3.409 8.594 3.409 2.973 0 5.872-1.284 8.594-3.409a23.728 23.728 0 0 0 2.545-2.307l.192-.204.348-.383.376-.44.402-.507-.443-.557-.18-.212-.155-.178-.348-.383a23.728 23.728 0 0 0-2.737-2.51c-2.722-2.126-5.62-3.41-8.594-3.41m0 1.5c2.585 0 5.19 1.154 7.67 3.091.88.687 1.68 1.425 2.384 2.161l.345.371.114.127-.114.127a22.241 22.241 0 0 1-2.729 2.532c-2.48 1.937-5.085 3.091-7.67 3.091s-5.19-1.154-7.67-3.091a22.241 22.241 0 0 1-2.384-2.161l-.345-.371L3.486 14l.115-.127a22.241 22.241 0 0 1 2.729-2.532C8.81 9.404 11.415 8.25 14 8.25"/><path d="M14 9.5a4.5 4.5 0 1 1 0 9 4.5 4.5 0 1 1 0-9m0 1.5a3 3 0 1 0-.001 5.999A3 3 0 0 0 14 11"/></g></svg>`
};
export const svgIconFileEdit: {
  name: 'file_edit';
  data: string;
} = {
  name: 'file_edit',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="square" stroke-width="1.5"><path d="M11.5 23.496h-9v-21h11l5 5v7.5"/><path d="M13 2.75V8h5M18 24.498l-3.5 1 1-3.5 7.501-7.502 2.5 2.5zM21.001 16.496l2.5 2.5M15.902 21.998 18 24.1"/></g></svg>`
};
export const svgIconGrapes: {
  name: 'grapes';
  data: string;
} = {
  name: 'grapes',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 50" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linejoin="round" stroke-width="2"><path d="M26.186 44.2a4.114 4.114 0 1 1-8.23 0 4.114 4.114 0 0 1 8.23 0ZM26.186 21.571a4.114 4.114 0 1 1-8.23 0 4.114 4.114 0 0 1 8.23 0ZM13.843 22.6a4.114 4.114 0 1 1-8.23 0 4.114 4.114 0 0 1 8.23 0ZM38.529 22.6a4.114 4.114 0 1 1-8.23 0 4.114 4.114 0 0 1 8.23 0ZM32.357 33.914a4.114 4.114 0 1 1-8.229 0 4.114 4.114 0 0 1 8.23 0ZM20.014 33.914a4.114 4.114 0 1 1-8.229 0 4.114 4.114 0 0 1 8.23 0ZM22.071 13.343V1M26.186 13.343c7.767 1.703 14.75-2.516 16.457-10.286-7.768-1.703-14.754 2.518-16.457 10.286ZM17.957 13.343C10.19 15.046 3.203 10.827 1.5 3.057c7.768-1.703 14.75 2.518 16.457 10.286Z"/></g></svg>`
};
export const svgIconHandHoldingPaper: {
  name: 'hand_holding_paper';
  data: string;
} = {
  name: 'hand_holding_paper',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="1.5"><path d="M16 19.5h9.5v-12l-5-5h-8v14"/><path d="M20.5 2.5v5h5M2.5 25.5h4v-7h-4zM6.5 24.5h17c0-1.5-3.5-3-7.5-3H9M6.5 19.5H10c2.353 0 3.5 1.5 4 2"/></g></svg>`
};
export const svgIconInspection: {
  name: 'inspection';
  data: string;
} = {
  name: 'inspection',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="1em" height="1em"><g fill="none" fill-rule="evenodd"><path stroke="currentColor" stroke-linecap="square" stroke-width="1.5" d="M11.391 22.87H2V2h19.826v9.913M18.174 3.565V2M14 3.565V2M9.826 3.565V2M5.652 3.565V2"/><path fill="currentColor" d="M4.61 5.652h2.086V3.565H4.61zM8.783 5.652h2.087V3.565H8.783zM17.13 5.652h2.088V3.565H17.13zM12.957 5.652h2.087V3.565h-2.087z"/><path stroke="currentColor" stroke-linecap="square" stroke-width="1.5" d="M23.506 19.536a3.97 3.97 0 1 1-7.94 0 3.97 3.97 0 1 1 7.94 0ZM22.348 22.348 26 26"/></g></svg>`
};
export const svgIconLoginAs: {
  name: 'login_as';
  data: string;
} = {
  name: 'login_as',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="square" stroke-width="1.5"><path d="M9.5 17.202V23.5h14V.5h-14v6.298"/><path d="m14 7 5 5-5 5M0 12h18"/></g></svg>`
};
export const svgIconMailWaiting: {
  name: 'mail_waiting';
  data: string;
} = {
  name: 'mail_waiting',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="square" d="M12 17H4a1.5 1.5 0 0 1-1.5-1.5v-11A1.5 1.5 0 0 1 4 3h18a1.5 1.5 0 0 1 1.5 1.5V12"/><path d="m23 3.5-10 8-10-8"/><path stroke-linecap="square" d="M25.5 19.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Z"/><path stroke-linecap="square" d="M21.5 20h-2v-3.5"/></g></svg>`
};
export const svgIconNachhaltigLabel: {
  name: 'nachhaltig_label';
  data: string;
} = {
  name: 'nachhaltig_label',
  data: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 44 44" width="1em" height="1em"><defs><path id="a" d="M22 37c8.284 0 15-6.716 15-15a14.93 14.93 0 0 0-2.015-7.514C32.39 10.01 27.546 7 22 7 13.716 7 7 13.716 7 22c0 8.284 6.716 15 15 15"/></defs><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linejoin="round" stroke-width="2.049"><circle cx="22" cy="22" r="21"/><use xlink:href="#a"/><path d="m7.183 20.793 27.046-7.246M9.771 30.453l27.046-7.246"/></g></svg>`
};
export const svgIconPlus: {
  name: 'plus';
  data: string;
} = {
  name: 'plus',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" stroke-width="1.5"><path d="M0 9h18M9 18V0"/></g></svg>`
};
export const svgIconQuestionCircleInverted: {
  name: 'question_circle_inverted';
  data: string;
} = {
  name: 'question_circle_inverted',
  data: `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" baseProfile="tiny" overflow="visible" version="1.2" viewBox="0 0 22 22" width="1em" height="1em"><path fill="currentColor" d="M11 .2C5.1.2.2 5.1.2 11S5.1 21.7 11 21.7s10.8-4.8 10.8-10.8C21.8 5.1 16.9.2 11 .2m-.1 17.5c-.6 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2 1.2.5 1.2 1.2-.5 1.2-1.2 1.2m.9-6.1h-.1v2.6h-1.5v-4h.8c1 0 1.8-.8 1.8-1.8S12 6.6 11 6.6c-.9 0-1.7.7-1.7 1.6v.9H7.7v-.6c0-1.8 1.5-3.2 3.2-3.2 1.8 0 3.2 1.5 3.2 3.2.1 1.5-.9 2.7-2.3 3.1"/></svg>`
};
export const svgIconQuestionCircle: {
  name: 'question_circle';
  data: string;
} = {
  name: 'question_circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="square" stroke-width="1.5"><path d="M21 10.996c0 5.524-4.478 10-10 10-5.524 0-10-4.476-10-10 0-5.523 4.476-10 10-10 5.522 0 10 4.477 10 10Z"/><path d="M8.431 8.497a2.5 2.5 0 1 1 2.5 2.5v2.5M11.348 16.58a.417.417 0 1 1-.834 0 .417.417 0 0 1 .834 0Z"/></g></svg>`
};
export const svgIconRibbon: {
  name: 'ribbon';
  data: string;
} = {
  name: 'ribbon',
  data: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 28 28" width="1em" height="1em"><defs><path id="a" d="M5.786 11.571A5.786 5.786 0 1 0 5.786 0a5.786 5.786 0 0 0 0 11.571"/></defs><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="1.5"><path stroke-linejoin="round" d="M5.871 16.158 2 22.851l4.017-.68L7.411 26l3.426-5.756M22.006 16.25l3.826 6.6-4.018-.679L20.419 26 17 20.277"/><g stroke-linecap="square" transform="translate(5 2.5)"><circle cx="9" cy="9" r="9"/><g transform="rotate(-15 19.6 -4.815)"><use xlink:href="#a"/><path d="M.643 3.857h10.286M.643 7.714h10.286"/></g></g></g></svg>`
};
export const svgIconSearch: {
  name: 'search';
  data: string;
} = {
  name: 'search',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="square" stroke-width="1.5"><path d="M16.518 8.496a8.001 8.001 0 0 1-16 0 8 8 0 0 1 16 0ZM14.174 14.152l9.344 9.344"/></g></svg>`
};
export const svgIconSparks: {
  name: 'sparks';
  data: string;
} = {
  name: 'sparks',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="1em" height="1em"><g fill="currentColor" fill-rule="nonzero"><path d="M31 8a4.004 4.004 0 0 0-4 4 1 1 0 1 1-2 0 4.005 4.005 0 0 0-4-4 1 1 0 1 1 0-2 4.005 4.005 0 0 0 4-4 1 1 0 1 1 2 0 4.004 4.004 0 0 0 4 4 1 1 0 0 1 0 2m-5-2.687A6.05 6.05 0 0 1 24.313 7 6.05 6.05 0 0 1 26 8.687 6.05 6.05 0 0 1 27.687 7 6.05 6.05 0 0 1 26 5.313M18 25a1 1 0 1 1 0-2 5.006 5.006 0 0 0 5-5 1 1 0 1 1 2 0 5.006 5.006 0 0 0 5 5 1 1 0 0 1 0 2 5.006 5.006 0 0 0-5 5 1 1 0 1 1-2 0 5.006 5.006 0 0 0-5-5m6 1.398A7.056 7.056 0 0 1 26.398 24 7.056 7.056 0 0 1 24 21.602 7.056 7.056 0 0 1 21.602 24 7.056 7.056 0 0 1 24 26.398M16 16a1 1 0 0 1-1 1 6.007 6.007 0 0 0-6 6 1 1 0 1 1-2 0 6.007 6.007 0 0 0-6-6 1 1 0 1 1 0-2 6.007 6.007 0 0 0 6-6 1 1 0 1 1 2 0 6.007 6.007 0 0 0 6 6 1 1 0 0 1 1 1m-8-3.13A8.06 8.06 0 0 1 4.87 16 8.06 8.06 0 0 1 8 19.13 8.06 8.06 0 0 1 11.13 16 8.06 8.06 0 0 1 8 12.87"/></g></svg>`
};
export const svgIconStarCircle: {
  name: 'star_circle';
  data: string;
} = {
  name: 'star_circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="enable-background:new 0 0 28 28" viewBox="0 0 28 28" width="1em" height="1em"><path d="m14 6.7 2 5h5l-4 3.5 1.5 5.5-4.5-3.5-4.5 3.5 1.5-5.5-4-3.5h5z" style="fill:none;stroke:#1e1e1e;stroke-width:1.5;stroke-linejoin:round"/><path d="M14 2.8c3.1 0 5.9 1.3 8 3.3 2 2 3.3 4.8 3.3 8 0 3.1-1.3 5.9-3.3 8s-4.8 3.3-8 3.3S8.1 24 6 22s-3.3-4.8-3.3-8C2.8 10.9 4 8.1 6 6c2.1-2 4.9-3.2 8-3.2z" style="fill:none;stroke:#1e1e1e;stroke-width:1.5"/></svg>`
};
export const svgIconSustainabilityNeutral: {
  name: 'sustainability_neutral';
  data: string;
} = {
  name: 'sustainability_neutral',
  data: `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" baseProfile="tiny" overflow="visible" version="1.2" viewBox="0 0 36 36" width="1em" height="1em"><path fill="currentColor" d="M18 0C8.1 0 0 8.1 0 18s8.1 18 18 18 18-8.1 18-18S27.9 0 18 0m11.1 18.4c0 .1-.1.2-.2.3l-6.8 6.9c-.2.2-.5.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l5.1-5.2h-18c-.6 0-1-.4-1-1s.4-1 1-1h18l-5.1-5.2c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l6.8 6.9c.1.1.1.2.2.3.1.3.1.5 0 .8"/></svg>`
};
export const svgIconTimesCircle: {
  name: 'times_circle';
  data: string;
} = {
  name: 'times_circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="2" transform="translate(1 1)"><circle cx="21" cy="21" r="21"/><path d="M30 12 12 30M30 30 12 12"/></g></svg>`
};
export const svgIconTimes: {
  name: 'times';
  data: string;
} = {
  name: 'times',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" stroke-width="2"><path d="M19.74 4 4 19.738M19.74 19.738 4 4"/></g></svg>`
};
export const svgIconUpload: {
  name: 'upload';
  data: string;
} = {
  name: 'upload',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="square" d="M23.018 18.214v1.913a2.87 2.87 0 0 1-2.87 2.869H3.888a2.869 2.869 0 0 1-2.87-2.87v-1.912"/><path d="M12.018 1.888v17.109"/><path stroke-linecap="square" d="m19.018 8.888-7-7-7 7"/></g></svg>`
};
export const svgIconUserPlusCircle: {
  name: 'user_plus_circle';
  data: string;
} = {
  name: 'user_plus_circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="1em" height="1em"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="square" stroke-width="1.5"><path d="M26 20.782A5.217 5.217 0 0 1 20.783 26 5.217 5.217 0 1 1 26 20.783ZM20.783 18.174v5.217M23.392 20.782h-5.218M16.051 15.338c-.81-.268-1.685-.556-2.573-.891v-2.61s1.565-.645 1.565-3.13c.816 0 .816-2.16 0-2.16 0-.244.866-1.677.522-3.057-.493-1.987-6.288-1.987-6.782 0-2.471-.497-1.044 2.798-1.044 3.13-.817 0-.817 2.087 0 2.087 0 2.485 1.565 3.13 1.565 3.13v2.61c-2.898 1.102-6.352 1.832-6.782 3.13-.494 1.491-.522 4.25-.522 4.25h10.957"/></g></svg>`
};
export const svgIconWrench: {
  name: 'wrench';
  data: string;
} = {
  name: 'wrench',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="1em" height="1em"><path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5" d="M23.987 13.737a6.89 6.89 0 0 0 1.26-8.002l-3.504 3.491h-2.82v-2.97l3.418-3.504c-2.585-1.321-5.874-.902-8.038 1.26a6.879 6.879 0 0 0-1.281 7.99l-10.4 10.389a2.111 2.111 0 1 0 2.986 2.99l10.388-10.387a6.877 6.877 0 0 0 7.991-1.257Z"/></svg>`
};
export type IconName =
  | 'arrow_left'
  | 'arrow_right'
  | 'arrow_up'
  | 'binoculars'
  | 'calendar'
  | 'certification'
  | 'check_circle'
  | 'check'
  | 'checklist'
  | 'chevron_down'
  | 'chevron_right'
  | 'circle_current'
  | 'circle_done'
  | 'circle_n'
  | 'circle'
  | 'clinging_glasses'
  | 'close'
  | 'download'
  | 'edit'
  | 'exclamation_circle'
  | 'eye_slash'
  | 'eye'
  | 'file_edit'
  | 'grapes'
  | 'hand_holding_paper'
  | 'inspection'
  | 'login_as'
  | 'mail_waiting'
  | 'nachhaltig_label'
  | 'plus'
  | 'question_circle_inverted'
  | 'question_circle'
  | 'ribbon'
  | 'search'
  | 'sparks'
  | 'star_circle'
  | 'sustainability_neutral'
  | 'times_circle'
  | 'times'
  | 'upload'
  | 'user_plus_circle'
  | 'wrench';
export interface SvgIcon {
  name: IconName;
  data: string;
}
export type SvgIconNameSubset<
  T extends Readonly<SvgIcon[]>
> = T[number]['name'];
export const completeIconSet = [
  svgIconArrowLeft,
  svgIconArrowRight,
  svgIconArrowUp,
  svgIconBinoculars,
  svgIconCalendar,
  svgIconCertification,
  svgIconCheckCircle,
  svgIconCheck,
  svgIconChecklist,
  svgIconChevronDown,
  svgIconChevronRight,
  svgIconCircleCurrent,
  svgIconCircleDone,
  svgIconCircleN,
  svgIconCircle,
  svgIconClingingGlasses,
  svgIconClose,
  svgIconDownload,
  svgIconEdit,
  svgIconExclamationCircle,
  svgIconEyeSlash,
  svgIconEye,
  svgIconFileEdit,
  svgIconGrapes,
  svgIconHandHoldingPaper,
  svgIconInspection,
  svgIconLoginAs,
  svgIconMailWaiting,
  svgIconNachhaltigLabel,
  svgIconPlus,
  svgIconQuestionCircleInverted,
  svgIconQuestionCircle,
  svgIconRibbon,
  svgIconSearch,
  svgIconSparks,
  svgIconStarCircle,
  svgIconSustainabilityNeutral,
  svgIconTimesCircle,
  svgIconTimes,
  svgIconUpload,
  svgIconUserPlusCircle,
  svgIconWrench
];
