import { AfterViewInit, Component, ContentChild, Input } from '@angular/core';
import { InputDirective } from '@app/shared/components/form-field/input.directive';
import { LabelDirective } from '@app/shared/components/form-field/label.directive';
import { IconName } from '@icons/svg-icons';

@Component({
    selector: 'app-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements AfterViewInit {

    @Input() highlight: boolean = false;

    @ContentChild(InputDirective)
    private inputRef?: InputDirective;

    @ContentChild(LabelDirective)
    private labelRef?: LabelDirective;

    get control() {
        return this.inputRef;
    }

    get hasLabel(): boolean {
        return null != this.labelRef;
    }

    get inlineLabel(): boolean {
        return !!this.control?.isCheckbox;
    }

    get icon(): IconName | undefined {
        return this.inputRef?.icon;
    }

    get hasClickableLabel(): boolean {
        return !!this.control?.hasClickableLabel;
    }

    get isPassword(): boolean {
        return 'password' === this.control?.initialType;
    }

    get passwordIcon(): IconName {
        return 'password' === this.control?.typeAttribute ? 'eye' : 'eye_slash';
    }

    ngAfterViewInit() {
        if (!this.control) {
            throw new Error(`No control found for the form field component. Did you forget to pass ${InputDirective.name} to the input?`);
        }
    }

    onTogglePasswordVisibility() {
        this.control?.togglePasswordVisibility();
    }
}
